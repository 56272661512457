progress {
  /* Positioning */
  position: fixed;

  /* left: 0;
  top: 177; */

  /* Dimensions */
  width: 100%;
  height: 2px;

  /* Reset the appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Get rid of the default border in Firefox/Opera. */
  border: none;

  /* Progress bar container for Firefox/IE10+ */
  background-color: transparent;

  /* Progress bar value for IE10+ */
  color: $main-color;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $main-color;
  }

  &::-moz-progress-bar {
    background-color: $main-color;
  }
}
