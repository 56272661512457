// Fonts
$font-stack-general: 'Playfair Display', sans-serif;
$font-stack-general-1: 'Montserrat', Arial, Verdana, Geneva, sans-serif;
$font-stack: $font-stack-general;
$font-playfair: $font-stack-general;
$font-montserrat: $font-stack-general-1;
$font-stack-general-2: 'Raleway-Medium', Arial, sans-serif;
$font-stack-general-3: 'Sacramento', Arial, sans-serif;
$font-root-size: 16px;

// Colors
$main-color: #ff5859;
$color-black: #000;
$color-grey: red;
$color-background: #fff;
$color-background-option: #f5f8fb;
$color-white: #fff;
$color-grey-border: #ecedf0;
$om-color: #ed2a7b;
$color-blue: #b0e1ed;

// Body Max Width

// Breakpoints
$mobile-breakpoint: 768px;
$tablet-breakpoint: 1024px;
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
// Variables spin
