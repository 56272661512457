article {
  background-color: #fff;
  font-family: $font-stack-general-1;
  font-size: 1rem;
  font-weight: 300;

  @media (min-width: $mobile-breakpoint) {
    background-color: $color-background;
  }

  .breadcrumbs {
    display: none;

    @media (min-width: $mobile-breakpoint) {
      display: block;
    }
  }

  .article-content,
  .article-content.row {
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 50px;
    }
  }

  h2 {
    @media (max-width: $mobile-breakpoint - 1) {
      margin-bottom: 5px;
      font-size: 21px;
      line-height: 27px;
      color: #000;
      font-family: $font-stack-general;
      font-weight: bold;
    }

    @media (min-width: $mobile-breakpoint) {
      margin-bottom: 10px;
    }
  }

  h3,
  h3 span {
    font-family: $font-stack-general-1;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 5px;

    @media (min-width: $mobile-breakpoint) {
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }

  h4 {
    font-family: $font-stack-general-1;
    font-size: 0.75rem;
    color: #878787;
    letter-spacing: 1px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  em {
    strong {
      font-family: $font-stack-general-1;
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  strong {
    em {
      font-family: $font-stack-general-1;
    }
  }

  > iframe {
    width: 100%;
    margin: 40px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .author-article {
    display: inline-block;
  }

  .by {
    margin-right: 5px;
  }

  .row {
    margin: 0 0 25px;

    @media (min-width: $mobile-breakpoint) {
      margin: 0 0 40px;
    }
  }

  .share-aside {
    float: right;
    text-align: center;
    margin-top: 80%;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 50%;
    }

    > .col-xs-12 {
      @media (min-width: $mobile-breakpoint) {
        padding: 0;
      }
    }

    figure {
      padding: 5px;

      a:hover {
        opacity: 0.75;
      }

      img {
        //height: 15px;
        width: auto !important;
      }
    }
  }

  .highlight-image__container {
    position: relative;
  }

  .highlight-image {
    width: 100%;

    @media (min-width: $mobile-breakpoint) {
      margin-top: -30px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .highlight-video {
    position: relative;
    padding-bottom: 56.25%; // Hack para permitir responsive nos vídeos

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  span.highlight-first {
    line-height: 4rem;
    color: $main-color;
    float: left;
    font-size: 5em;
    margin: 0 0.2em 0 0;
    font-weight: bold;
  }

  p {
    span.highlight-first {
      line-height: 4rem;
      color: $main-color;
      float: left;
      font-size: 5em;
      margin: 0 0.2em 0 0;
      font-weight: bold;
    }

    /*
    img {
      margin: 30px 0 20px;

      @media (min-width: $mobile-breakpoint) {
        margin: 40px 0 0;
      }
    }
    */
  }

  .excerpt {
    font-family: $font-stack-general;
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: 400;

    @media (min-width: $mobile-breakpoint) {
      font-size: 1.125rem;
    }
  }

  .caption {
    p {
      margin: 10px 0 0;
    }
  }

  .wp-caption {
    img {
      max-width: 100%;
      padding-bottom: 40px;

      @media (min-width: $mobile-breakpoint) {
        padding-bottom: 0;
      }
    }

    .wp-caption-text {
      color: #646363;
      margin: 10px 0 0;
      line-height: 1rem;
      font-family: $font-stack-general-1;
      font-size: 0.75rem;
      text-align: center;
      font-weight: normal;
    }
  }

  .post-container {
    .wp-caption {
      .wp-caption-text {
        margin: -23px 0 23px 0;

        @media (min-width: $mobile-breakpoint) {
          margin: 10px 0 23px;
        }
      }
    }
  }

  .single__bottom {
    padding-top: 30px;
    background: #f0f0f0;

    .row {
      margin: 0;
    }
  }

  .by {
    font-family: $font-stack-general;
    font-style: italic;
    font-size: 0.625rem;
  }

  .by-sponsored {
    display: inline-flex;
    align-self: right;
    align-items: center;

    span {
      line-height: 1.4rem;
    }

    img {
      max-width: 150px;
      width: auto !important;
    }
  }

  .author-info {
    flex-direction: column;

    @media (min-width: $mobile-breakpoint) {
      border-top: 1px solid $color-grey-border;
      border-bottom: 1px solid $color-grey-border;
      margin-top: 30px;
      padding: 10px 0;
      font-family: $font-stack-general;
      letter-spacing: 1px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .by {
      padding-right: 10px;
      font-family: $font-stack-general;
      font-style: italic;
      font-size: 0.625rem;
      min-width: 95px;

      @media screen and (min-width: $mobile-breakpoint) {
        padding-right: 0;
      }
    }

    .author-date {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .author {
      font-family: $font-stack-general-1;
      text-decoration: none;
      font-size: 0.75rem;
      color: $main-color;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: inline-block;
      line-height: 2;
      padding: 0 !important;

      &::after {
        content: '•';
        padding: 10px;
      }
    }

    .date {
      text-transform: uppercase;
      font-size: 0.625rem;
      padding-top: 2px;
    }
  }

  .tags {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $main-color;
    font-size: 0.75rem;
    margin-bottom: 12px;
    margin-top: 20px !important;

    .more {
      font-size: 10px;
      color: $color-black;
      display: block;
      margin-bottom: 0;
      line-height: 1.2;
    }

    .single-tag {
      //font-size: 10px;
    }

    .comma {
      color: $color-black;
    }
  }

  .share {
    background-color: $color-background-option;

    .icons {
      display: flex;
      justify-content: space-around;
      /*
      width: 90%;
      margin: 0 auto;
       */
      width: 100%;

      figure {
        margin: 0;
        padding: 10px;
        display: inline-block;

        a {
          &:hover {
            opacity: 0.75;
          }

          img {
            //width: 20px !important;
          }
        }
      }
    }
  }

  .comments-section {
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
      cursor: pointer;
    }

    p {
      font-size: 0.625rem;
      color: $color-black;
      display: inline-block;
      line-height: 40px;
      margin: 0;
      padding-right: 5px;
    }

    .open {
      position: relative;
    }

    .count {
      color: $main-color;
      font-family: $font-stack-general-1;
      position: relative;
      left: -19px;
      top: -2px;
      font-size: 0.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.recipy {
    .row {
      margin: 0 0 30px;

      h3 {
        margin-bottom: 0;
      }
    }

    .recipy-details {
      background-color: #f5f8fb;
      padding: 17px;
      margin: -10px 0 30px 0;

      img {
        vertical-align: text-top;
        margin-right: 9px;
      }

      span:nth-child(2) {
        margin-left: 40px;
      }

      span {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
      }
    }

    .recipy-ingredients {
      //margin-bottom: -10px;
      margin-bottom: 20px;

      .title {
        margin-bottom: 26px;
      }

      p {
        margin: 0;
      }
    }

    .recipe-preparation {
      margin-top: 30px;

      .step {
        margin-top: 30px;

        .wp-caption-text {
          margin-top: -23px;
        }
      }
    }
  }

  .sidebar {
    display: none;

    @media (min-width: $mobile-breakpoint) {
      margin-top: -30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 40px;
    }
  }

  .native-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      max-width: 150px;
      width: auto !important;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .share-aside,
    .comments-section {
      display: none;
    }

    .author-info {
      border-top: 1px solid #ecedf0;
      border-bottom: 1px solid #ecedf0;
      padding: 10px 0;
      font-family: "Playfair Display",sans-serif;
      letter-spacing: 1px;
      width: 90%;
      margin: 25px auto;
    }

    &.recipy {
      .newsletter-area {
        display: none;
      }

      .most-read-area {
        display: none;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .share {
      margin-bottom: 0;
    }
  }
}
