.last-articles {
  .element {
    .video-thumbnail {
      position: absolute;
      opacity: 1;
      width: auto !important;
      height: 50px;
      top: 41%;
      left: 43.5%;
      z-index: 1;

      @media screen and (min-width: 1025px) {
        top: 20%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 18%;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        top: 13%;
      }
    }
  }

  @media (max-width: $mobile-breakpoint - 1) {
    .element {
      &.native-article {
        .thumbnail-area {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 98%;
            //box-shadow: 0 -5px 0 #ff5859;
            height: 50px;
          }
        }

        a {
          color: white;

          img {
            display: inline-block;
          }
        }

        .content,
        .content-area {
          background-color: black;
          color: white;
        }
      }
    }

    .sponsored {
      position: absolute;
      top: 0;
      z-index: 1;
      //margin: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 2px 5px;
      font-family: "Montserrat", Arial, Verdana, Geneva, sans-serif;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 15px;
      display: flex;
      align-items: center;
      color: black;
      margin: 10px;

      a {
        color: #000 !important;
      }
    }
  }

  @media (min-width: $mobile-breakpoint) {
    background-color: #f0f0f0;
    padding: 60px 0 0;
    margin: 0;

    // Título categoria
    .description {
      font-family: "Montserrat", Arial, Verdana, Geneva, sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 10px 0 30px;
      font-weight: 300;
      text-align: center;
      font-size: 14px;
    }

    // Comportamento hover
    img:hover {
      opacity: 0.75;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    h2 {
      margin: 0;
      max-height: 120px;
      overflow: hidden;
      //white-space:nowrap;
      //text-overflow: ellipsis;
    }

    h6 {
      margin-bottom: 0;

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #7f7f7f;
        font-size: 10px;

        &:hover {
          color: black;
        }
      }
    }

    .social-media {
      img {
        height: 15px;
      }
    }

    hr {
      text-align: center;
      margin: 0 auto;
      border-top: 5px solid $main-color;
      width: 50px;
    }

    .read-article {
      margin: 0 0 12px;
      padding: 0;

      & hr {
        border: 0.5px solid #ff5859;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 17px;
      }
    }

    .sponsored {
      position: absolute;
      top: 0;
      z-index: 1;
      margin: 10px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 2px 5px;
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 15px;
      display: flex;
      align-items: center;
      color: black;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }

    .col-md-4 {
      margin-bottom: 40px;
      height: 100%;

      .element {
        text-align: center;
        background-color: white;

        .inside {
          padding: 20px;
          height: 230px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h6 {
            margin-bottom: 12px;
          }

          .social-media {
            display: flex;
            flex-direction: row;
            justify-content: center;

            figure {
              margin: 0 12px;
            }
          }
        }

        &.native-article {
          margin-top: 0;

          a {
            color: white;

            &:hover {
              color: #878787;
            }
          }

          .sponsored a {
            color: black;
            text-decoration: none;

            &:hover {
              color: black;
            }
          }

          .inside {
            background-color: black;
            color: white;
          }
        }
      }
    }

    .col-md-12 {
      text-align: center;
      margin-bottom: 90px;

      &.article {
        .element {
          max-width: 618px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .category {
            margin: 10px 0;
          }

          .thumbnail-area {
            &.native-article {
              //box-shadow: -5px -5px 0 $main-color;
              //height: 100%;
            }
          }

          .read-article {
            position: relative;
            width: 100%;
            text-align: center;
            bottom: 0;
            margin: 0 0 12px;
            padding: 0;
          }
        }
      }

      .content-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 25px !important;
      }

      padding: 0;
      //margin-right: 50px;

      .element {
        height: 390px;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;

        &.native-article {
          .thumbnail-area {
            //box-shadow: -5px -5px 0 $main-color;
          }

          a {
            color: white;

            &:hover {
              color: #878787;
            }
          }

          .sponsored a {
            color: black;
            text-decoration: none;

            &:hover {
              color: black;
            }
          }

          .content,
          .content-area {
            background-color: black;
            color: white;
          }
        }

        &:hover {
          cursor: pointer;

          .content-area {
            z-index: 5;
          }
        }

        .thumbnail-area {
          display: inline-block;
          //height: 100%;
        }

        .social-media {
          position: absolute;
          right: 10px;
          top: -150px;
          width: 25px;

          figure {
            padding-bottom: 16px;
          }
        }

        .content-area {
          background-color: white;
          padding: 20px;
          position: absolute;
          width: 359px;
          height: 227px;
          right: -50px;
          bottom: -50px;
        }
      }
    }
  }
}
