.page-template-openmag_single {
  .main-content {
    .mobile-container {
      margin-bottom: 0;

      .article-content {
        margin: 0;

        .post-container .row {
          margin: 0 0 20px;
        }
      }
    }

    .container-fluid {
      margin-bottom: 0;
    }
  }
}

.page-template-openmag_single,
.page-template-openmag_page {
  #footer .sv-newsletter {
    @media screen and (max-width: $mobile-breakpoint - 1) {
      display: none;
    }
  }
}

.cta-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $mobile-breakpoint) {
    flex-direction: row;
  }
}

.cta__wrapper {
  padding: 0 20px;

  @media screen and (min-width: $mobile-breakpoint) {
    padding: 0;
  }

  br,
  p {
    display: none;
  }
}

.cta {
  br {
    display: none;
  }

  display: inline-block;
  min-width: 170px;
  padding: 0 10px;
  margin: 0 0 20px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 0 12px;
  }

  .button__wrapper {
    display: flex;
    min-height: 45px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: initial;
    color: white !important;
    text-decoration: none;

    a:hover {
      color: #fff;
    }

    img {
      display: none;
    }
  }

  &:not(.disabled):hover {
    background-color: black;
    border: 1px solid black;
    color: white;
    text-decoration: none;
  }

  img {
    width: 20px !important;
    height: 20px;
    margin: -5px -3px 0 6px;
  }

  img.gallery {
    width: 35px !important;
    height: auto;
    margin: 0 10px 0 0;
  }

  &.cta-pink {
    background-color: $om-color;
    border: 1px solid $om-color;
    color: white;

    &:active {
      background-color: black;
      border: 1px solid black;
    }

    &:visited,
    &:focus {
      color: white;
      text-decoration: none !important;
    }

    &.active {
      background-color: black;
      border: 1px solid black;
      color: white;
      text-decoration: none;
    }

    &.cta-pink--bordered {
      border: 1px solid #fff;
    }
  }

  &.cta-white {
    background-color: white;
    border: 1px solid $om-color;
    color: $om-color;

    &:visited,
    &:focus {
      color: $om-color;
      text-decoration: none !important;
    }

    &.active {
      background-color: black;
      border: 1px solid black;
      color: white;
      text-decoration: none;
    }
  }

  &.cta-ghost {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    &:visited,
    &:focus {
      color: white;
      text-decoration: none !important;
    }

    &.active {
      background-color: black;
      border: 1px solid black;
      color: white;
      text-decoration: none;
    }
  }
}

.cta-box {
  background-color: $om-color;
  min-height: 180px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (min-width: 415px) {
    padding: 40px;
  }

  p {
    font-family: $font-stack-general-1;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: white;
    margin-bottom: 30px;
  }
}

.om-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 0;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: -10px 0 30px;
  }

  .om-section-title {
    margin: 20px 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin: 30px 0;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
    margin-bottom: 5px;

    @media screen and (min-width: $mobile-breakpoint) {
      //justify-content: space-between;
      max-width: 800px;
      margin-bottom: 30px;
    }

    .tab-dash {
      //width: 100px;
      width: 100%;
      border-top: 5px solid $om-color;
      margin: 5px auto 0;

      @media screen and (min-width: $mobile-breakpoint) {
        //width: 132px;
      }
    }

    .tab {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      a,
      span {
        //font-family: "melloner-fun", sans-serif;
        font-family: $font-stack-general-1;
        font-size: 21px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: black;

        @media screen and (min-width: $mobile-breakpoint) {
          font-size: 30px;
        }
      }

      &-active {
        a {
          color: black;
        }

        &.no-touch:hover {
          a {
            color: #9d9d9d;
          }

          .tab-dash {
            border-top: 5px solid #9d9d9d;
          }
        }
      }

      &-inactive {
        a {
          color: #9d9d9d;
        }

        .tab-dash {
          display: none;
        }

        &.no-touch:hover {
          a {
            color: black;
          }
        }
      }
    }
  }
}

.om-section-title {
  text-align: center;
  margin: 45px 0 50px;

  hr {
    width: 50px;
    margin: 0 auto;
  }

  .hr-white {
    border-top: 4px solid white;
  }

  .hr-pink {
    border-top: 4px solid $om-color;
  }

  h1 {
    font-family: $font-stack-general-1;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 3px;
    margin: 5px 0 0;
  }

  h2 {
    font-family: 'Melloner Fun', sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 1.688rem;
    margin-top: 15px;
    color: black;
    margin: 0;
    letter-spacing: 1px;

    @media screen and (min-width: $mobile-breakpoint) {
      font-size: 80px;
      line-height: 1.875rem;
    }

    &.summer {
      font-family: "melloner-fun", sans-serif;
      font-size: 25px;
      font-weight: 400;
      line-height: 1.688rem;
      margin-top: 5px;

      @media screen and (min-width: $mobile-breakpoint) {
        font-family: "melloner-fun", sans-serif;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.875rem;
      }
    }
  }
}

.om-menu {
  display: flex;
  justify-content: center;
  padding: 0;

  .om-menu-dropdown {
    display: flex;
    align-self: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid black;
    width: 100%;
    margin: 30px 0 40px;

    @media screen and (min-width: $mobile-breakpoint) {
      flex-direction: row;
      background-color: transparent;
      border: none;
      margin: 0;
      justify-content: center;
    }

    .button-menu {
      font-family: $font-stack-general-1;
      text-decoration: none;
      width: 100%;
      height: 50px;
      color: black;
      text-transform: uppercase;
      font-weight: 300px;
      letter-spacing: 2px;
      font-size: 14px;
      line-height: 19px;
      border-radius: none;
      margin: 0;
      text-align: left;
      padding: 0 25px;
      display: flex;
      align-items: center;

      @media screen and (min-width: $mobile-breakpoint) {
        width: 190px;
        height: 190px;
        color: white;
        font-weight: 400px;
        letter-spacing: none;
        font-size: 17px;
        line-height: 23px;
        border-radius: 50%;
        margin: 30px 15px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      &.active {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid black;
        min-height: 50px;

        @media screen and (min-width: $mobile-breakpoint) {
          display: none;
        }

        .dropdown-arrow {
          background-image: url("../images/dropdown-arrow_black.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 22px;
          height: 20px;
        }

        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;

          .dropdown-arrow {
            background-image: url("../images/dropdown-arrow_white.svg");
            border: 0;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &.onde {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_onde.png");
          border: 0;
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      &.goodie-bag {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_goodie-bag.png");
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      &.talks-workshops {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_talks-workshops.png");
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      &.espacos-experiencias {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_espacos-experiencias.png");
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      &.sunset-party {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_sunset-party.png");
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      &.news {
        background-image: none;

        @media screen and (min-width: $mobile-breakpoint) {
          background-image: url("../images/open-mag/om-menu_news.png");
          background-size: cover;
        }

        &.selected,
        &:hover,
        &:active {
          background-color: $om-color;
          background-image: none;
          color: white;
        }
      }

      // Opções desactivadas
      &.disabled {
        opacity: 0.4;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}

.om-bottom-menu {
  background-color: #f0f0f0;
  margin: 0;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 20px 0 50px;
  }

  .om-section-title {
    margin: 10px 0 0;

    .hr-pink {
      margin-bottom: 10px;
    }
  }

  .om-menu-dropdown {
    display: flex;
    justify-content: center;
  }
}

.om-section-text {
  p {
    font-family: $font-stack-general-1;
    color: black;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
    font-weight: 300;

    @media screen and (min-width: $mobile-breakpoint) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 60px;
    }

    a {
      color: $om-color !important;
      text-decoration: none !important;
    }
  }
}

.om-info {
  display: flex;
  justify-content: center;
}

.om-box {
  background-image: url("../images/om-box.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1032px;
  height: 100%;
  text-align: center;
  margin: 50px 0;
  padding: 50px 15px;

  @media screen and (min-width: $mobile-breakpoint) {
    padding: 80px;
    margin: 30px 0 70px;
  }

  .om-section-title {
    margin-top: 0;
    margin-bottom: 20px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 50px;
    }

    h1 {
      color: white;
    }
  }

  .box-content {
    max-width: 590px;
    margin: 0 auto;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-bottom: 20px;
      }

      &:hover {
        opacity: 1;
      }
    }

    h2 {
      font-family: $font-stack-general-1;
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      color: white;
      letter-spacing: 0.5px;
      margin: 0 0 20px;
    }

    p {
      font-family: $font-stack-general-1;
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
      color: white;
      margin-bottom: 20px;
      letter-spacing: 0.5px;

      a {
        color: $om-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.om-partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  min-height: 200px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 0 0 50px;
    min-height: unset;
  }

  .om-section-title {
    margin: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin: 30px 0;
    }

    h1 {
      color: black;
    }
  }

  .partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      flex-direction: row;
      margin: 0;
    }

    .partner__wrapper {
      padding: 20px;

      &.white {
        background: #fff;
      }
    }

    img {
      max-width: 270px;
      max-height: 170px;
      margin: 10px;

      @media screen and (min-width: $mobile-breakpoint) {
        //margin: 40px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.tickets-bar {
  display: none;
  background-color: $om-color;
  height: 65px;
  width: 100vw;
  align-items: center;
  position: fixed;
  top: 92px;
  z-index: 1;

  @media screen and (min-width: $mobile-breakpoint) {
    height: 80px;
  }

  .container {
    margin-bottom: 0 !important;
  }

  .tickets-bar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $mobile-breakpoint) {
      flex-wrap: wrap;
      justify-content: center;
    }

    p {
      font-family: $font-stack-general-1;
      color: white;
      font-size: 19px;
      font-weight: 300;
      margin-bottom: 0;

      @media screen and (max-width: $mobile-breakpoint) {
        display: none;
        /*font-size: 13px;
        width: 100%;
        text-align: center;*/
      }
    }

    .tickets-bar-ctas {
      display: flex;
      flex-direction: row;

      .cta {
        min-width: 210px;

        @media screen and (max-width: $mobile-breakpoint) {
          margin-bottom: 0;
        }
      }

      .cta__wrapper {
        @media screen and (max-width: $mobile-breakpoint) {
          padding: 0;
          margin-top: 10px;
        }

        .button__wrapper {
          @media screen and (max-width: $mobile-breakpoint) {
            min-height: 35px;
          }
        }
      }
    }
  }
}
