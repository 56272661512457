body {
  &.admin-bar{
    padding-top: 67px;
    .header-menu {
      top: 32px;
    }
  }
  &.with-customized-header{
    margin-top: 60px;
    @media (min-width: $mobile-breakpoint) {
      margin-top: 200px;
    }
    #menu-search-area{
      .search-form-container{
        top: 80px;
        @media (min-width: $mobile-breakpoint) {
          top: 198px;
        }
      }
    }
    &.article{
      @media (min-width: $mobile-breakpoint) {
        padding-top: 97px;
      }
    }
    .main-content{
    }
  }
  &.article {
    margin-top: 100px;
    padding: 0;
    text-align: inherit;
  }
}

main {
  margin-top: 20px;

  @media (min-width: $mobile-breakpoint) {
    margin-top: 32px;
  }
}

.container,
.container-fluid {
  margin-bottom: 0;

  @media (min-width: $mobile-breakpoint) {
    margin-bottom: 40px;
  }
}

.mobile-card-row {
  margin-bottom: -20px;
}

.mobile-post-card {
  margin: 20px auto;
  max-width: 400px;
  padding: 0 10px;

  &.feed-pub {
    .element {
      position: relative;
      margin-top: 30px !important;

      /*
      &:before {
        content: 'PUB';
        position: absolute;
        top: -20px;
        right: 0;
        font-family: $font-stack-general-1;
        font-size: 10px;
      }
      */
    }
  }

  .element.native-article {
    .thumbnail-area::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 98%;
      //box-shadow: 0 -5px 0 #ff5859;
      height: 50px;
    }

    .content,
    .content-area {
      background-color: black;
      color: white;
    }

    .content-area a {
      color: white;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    .sponsored {
      position: absolute;
      top: 0;
      z-index: 1;
      margin: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 2px 5px;
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 15px;
      display: flex;
      align-items: center;
      color: black;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}

body {
  &.newsletter_mobile_fixed_bar,
  &.gs-mobile-bottom-large {
    padding-bottom: 52px;

    @media (min-width: $mobile-breakpoint) {
      padding-bottom: 0;
    }
  }

  .main-content {
    .container,
    .container-fluid {
      .article {
        &.mobile-post-card {
          margin-bottom: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .container {
    &.mobile-container {
      padding-right: 0;
      padding-left: 0;

      .post-container {
        padding-right: 0;
        padding-left: 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        .author,
        .tags {
          padding: 0 20px;
        }

        p {
          &.has_image.has_size-full {
            padding: 0;
          }
        }
      }
    }
  }
}
