.social-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  margin: 0 10px;

  @media screen and (min-width: $mobile-breakpoint) {
    height: 25px;
    width: 25px;
    margin: 0;
  }

  &:hover {
    opacity: 0.75;
  }

  &.facebook {
    background-color: #3b5998;
  }

  &.pinterest {
    background-color: #bd081c;
  }

  &.whatsapp {
    background-color: #25d366;
  }

  &.email {
    background-color: #000;
  }

  img {
    height: 18px !important;

    @media screen and (min-width: $mobile-breakpoint) {
      height: 18px !important;
    }
  }
}

.element.native-article {
  .social-button.email {
    background-color: white;
  }
}

.single-item,
.two-articles {
  .social-button.email {
    background-color: #000 !important;
  }
}