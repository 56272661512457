@media (max-width: $mobile-breakpoint - 1) {
  .sv-08-latest {
    margin-top: 15px;
    text-align: center;

    h2 {
      /* padding-bottom: 0; */
      margin: 0 0 35px;

      a {
        min-height: 90px;
      }
    }

    h5 {
      margin-bottom: 15px;
    }

    h6 {
      .read-article {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #7f7f7f;
        font-size: 10px;
      }
    }

    a {
      color: black;

      &:hover {
        text-decoration: none;
      }
    }

    .single-item {
      margin-bottom: 150px;

      .article {
        .element {
          .thumbnail-area {
            &.native-article {
              height: 100%;
            }
          }
        }
      }
    }

    .article {
      padding: 0 10px;
      //margin-right: 50px;
      .element {
        height: 390px;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;

        &:hover {
          cursor: pointer;

          /* .overlay{
             height: 385px;
             width: 620px;
             background: rgba(255,255,255,.25);
             position: absolute;
             left: 0;
             top: 0;
             z-index: 0;
           } */
          .content-area {
            z-index: 5;
          }

          /* .img-responsive{
            opacity: 0.5;
          } */
          h2 a {
            color: #515151;
          }

          .h5 a {
            color: #515151;
          }

          .read-article {
            color: #000;
          }
        }

        .thumbnail-area {
          position: relative;
          display: inline-block;
          height: 100%;
          width: 100%;
          min-height: 20px;
          background: #f0f0f0;
          max-height: 185px;

          @media (min-width: 380px) {
            max-height: 225px;
          }

          &:hover {
            .overlay {
              height: 110%;
              width: 620px;
              background: rgba(255, 255, 255, 0.25);
              position: absolute;
              left: 0;
              top: 0;
              z-index: 0;
            }
          }

          img {
            height: auto;
          }
        }

        .social-media {
          //position: absolute;
          //right: 10px;
          //top: -160px;
          //width: 25px;

          figure {
            //padding-bottom: 16px;
          }
        }

        .content-area {
          background-color: white;
          padding: 15px 20px 25px;
          //position: absolute;
          //width: 359px;
          //height: 227px;
          //right: -50px;
          //bottom: -50px;
        }
      }
    }

    .single-item {
      .article {
        .element {
          //max-width: 618px;
          //max-height: 385px;
          .content-area {
            // right: 75px;
          }
        }
      }
    }

    .post-image-thumbnail {
      .img-responsive {
        width: 100%;
      }
    }

    .category-main-title {
      background-repeat: no-repeat;
      background-position: top center;
      text-align: center;
      padding-top: 10px;
      width: 145px;
      line-height: 22px;
      height: 60px;
    }

    .mobile-social {
      display: none;
    }

    .element {
      &.native-article {
        .thumbnail-area {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 98%;
            //box-shadow: 0 -5px 0 #ff5859;
            height: 50px;
          }
        }

        /*
        .nativelabel {
          display: block !important;
          position: absolute;
          padding: 2px;
          background: #fff;
          opacity: 0.8;
          top: 5px;
          left: 5px;
          text-transform: uppercase;
          font-family: Montserrat;
          font-size: 10px;
          font-weight: 300;
          line-height: 1.8;
          text-align: left;
          color: #000;
        }
        */

        a {
          color: white;

          img {
            display: inline-block;
          }
        }

        .content,
        .content-area {
          background-color: black;
          color: white;
        }
      }
    }

    .sponsored {
      position: absolute;
      top: 0;
      z-index: 1;
      //margin: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 2px 5px;
      font-family: "Montserrat", Arial, Verdana, Geneva, sans-serif;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 15px;
      display: flex;
      align-items: center;
      color: black;
      margin: 10px;

      a {
        color: #000 !important;
      }
    }

    .video-thumbnail {
      position: absolute;
      opacity: 1;
      width: auto !important;
      height: 50px;
      top: 41%;
      left: 43.5%;
      z-index: 1;

      @media screen and (min-width: 1025px) {
        top: 20%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 18%;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        top: 13%;
      }
    }

    .mobile-newsletter-cta {
      display: none;
    }

    .mobile-newsletter-cta {
      display: block;
    }

    .article {
      //margin: 0;

      &.feed-pub {
        .element {
          display: flex;
          justify-content: center;
        }
      }

      .element {
        height: auto;

        .overlay {
          display: none;
        }

        .content-area {
          position: relative;
          width: auto;
          height: auto;
          bottom: auto;
          right: auto;

          .cards__mobile-social-media {
            .share-icons {
              display: flex;
              width: 80%;
              margin: 0 auto;
              justify-content: space-around;

              figure {
                a {
                  img {
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .single-item {
      margin-bottom: 0;

      .article {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .element {
          .thumbnail-area {
            &.native-article {
              //box-shadow: -5px -5px 0 $main-color;
            }
          }
        }
      }
    }

    .social-media {
      display: none;
    }
  }
}

@media (min-width: $mobile-breakpoint) {
  .sv-08-latest {
    text-align: center;
    margin-bottom: 0;

    // Título categoria

    .category-main-title {
      background-repeat: no-repeat;
      background-position: top center;
      text-align: center;
      padding-top: 10px;
      width: 145px;
      line-height: 22px;
      height: 60px;
    }

    // Comportamento hover

    .wp-post-image:hover {
      opacity: 0.75;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    h2 {
      margin: 0;
      max-height: 120px;
      overflow: hidden;
    }

    h6 {
      margin-bottom: 0;

      .read-article {
        margin: 0 0 12px;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #7f7f7f;
        font-size: 10px;

        &:hover {
          color: black;
        }
      }
    }

    .social-media {
      img {
        //height: 15px;
      }
    }

    // Destaque

    .single-item {
      margin-bottom: 60px;
      margin-left: -50px;

      .article {
        .element {
          height: 390px;

          .thumbnail-area {
            &.native-article {
              margin-top: 0;

              a {
                color: white;
                text-decoration: none;

                &:hover {
                  color: #878787;
                }
              }
            }
          }
        }
      }

      .content-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 25px !important;
      }

      .video-thumbnail {
        position: absolute;
        opacity: 1;
        width: auto !important;
        height: 50px;
        top: 41%;
        left: 47%;
        z-index: 1;

        @media screen and (min-width: 1025px) {
          top: 30%;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          top: 30%;
        }

        @media (min-width: 481px) and (max-width: 768px) {
          top: 30%;
        }
      }
    }

    .four-items {
      max-height: 270px;
      margin-bottom: 0;
      background-color: $color-background-option;
      padding: 34px 0;
      text-align: left;
      margin-left: -30px;
      margin-right: -30px;

      .contentarea {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
      }

      .four-articles {
        max-width: 223px;

        &:first-child {
          padding-right: 40px;
        }

        &:nth-child(2) {
          /* padding-left: 0; */
        }

        .content {
          img {
            max-width: 193px;
          }
        }

        h6 {
          margin-top: 16px;
        }

        &.native-article {
          margin-top: 0;

          .category {
            color: $main-color;

            &:hover {
              color: #878787;
            }
          }

          .post-image-thumbnail {
            //box-shadow: -5px -5px 0 $main-color;
          }

          .sponsored {
            a {
              color: black;
              text-decoration: none;

              &:hover {
                color: black;
              }
            }
          }
        }
      }

      .category {
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    .article {
      padding: 0;
      //margin-right: 50px;
      .element {
        height: 320px;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;
        position: relative;

        &:hover {
          cursor: pointer;

          .content-area {
            z-index: 5;
          }
        }

        .thumbnail-area {
          //display: inline-block;
          //height: 100%;
        }

        .social-media {
          position: absolute;
          right: 10px;
          top: -150px;
          width: 25px;
          padding-top: 22px;

          figure {
            padding-bottom: 16px;
          }
        }

        .content-area {
          background-color: white;
          //padding: 20px;
          padding: 30px 20px;
          position: absolute;
          width: 359px;
          height: 227px;
          right: -50px;
          bottom: -50px;
        }
      }
    }

    .two-items {
      .article {
        /*
        width: 35%;
        display: inline-block;
        margin: 0 auto;
        */

        .element {
          width: 80%;

          @media screen and (min-width: $tablet-breakpoint +1) {
            width: 433px;
          }

          &.native-article {
            margin-top: 0;
          }

          h2 {
            overflow: hidden;
          }

          .social-media {
            position: absolute;
            right: -43px;
            top: 0;
            padding-top: 22px;
          }

          .thumbnail-area {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;

            @media screen and (min-width: $tablet-breakpoint +1) {
              width: 435px;
              height: 272px;
            }
          }

          .video-thumbnail {
            position: absolute;
            opacity: 1;
            width: auto !important;
            height: 50px;
            top: 41%;
            left: 43.5%;
            z-index: 1;

            @media screen and (min-width: 1025px) {
              top: 30%;
            }

            @media (min-width: 768px) and (max-width: 1024px) {
              top: 30%;
            }

            @media (min-width: 481px) and (max-width: 768px) {
              top: 35%;
            }
          }

          .content-area {
            position: absolute;
            right: -50px;
            bottom: 0;
            z-index: 7;
            height: 172px;
            width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.native {
              background-color: black;
              color: white;
            }

            h2 {
              /*font-size: 1rem;
              line-height: 1.3rem;*/
              font-size: 1.25rem;
              line-height: 1.5rem;
              padding-bottom: 0;
              margin-bottom: 0;
              margin-top: 12px;
            }

            h5 {
              margin-top: 5px;
            }
          }
        }
      }

      div:nth-of-type(2) {
        .article {
          .element {
            float: left;
          }
        }
      }
    }

    .single-item {
      .article {
        .element {
          max-width: 618px;
          //max-height: 385px;
          &.native-article {
            margin-top: 0;
          }

          .content-area {
            // right: 75px;
          }
        }
      }
    }

    .post-image-thumbnail {
      .latest-article__link {
        display: block;
        width: 100%;
        max-height: 225px;
        overflow: hidden;

        @media (min-width: 768px) {
          max-height: 140px;
        }

        @media (min-width: 992px) {
          max-height: 184px;
        }

        @media (min-width: 1200px) {
          max-height: 225px;
        }

        > img {
          max-width: 100%;
          height: auto;
        }
      }

      .img-responsive {
        width: 100%;
      }
    }

    .three-items {
      //   width: 70%;
      //  margin: 0 auto;
      .elements-data {
        max-width: 70%;
        margin: 0 auto;
      }

      .feed-pub {
        .element {
          position: relative;
          background: none;
          display: flex;
          justify-content: flex-end;

          /*
          &:before {
            content: 'PUB';
            position: absolute;
            top: -20px;
            right: 0;
            font-family: $font-stack-general-1;
            font-size: 10px;
          }
          */
        }
      }

      .element {
        background-color: #fff;

        &.native-article {
          margin-top: 0;

          /*
          .nativelabel {
            display: block !important;
            position: absolute;
            width: auto;
            padding: 2px 4px 1px 8px;
            background-color: #fff;
            opacity: 0.8;
            top: 10px;
            left: 25px;
            text-transform: uppercase;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 300;
            letter-spacing: 0.15em;
            line-height: 1.8;
            text-align: center;
            color: #000;

            a {
              color: #000;
              text-decoration: none;

              &:hover {
                color: #878787;
              }
            }
          }
          */
        }

        .post-image-thumbnail {
          height: auto;
        }

        .content {
          padding: 20px;
          height: 230px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h6 {
            margin-bottom: 12px;
          }

          .social-media {
            display: flex;
            flex-direction: row;
            justify-content: center;

            figure {
              margin: 0 12px;
            }
          }
        }

        box-shadow: 5px 5px 5px #f2f2f2;
      }
    }

    .mobile-social {
      display: none;
    }

    .element {
      &.native-article {
        .thumbnail-area {
          //box-shadow: -5px -5px 0 $main-color;
        }

        /*
        .nativelabel {
          display: block !important;
          position: absolute;
          padding: 2px;
          background: #fff;
          opacity: 0.8;
          top: 5px;
          left: 5px;
          text-transform: uppercase;
          font-family: Montserrat;
          font-size: 10px;
          font-weight: 300;
          line-height: 1.8;
          text-align: left;
          color: #000;
        }
        */

        .sponsored {
          a {
            color: black;
            text-decoration: none;

            &:hover {
              color: black;
            }
          }
        }

        a {
          color: white;
          text-decoration: none;

          &:hover {
            color: #878787;
          }
        }

        .content,
        .content-area {
          background-color: black;
          color: white;
        }
      }
    }

    .sponsored {
      position: absolute;
      top: 0;
      z-index: 1;
      margin: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 2px 5px;
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 1px;
      height: 15px;
      display: flex;
      align-items: center;
      color: black;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }

    .single-item,
    .two-items,
    .three-items {
      .sponsored {
        margin: 10px;
      }
    }

    .video-thumbnail {
      position: absolute;
      opacity: 1;
      width: auto !important;
      height: 50px;
      top: 41%;
      left: 43.5%;
      z-index: 1;

      @media screen and (min-width: 1025px) {
        top: 20%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 18%;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        top: 13%;
      }
    }

    .mobile-newsletter-cta {
      display: none;
    }
  }
}
