.sv-diabetes-latest {
  .sv-diabetes-articles-container {
    .container {
      margin-bottom: 0;

      @media (max-width: $mobile-breakpoint) {
        margin-bottom: 5px;
      }
    }
  }
}