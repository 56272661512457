.ad {
  position: relative;
  clear: both;

  &.ad--mobile {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
      display: none;
    }
  }

  &.ad--tablet {
    display: none;

    @media (min-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: 768px) {
      display: none;
    }

    &.ad--tablet--fullrow {
      margin: 0 auto;
    }
  }

  &.ad--desktop {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.amp-ad {
    display: flex;
    justify-content: center;
  }
}
