.sv-form-contact-us {
  background-color: $color-background-option;

  .form-container {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (min-width: $mobile-breakpoint) {
      padding: 40px;
      flex-direction: row;
    }

    .info-area {
      text-align: center;

      &.info-area-mob {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f5f8fb;
        margin-bottom: -5px;
        padding: 0 10px;

        @media (min-width: $mobile-breakpoint) {
          margin-bottom: 10px;
        }

        .info-area-mob__img {
          margin-bottom: 20px;
          width: 150px;
        }

        p {
          font-family: $font-stack-general-1;
          font-weight: 300;
          font-size: 13px;
          margin-bottom: 0;

          @media (min-width: $mobile-breakpoint) {
            font-size: 17px;
          }
        }
      }

      h2 {
        font-weight: bold;
      }

      p {
        font-weight: 200;
      }

      .text-with-separator {
        hr {
          margin: 20px auto;
          margin-bottom: 10px;
          width: 50px;
        }

        p {
          padding: 20px;
          margin: 0;
          letter-spacing: 1px;
          font-weight: 400;
          padding-top: 0;
        }

        &.text-with-separator-mob {
          hr {
            margin: 15px auto;
            width: 40px;
          }

          h2 {
            font-family: $font-stack-general-1;
            font-weight: bold !important;
            font-weight: 300;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 0;

            @media (min-width: $mobile-breakpoint) {
              font-size: 17px;
            }
          }

          h2:first-of-type {
            font-size: 15px;
            font-family: "Playfair Display";
            color: rgb(0, 0, 0);
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            margin-top: 0;
            margin-bottom: 15px;

            @media (min-width: $mobile-breakpoint) {
              font-family: $font-stack-general;
              font-weight: 700;
              font-size: 20px;
              text-transform: initial;
            }
          }

          .text-with-separator-mob__title {
            font-family: $font-stack-general-1;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 13px;

            @media (min-width: $mobile-breakpoint) {
              font-size: 17px;
              line-height: 1.2;
            }
          }

          p {
            font-family: $font-stack-general-1;
            font-weight: 300;
            text-transform: initial;
            padding: 0;
            margin-bottom: 25px;

            @media (min-width: $mobile-breakpoint) {
              font-size: 17px;
              margin-bottom: 10px;
            }

            a {
              color: #000;
            }

            &.email {
              a {
                font-family: $font-stack-general-1;
                font-weight: 300;
                font-size: 17px;
                color: black;
              }
            }
          }

          p:last-of-type {
            margin-top: 30px;
            margin-bottom: 0;
          }
        }
      }

      .social {
        figure {
          display: inline-block;
          padding: 5px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .social-mob {
        margin-bottom: 40px;

        img {
          width: 35px;
        }
      }
    }

    .form-area {
      background-color: white;
      padding: 10px 24px 24px;
      border: 1px solid $main-color;
      font-family: $font-stack-general-1;
      color: #575756;
      font-size: 0.75rem;
      margin-bottom: 0;
      width: 100%;

      @media (min-width: $mobile-breakpoint) {
        padding: 24px;
        width: 475px;
        margin-bottom: 10px;
      }

      .wpcf7-validation-errors,
      .wpcf7-acceptance-missing,
      .wpcf8-response-output,
      .wpcf7-mail-sent-ok {
        border: none;
        padding: 0;
        font-size: 14px;
        color: black;
        margin: 30px 0 0;
      }

      label {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.67;
        letter-spacing: 1.4px;
        text-align: left;
        color: white;
        width: 100%;
      }

      input,
      textarea {
        background-color: #f5f8fb;
        border: 0;
        width: 100%;
      }

      textarea {
        height: 130px;
        resize: none;
      }

      input[type=submit] {
        width: 139px;
        height: 40px;
        background-color: #ff5859;
        color: #fff;
        border-radius: 0;
        float: right;
      }

      .call-to-action {
        font-family: $font-stack-general-1;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 300;
        font-size: 12px;
        margin: 10px 0;
      }

      .form-email,
      .form-message {
        width: 100%;
        margin-bottom: 20px;
        border: none;
        padding: 15px;
        background-color: $color-background-option;
        text-transform: uppercase;
        font-weight: 400;
      }

      .btn-subscribe {
        border: none;
        float: right;
      }

      .form-inner__mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        input[type="text"],
        input[type="email"] {
          font-family: $font-stack-general-1;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 300;
          font-size: 12px;
          padding: 10px 60px 10px 10px;
        }

        input[type="submit"] {
          text-transform: uppercase;
          font-size: 12px;
        }

        textarea {
          font-family: $font-stack-general-1;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 300;
          font-size: 12px;
          padding: 10px 60px 10px 10px;
        }
      }
    }
  }
}

.wpcf7-form {
  .wpcf7-form-control-wrap {
    input,
    textarea {
      width: 100%;
      background-color: $color-background-option;
      //color: white;
      color: black;
      border: none;
      min-height: 40px;
      font-family: $font-stack-general-1;
      font-size: 12px;
      letter-spacing: 1px;
      padding: 10px 15px;
      margin-bottom: 10px;

      &:focus {
        border-color: black;
      }
    }
  }

  .wpcf7-submit {
    background-color: $main-color;
    border: none;
    min-height: 40px;
    min-width: 140px;
    color: white;
    font-family: $font-stack-general-1;
    font-size: 12px;
    letter-spacing: 1px;

    &:hover {
      background-color: $color-black;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        background-color: $main-color;
      }
    }
  }

  .wpcf7-not-valid-tip {
    /*
    color: $main-color;
    font-size: 12px;
    font-weight: normal;
    display: block;
    margin-left: 15px;
    */
    display: none;
  }

  .wpcf7-validation-errors,
  .wpcf7-acceptance-missing,
  .wpcf8-response-output,
  .wpcf7-mail-sent-ok {
    border: none;
    padding: 0;
    margin: 20px 15px;
    font-size: 14px;
    color: black;

    @media (min-width: $mobile-breakpoint) {
      margin: 0 0 20px;
    }
  }

  .form-container {
    display: initial;

    @media screen and (min-width: $mobile-breakpoint) {
      display: flex;
      justify-content: center;
    }
  }

  .first-block,
  .second-block {
    display: block;
    margin: 0 16px;

    @media screen and (min-width: $mobile-breakpoint) {
      display: inline-block;
      margin: 0;
    }
  }

  .conditions {
    text-align: left;
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding: 0;

    ///// CUSTOM CHECKBOX /////

    input[type=checkbox] {
      visibility: hidden;
    }

    p {
      margin: 0;
    }

    .custom-checkbox {
      width: 12px;
      height: 12px;
      position: relative;
    }

    /* custom check */
    .custom-checkbox label {
      cursor: pointer;
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      left: 0;
      background: #eef1f4;
    }

    /* checked state */
    .custom-checkbox label:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      background: transparent;
      top: 20%;
      left: 15%;
      border: 2px solid #000;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
    }

    /* checkbox hover */
    .custom-checkbox label:hover::after {
      opacity: 0.4;
    }

    /* checkbox state */
    .custom-checkbox label.active:after {
      visibility: initial;
      opacity: 1;
    }

    .disclaimer {
      font-family: Montserrat,Arial,Verdana,Geneva,sans-serif;
      font-weight: 700;
      font-size: 14px;
      padding-top: 0;
      text-align: left;
      color: #000;
      width: 100%;
      margin-left: 10px;

      a {
        color: $main-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.custom-checkbox {
  label {
    &.active {
      &:after {
        opacity: 1 !important;
        border-color: #fff !important;
      }
    }
  }
}
