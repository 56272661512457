.hmp-002-mob {
  background-color: #fff;
  text-align: center;

  .thumbnail-area {
    position: relative;
  }

  .mobile-social {
    bottom: 0;
  }

  .content-area {
    padding: 15px 20px 25px;

    @media (min-width: $mobile-breakpoint) {
      padding: 20px;
    }

    .cards__mobile-social-media {
      .share-icons {
        display: flex;
        width: 60%;
        margin: 0 auto;
        justify-content: space-around;

        figure {
          a {
            color: #000;

            img {
              //width: 20px;
            }
          }
        }
      }
    }

    h2 {
      margin: 0 0 35px;

      a {
        min-height: 90px;
      }
    }

    h5 {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  .dividing-line {
    position: relative;
  }
}
