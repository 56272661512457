/*
New newsletter module
 */

/* mobile */
.newsletter-footer-mobile,
.newsletter-article-mobile {
  padding: 20px 10px;
  background: #f0f0f0;
  height: 100%;

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }
}

// MÓDULO VERTICAL HOMEPAGE

.newsletter-homepage {
  padding-bottom: 20px;
}

.sv-newsletter-vertical {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;

  /*
  &.sv-newsletter-vertical--card {
    width: 360px;
    max-width: 100%;

  }

  &[data-newsletter-side-fixed] {
    position: absolute;
    z-index: 999;
    width: 325px;
    padding-bottom: 30px;
    background-color: transparent;
  }
  */

  .noise-texture {
    padding: 35px 0 0;
    height: 100%;
    width: 100%;
    background-image: url("../images/noise-texture.png");
    background-repeat: repeat;
    background-size: 10%;
  }

  .box-color {
    height: 100%;
    width: 710px;
    background-color: #e84d75;
  }

  img:hover {
    opacity: 1;
  }

  button {
    background: transparent;
    border: none;
    margin: 0;

    &:hover {
      opacity: 0.75;
    }
  }

  .newsletter-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: white;

    .mono-logo {
      height: 55px;
      width: auto;
      margin: 0 0 20px;
    }

    h3 {
      font-family: $font-stack-general;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      margin: 0 0 20px;
      letter-spacing: 0.05rem;
      padding: 0 10px;

      @media screen and (min-width: 480px) {
        font-size: 28px;
        line-height: 34px;
        padding: 0;
      }
    }

    p {
      font-family: $font-stack-general-1;
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      max-width: 355px;
      margin: 0;
    }

    .wpcf7-mail-sent-ok {
      text-align: center;
      margin-top: 0;
      border: 0;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-response-output,
    .wpcf7-acceptance-missing {
      margin: 0;
      color: white;
      border: none;
      font-size: 12px;
      background-color: #000;
      padding: 8px 5px;
    }

    .wpcf7-form-control-wrap input {
      margin: 0;
    }

    .wpcf7-form-control-wrap input,
    textarea {
      color: white;
    }

    .newsletter_error {
      font-family: $font-stack-general-1;
      font-size: 10.2px;
      font-weight: 300;
      line-height: 1.75;
      letter-spacing: 0.3px;
      text-align: left;
      color: #000;
    }

    .newsletter_success {
      text-align: center;
      margin-top: 40px;
    }

    .ajax-loader {
      margin: 10px auto 0;
      display: block;
    }

    div.wpcf7 {
      width: 100%;
    }

    form {
      color: white;
      font-family: $font-stack-general-1;
      margin-top: 10px;

      @media screen and (min-width: 480px) {
        margin-top: 30px;
      }

      p {
        display: inline-block;
        margin: 0;
        width: 100%;

        @media screen and (min-width: 480px) {
          margin: 0 5px 0 0;
          width: unset;
        }
      }

      label {
        width: 100%;
        margin: 0;
      }

      input[type="email"] {
        outline: none;
        border: 1px solid white;
        background-color: transparent;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        min-height: 45px;
        font-weight: 300;
        width: 100%;
        padding: 10px;
        margin: 0 0 10px;

        @media screen and (min-width: 480px) {
          margin: 0;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: white;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: white;
          opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: white;
          opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }

        &:-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
      }

      input[type="submit"] {
        background-color: white;
        color: black;
        border: 1px solid white;
        padding: 10px 35px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        min-height: 46px;
        width: 100%;
        border-radius: 0;

        &:hover,
        &:active {
          color: white;
          background-color: black;
          border: 1px solid black;
        }
      }
    }

    .conditions {
      text-align: left;
      margin-bottom: 20px;

      input[type=checkbox] {
        margin: 0;
      }

      .disclaimer {
        padding-top: 0;
        text-align: left;
        font-size: 12px;
        font-weight: 300;
        color: #fff;
        width: 100%;
        margin-left: 5px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    &.success {
      h3 {
        max-width: 450px;
        margin-bottom: 50px;
      }
    }
  }
}

.newsletter_share-title {
  font-family: $font-stack-general-1;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px !important;
  text-align: center;
  margin-bottom: 15px !important;
}

.newsletter_share-social {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #000;
    margin: 0 15px;

    img {
      height: 25px;
      width: auto;
    }
  }
}
