html {
  font-size: 16px;
  color: $color-black;

  p:empty {
    display: none;
  }

  body {
    // font-size: 1.125rem;
  }

  h1 {
    font-family: $font-stack-general;
    font-size: 1.875rem;
    line-height: 2.375rem;
    font-weight: bold;
  }

  h2 {
    font-family: $font-stack-general;
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: bold;
  }

  h3 {
    font-family: $font-stack-general;
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: bold;
  }

  h4 {
    font-family: $font-stack-general;
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: bold;
  }

  h5 {
    font-size: 0.6875rem;
    font-family: $font-stack-general-1;
  }

  h6 {
    font-size: 0.6875rem;
    font-family: $font-stack-general-1;
    color: $color-background-option;
  }

  p {
    font-family: $font-stack-general-1;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  span {
    font-family: $font-stack-general-1;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  a {
    color: $main-color;

    &:hover {
      color: $main-color;
    }
  }

  strong {
    font-weight: 700;
  }

  ul {
    list-style: none;
    padding: 0 0 0 40px;
    margin: 0;

    li {
      text-indent: -0.7em;
    }

    li::before {
      content: "• ";
      color: $main-color; /* or whatever color you prefer */
      font-weight: bold;
    }
  }

  ol {
    counter-reset: item;
    padding-left: 25px;

    li {
      display: block;
    }

    li::before {
      content: counter(item) ". ";
      counter-increment: item;
      color: $main-color;
      font-weight: bold;
    }
  }

  hr {
    border-top: 1px solid #b7b7b7;
    margin-top: 40px;
    margin-bottom: 0;
  }

  @media (max-width: $mobile-breakpoint) {
    h1 {
      font-size: 1.313rem;
      line-height: 1.688rem;
    }

    h2 {
      font-size: 1.313rem;
      line-height: 1.688rem;
    }

    h3 {
      // @todo h3 mobile está onde?

      /*  font-size: 1.375rem;
      line-height: 1.688rem; */
    }

    h4 {
      font-size: 0.8125rem;
      line-height: 1rem;
    }

    h5 {
      font-size: 0.625rem;
    }

    h6 {
      font-size: 0.625rem;
    }
  }
}

