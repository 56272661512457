.tag-page {
  .page-title {
    /*  margin-bottom: 46px; */
    p {
      padding: 20px 0;
    }

    .description {
      font-family: $font-stack-general-1;
      letter-spacing: 1px;
      margin: 0 auto 30px;
      font-weight: 300;
      text-align: center;
      font-size: 14px;
      max-width: 90%;
      text-transform: initial;

      @media (max-width: $mobile-breakpoint) {
        margin: 0 auto 10px;
      }
    }
  }

  h1 {
    font-family: $font-stack-general-1;
    text-align: center;
    text-transform: capitalize;
  }

  hr {
    border-top: 2px solid $main-color;
    margin: 0 auto;
    width: 10%;
  }
}
