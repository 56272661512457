#comments {
  display: none;

  .comments-area-overlay {
    height: 100vh;
    width: 80vw;
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(234, 234, 234, 0.5);
  }

  .comments-area {
    overflow-y: scroll;
    padding: 0 30px;
    height: 100vh;
    width: 20vw;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;

    .comments-heading {
      text-transform: uppercase;
      letter-spacing: 1px;

      p {
        font-size: 0.625rem;
        color: $main-color;
        display: inline-block;
        line-height: 40px;
        margin: 0;
        padding-right: 5px;
      }

      .close-comments {
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    input,
    textarea {
      background-color: $color-background-option;
      width: 100%;
      padding: 5px;
      border: 0;
      text-transform: uppercase;
      font-family: $font-stack-general-1;
      font-size: 10px;
      letter-spacing: 1px;

      &:active,
      &:focus {
        outline: 0;
      }

      &[type="submit"] {
        background-color: $main-color;
        color: white;
        padding: 10px;
        font-family: $font-stack-general-1;
        text-align: center;
        font-size: 0.75rem;
        display: block;
        width: 100px;
        border-radius: 0;

        &:hover,
        &:active {
          color: white;
          background-color: black;
        }
      }
    }

    .comment-after {
      font-family: $font-stack-general-1;
      font-size: 10px;
      color: #767575;
      line-height: 1rem;
    }

    .comment-list {
      padding: 0;

      li {
        &::before {
          content: '';
        }
      }

      .says,
      .commentmetadata {
        display: none;
      }

      .comment-author {
        cite {
          font-style: normal;
        }

        font-family: $font-stack-general;
        margin: 10px 0;
        font-size: 1rem;
      }

      p {
        font-size: 10px;
        font-family: $font-stack-general-1;
      }

      .reply {
        a {
          color: $main-color;
          text-transform: uppercase;
          font-size: 10px;
          text-decoration: underline;
          letter-spacing: 1px;
        }
      }
    }
  }
}
