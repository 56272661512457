footer {
  .container {
    margin-bottom: 0;
  }

  .container-fluid {
    margin-bottom: 0;

    @media (min-width: $mobile-breakpoint) {
      margin-bottom: 40px;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  img:hover {
    opacity: 0.75;
  }

  .instagram {
    padding: 0;
    text-align: center;
    background-color: $main-color;
    color: white;
    margin: 52px 0;
    margin-bottom: 0;

    @media (min-width: $mobile-breakpoint) {
      margin: 0;
    }

    &.instagram-mob {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .follow-cta {
      margin-top: 50px;
    }

    p {
      padding: 19px 0;
      font-size: 12px;
      text-transform: none;
    }

    .followup {
      border-radius: 0;
      width: 75%;
      background: white;
      color: $main-color;
    }

    .feed-image {
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  .newsletter-area {
    padding: 20px 0 10px;
    background: #f0f0f0;
  }

  .footer-menu {
    background-color: $color-background;
    padding: 0;

    .no-padding {
      padding: 0;
      margin-bottom: 50px;
    }

    .no-gutters {
      padding: 0;
    }

    .footer-sv-logo {
      width: 160px;
    }

    .logo-area {
      margin-bottom: 30px;
    }

    .footer__links {
      margin-top: 15px;

      @media (min-width: $mobile-breakpoint) {
        margin-top: 25px;
      }

      a {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        font-family: $font-stack-general-1;
        margin-bottom: 0;
        color: #000;
        text-decoration: none;

      }
    }

    .footer__mobile-social,
    .mobile-social {
      display: none;
      text-align: center;
    }

    .top-cta {
      text-align: right;

      a {
        text-decoration: none;
        background-color: black;
        text-transform: uppercase;
        color: white;
        font-size: 0.875rem;
        line-height: 2rem;
        padding: 10px;
      }
    }

    .menu {
      font-family: $font-stack-general-1;
      padding: 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 300;

      .mb-20 {
        margin-bottom: 20px;
      }

      li {
        display: block;
        float: initial;
        text-indent: unset;

        &::before {
          content: '';
        }

        &.extra-margin {
          margin-top: 1rem;
        }

        a {
          padding: 5px 0;
          color: black;

          &:hover {
            background-color: unset;
            color: #878787;
          }
        }
      }

      .footer-social {
        margin-top: 0;

        figure {
          display: inline-block;
          padding: 10px 10px 10px 0;

          @media (min-width: $mobile-breakpoint) {
            padding: 10px 20px 10px 0;
          }

          img {
            height: 18px;
          }
        }

        h3 {
          font-family: $font-stack-general;
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 10px;
          text-transform: none;
        }
      }
    }

    .social-media {
      .title-container {
        padding: 0;
      }

      .sv-newsletter {
        min-height: 0;
        color: black;
        background-color: unset;
        padding: 0;

        h3 {
          margin-top: 0;
          height: auto;
          font-family: $font-stack-general;
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
          text-align: left;
        }

        form {
          margin-bottom: 20px;

          input {
            display: block;
            width: 100%;
            font-size: 12px;
            font-weight: 300;

            &[type="email"] {
              background-color: white;
              color: $main-color;
              border: 1px solid $main-color;
              margin-bottom: 10px;

              &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color: $main-color;
              }

              &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: $main-color;
                opacity: 1;
              }

              &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: $main-color;
                opacity: 1;
              }

              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $main-color;
              }

              &:-ms-input-placeholder { /* Microsoft Edge */
                color: $main-color;
              }
            }

            &[type="submit"] {
              background-color: $main-color;
              color: white;

              &:hover,
              &:active {
                background-color: black;
              }
            }
          }
        }

        .disclaimer {
          color: #757575;

          a {
            color: #757575;
            text-decoration: underline;
          }
        }
      }

      .newsletter_share-title {
        text-align: left;
      }

      .newsletter_share-social {
        text-align: left;
        justify-content: flex-start;
        margin-bottom: 0;

        a {
          margin: 0 30px 0 0;
          background-color: $main-color;
        }

        a:last-of-type {
          margin: 0;
        }
      }

      .footer-social {
        &.mobile-only {
          display: none;
        }
      }
    }

    hr {
      border-top: 1px solid #e1e1e1;
    }

    .registered-container {
      background-color: $color-background-option;
    }

    .company-year {
      margin: 15px 0;
      text-transform: none;
      font-family: $font-stack-general-1;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .social-media {
    padding: 0;

    .sv-newsletter {
      .col-xs-6 {
        padding: 0;

        h3 {
          text-align: left;
        }
      }
    }
  }

  .back-to-top {
    min-width: 60px;
    display: block;
    float: right;
    text-align: center;
    background-color: black;
    color: white;

    .text {
      letter-spacing: 1px;
      padding: 5px;
      font-weight: 300;
    }

    &:hover {
      cursor: pointer;
    }

    /* background-repeat: no-repeat;
    background-position: center top; */
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .instagram {
      margin-top: 0;

      .follow-cta {
        padding: 10px 0;
        margin-top: 20px;
        //padding: 25px;
      }
    }

    .top-cta {
      display: none;
    }

    .no-padding {
      margin: 20px 0;
    }

    .footer-menu {
      .container {
        padding: 0;
      }

      .footer-sv-logo {
        max-width: 100%;
      }

      .logo-area {
        margin-bottom: 0;
      }

      .footer__mobile-social,
      .mobile-social {
        display: flex;
        margin: 0 auto;
        width: 80%;
        justify-content: space-around;

        @media (min-width: $mobile-breakpoint) {
          display: block;
          width: 100%;
        }

        figure {
          display: inline-block;
          padding: 20px 5px;

          a {
            img {
              width: 20px;
            }
          }
        }
      }
    }

    ul {
      li {
        padding-left: 15px;
      }
    }

    .footer-social {
      display: none;

      &.mobile-only {
        display: block;

        figure {
          display: inline-block;
          padding: 10px;
        }
      }
    }

    .footer-menu {
      .social-media {
        .sv-newsletter {
          padding: 0;
          border-top: 1px solid #e1e1e1;

          .title-container {
            margin-top: 16px;
          }

          form {
            input {
              font-size: 0.625rem;
              display: inline-block;
              width: 45%;
            }
          }

          .disclaimer {
            display: block;
            padding: 0 16px;
          }
        }
      }
    }

    .disclaimer {
      @media (min-width: $mobile-breakpoint) {
        display: none;
      }
    }
  }

  .footer-year {
    background-color: $color-background-option;
  }

  .company-year {
    margin: 23px 0;
    font-family: $font-stack-general-1;
    font-size: 12px;
    font-weight: normal;
  }
}

