.image-attribution {
  font-family: $font-stack-general-1;
  font-weight: 300;
  font-size: 9px;
  margin-top: 10px;
  color: #9d9d9c;
  text-transform: uppercase;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-left: 20px;
  }
}