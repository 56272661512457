/*
  Ficheiro inicial SCSS
  Neste ficheiro apenas devem ser feitos imports de partials, tudo o resto deve estar organizado por secções
*/


// Variables
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/variables/_generic.scss";


// Mixins
//

// Fonts
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_melloner-fun.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_montserrat.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_old-standard.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_permnantmarker.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_playfair.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_sacramento.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/fonts/_summerhearts.scss";


// Bootstrap
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/bootstrap/_bootstrap.scss";


// Base
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/base/_containers.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/base/_typography.scss";


// Common
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/_cookies.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/_cta-sv.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/_social-buttons.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/_sv-privacy.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/_utilities.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/native/_native-article.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/newsletter/_lp-newsletter.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/newsletter/_newsletter-bar.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/newsletter/_sv-newsletter-horizontal.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/newsletter/_sv-newsletter-vertical.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/newsletter/_sv-newsletter.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-archive.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-article.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-event.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-general.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-homepage.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/openmag/_om-newsletter.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/popups/_modal-newsletter.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/common/popups/_popup-viver-melhor.scss";


// Components
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_404.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_ads.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_article-related.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_article.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_author.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_breadcrumbs.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_buttons.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_comments.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_cpt006.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_customized-header.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_editors-pick.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_footer.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_forms.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_header.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_hmp-002.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_image-attribution.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_last-articles.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_magazine-template.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_mobile-menu.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_mortgage-simulator.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_most-read.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_progressbar.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_quizz.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_quotes.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_recipes_group.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_recipes-group-single.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_search.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_subcategory.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv_cardio.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv_diabetes.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-01.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-02.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-03.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-04.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-05.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-06.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-08.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-newsletter.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv-people-area.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv07-partners.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_sv09_category_selector.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/components/_tag.scss";


// Plugins
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/plugins/_slick.scss";


// Templates
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/public_regular/helpers/_general.scss";



// Services-oriented
// @import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/cardio/_cardio.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/diabetes/_diabetes.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/gallery/_gallery.scss";

@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/cardio/_cardio.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/cardio/_cardio.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/diabetes/_diabetes.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/diabetes/_diabetes.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/gallery/_gallery.scss";
@import "/Users/pedrosaldanha/Projects/saberviver/saberviver-2.0/wp-content/themes/saberviver/src/resources/styles/services/gallery/_gallery.scss";
