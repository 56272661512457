.article {
  &.om-article {
    .cta {
      margin: 20px 0 !important;
    }

    .sidebar {
      @media screen and (max-width: $mobile-breakpoint - 1) {
        display: none;
      }
    }

    .breadcrumbs {
      display: none;

      @media screen and (min-width: $mobile-breakpoint) {
        display: block;
      }
    }

    hr {
      border-color: #ecedf0;
    }

    background-color: white;

    // OPEN MAG

    .om-author-info {
      border-bottom: none !important;
    }

    .om-content {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 30px;

      @media (min-width: $mobile-breakpoint) {
        margin-left: 0;
        margin-right: 0;
      }

      .cta__wrapper {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: $mobile-breakpoint) {
          //padding: 0 20px;
        }
      }

      .om-info-box {
        //margin-left: -20px;
        //margin-right: -20px;
      }
    }

    p {
      a {
        color: $om-color;

        &:hover {
          text-decoration: underline;
        }
      }

      span .highlight-first {
        color: $om-color !important;
      }
    }

    .om-info-box {
      margin-bottom: 50px;

      h3 {
        margin-bottom: 40px;
        padding: 0 20px;
      }

      .om-info-row {
        display: flex;
        flex-direction: column;
        margin: 25px 0;

        @media screen and (min-width: 415px) {
          flex-direction: row;
        }

        .om-info-icon {
          height: 75px;
          width: 90px;
          margin: 0 0 20px;

          @media screen and (min-width: 415px) {
            margin: 0 15px 0 0;
          }
        }

        h3,
        p {
          margin: 0;
          padding: 0 20px;

          @media screen and (min-width: $mobile-breakpoint) {
            padding: 0;
          }
        }
      }
    }

    p {
      img {
        margin: 15px 0 0 !important;

        &:hover {
          opacity: 1;
        }
      }
    }

    .share-aside,
    .share {
      figure a {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;

        @media screen and (min-width: $mobile-breakpoint) {
          width: 25px;
          height: 25px;
        }

        &.fb-social {
          background-color: #3b5998;
        }

        &.pinterest-social {
          background-color: #bd081c;
        }

        &.whatsapp-social {
          background-color: #25d366;
        }

        &.email-social {
          background-color: black;
        }
      }

      img {
        height: 22px;

        @media screen and (min-width: $mobile-breakpoint) {
          height: 11px;
        }
      }
    }

    .share-aside {
      //display: block;

      .share-aside-mobile {
        display: flex;

        @media screen and (min-width: $mobile-breakpoint) {
          display: block;
        }

        figure {
          padding: 10px;
        }
      }
    }

    .share {
      background-color: $color-background-option;

      .icons {
        display: flex;
        flex-direction: row;
      }

      figure a {
        margin: 10px;
      }
    }
  }
}

.article.campaign-card {
  margin: 15px 0 20px;
  padding: 0;

  @media screen and (min-width: 480px) {
    padding: unset;
  }

  .element-agenda {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 0 0 10px;

    .thumbnail-area {
      background-color: #b1e1ed;

      &.open-mag-spaces {
        background-color: #d8de3f;
      }


      margin: 10px 10px 0;

      img:hover {
        opacity: 1;
      }

      h1 {
        font-family: $font-stack-general;
        font-weight: 700;
        font-size: 55.5px;
        line-height: 61px;
        color: #000;
        margin: 0 15px 20px;
        position: absolute;
        bottom: 0;
      }
    }

    .content-area {
      background-color: #b1e1ed;

      &.open-mag-spaces {
        background-color: #d8de3f;
      }

      margin: 0 10px 10px;
      padding: 0 20px 20px;
      height: 204px;
      text-align: center;

      .description {
        font-family: $font-stack-general-1;
        font-weight: 300;
        text-transform: none;
        font-size: 18px;
        line-height: 27.5px;
        color: #000;
        margin: 10px 0 40px;
      }

      .cta-campaign-card {
        background-color: #fff;
        color: #000;
        padding: 15px 30px;
        font-family: $font-stack-general-1;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 12px;
        text-align: center;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
