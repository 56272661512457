#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  //cursor: pointer; /* Add a pointer on hover */
  transition: all 0.4s ease;
}

img:hover {
  opacity: 0.75;
}

.mobile-menu-opened #overlay {
  opacity: 1;
  display: block;
}

.navbar {
  .navbar-nav {
    display: flex;
    justify-content: space-around;
    float: none;
    vertical-align: bottom;
    margin: 0 -15px;

    @media screen and (min-width: $mobile-breakpoint) {
      justify-content: center;
    }
  }

  .navbar-collapse {
    text-align: center;
  }

  .main-header {
    background-color: $color-background-option;
    //height: 45px !important;

    &.under-shadow {
      box-shadow: 0 0 50px -15px;
    }
  }

  .container .navbar-brand,
  .container-fluid .navbar-brand {
    margin-left: -15px;
    padding-top: 5px;
  }
}

nav {
  .container-fluid,
  .container {
    margin-bottom: 0;
  }
}

.pre-header {
  text-transform: uppercase;
  font-family: $font-stack-general-1;
  background-color: #fff;

  .left-side {
    padding-right: 0;
    padding-left: 0;
    margin-top: 15px;

    img {
      height: 15px;
    }
  }

  .text-right {
    margin-top: 11px;

    .search-icon {
      height: 25px;
    }
  }

  figure {
    display: inline-block;
    padding: 0 12px;

    img {
      vertical-align: bottom;
      height: 28px;
    }
  }

  .mobile-search {
    display: none;
  }
}

.header-container {
  padding: 0;
}

body.mobile-menu-opened .header-menu {
  left: 250px;
}

.header-menu {
  background-color: transparent;
  font-family: $font-stack-general-1;
  border: none;
  text-transform: uppercase;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999999;
  width: 100%;
  min-height: auto;
  transition: left 0.5s ease;

  .menu-item {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    text-indent: 0;
    border-bottom: 5px solid #f5f8fb;

    @media screen and (min-width: $mobile-breakpoint) {
      font-size: 12px;
      margin: 0 30px;
    }

    &.about {
      margin-right: 33px;
    }

    &.newsletter {
      margin-left: 33px;
    }

    &.empowering-women {
      img {
        height: 20px;
      }
    }

    &.openmag {
      img {
        height: 24px;
      }
    }
  }

  a {
    &.menu-item {
      color: black;
      text-decoration: none;
    }
  }

  li {
    &.menu-item {
      &:hover {
        border-bottom: 5px solid #c6c6c6;

        a {
          color: #c6c6c6;
        }
      }

      &.current_page_item,
      &.current-menu-item,
      &.current-menu-parent {
        border-bottom: 5px solid $color-black;

        a {
          color: $color-black;
        }

        &:hover {
          border-bottom: 5px solid #c6c6c6;

          a {
            color: #c6c6c6;
          }
        }
      }

      &.current_page_item {
        border-bottom: 2px solid $main-color;
      }

      &::before {
        content: '';
      }

      a {
        color: black;
        padding: 0;
        margin: 10px 0 5px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 10px 10px 5px;
        }
      }
    }
  }

  li {
    &.menu-item.more {
      &.open {
        border-bottom: 5px solid #ff5859;

        > a {
          color: #000;
          background-color: transparent;
        }
      }

      i {
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        margin: 0 0 2px 6px;
      }

      .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .up {
        animation: transform ease-in-out 0.4s;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      &:hover {
        border-bottom: 5px solid $main-color;

        a {
          color: $main-color;
        }
      }
    }
  }

  .more-sub-menu {
    display: none;
    background-color: #000;
    margin: 0 auto;
    font-family: $font-stack-general-1;
    text-align: left;
    width: 100vw;
    max-width: none;
    height: 100vh;
    padding: 25px 40px 55px;
    overflow: scroll;

    @media screen and (min-width: $mobile-breakpoint) {
      width: 100%;
      max-width: 570px;
      height: auto;
      padding: 30px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 15px;
      position: absolute;
      left: calc(50vw - 285px);
    }

    .header-sub-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 35px;

      h6 {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $main-color;
        margin: 0;
      }

      .close-sub-menu {
        margin-top: -5px;

        img {
          height: 15px;
          width: 15px;

          @media screen and (min-width: $mobile-breakpoint) {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    a:hover {
      text-decoration: none;
      opacity: 0.75;
    }

    .social-title {
      font-family: $font-stack-general;
      font-size: 14px;
      font-weight: 300;
      text-transform: none;
      margin: 0 0 15px;
    }

    .sub-menu {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
      }

      ul {
        padding: 0;
        flex-basis: 33%;
      }

      li {
        text-indent: 0;

        &::before {
          content: none;
        }
      }

      li:not(:last-child) {
        margin-bottom: 35px;
      }

      .cta-sv {
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        .cta-sv-wrapper {
          height: auto;
          font-size: 9px;
          margin: 0 !important;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 10px;
          }
        }

        .cta-sv-main:hover {
          background-color: #fff;
          border-color: #fff;
          color: #000;
        }
      }

      .sub-menu-item {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        padding-right: 5px;

        a {
          color: #fff;
        }

        img {
          max-width: 85px;
          height: auto;
        }
      }

      .newsletter-mobile {
        display: block;
        border-top: 1px solid $main-color;
        border-bottom: 1px solid $main-color;
        padding: 35px 0;

        @media screen and (min-width: $mobile-breakpoint) {
          display: none;
        }
      }

      .sub-menu-info {
        margin-top: 35px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin-top: 0;
        }
      }

      .sub-menu-social {
        color: #fff;
        border-top: 1px solid $main-color;
        padding-top: 35px;
        margin-top: 35px;

        @media screen and (min-width: $mobile-breakpoint) {
          border: none;
          padding: 0;
          margin: 0;
        }

        li {
          border-bottom: 1px solid $main-color;
          padding-bottom: 30px;
        }

        li.social-networks {
          padding-bottom: 40px;
          margin-bottom: 65px;

          @media all and (min-width: $mobile-breakpoint) {
            margin-bottom: 0;
          }
        }

        li:last-child {
          padding-bottom: 35px;
        }

        .newsletter-desktop {
          display: none;

          @media screen and (min-width: $mobile-breakpoint) {
            display: block;
          }
        }

        img {
          max-width: 100px;
          height: auto;
        }

        .social {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          li {
            border: none;
            padding: 0;
            margin: 0 20px 0 0;

            a {
              border-radius: 50%;
              width: 26px;
              height: 26px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.facebook {
                background-color: #3b5998;
              }

              &.pinterest {
                background-color: #bd081c;
              }

              &.instagram {
                background-color: #fff;
              }

              &.youtube {
                background-color: #d92722;
              }
            }

            h6 {
              margin-bottom: 20px;
            }

            img {
              height: 12px;
              width: auto;
            }
          }
        }
      }
    }
  }

  .navbar-brand,
  .search-sub-menu {
    display: none;
    text-align: right;
    float: none;
  }

  .search-sub-menu {
    position: relative;
    top: -10px;
  }

  .menu-item-content {
    display: none;
    //padding: 0;
    width: 72vw;
    left: 13vw;
    position: absolute;
    padding: 10px;
    border-top: 2px solid #fff;
    background-color: #f5f8fb;

    /* box-shadow: 0 10px 15px #ededed; */
    overflow: hidden;

    .subcategories {
      margin-bottom: 30px;
    }

    .element {
      .card {
        height: 164px;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-top: 0;
        text-align: center;
        color: #fff;

        h3 {
          font-family: 'Montserrat', sans-serif;
          font-size: 9.8px;
          font-weight: 300;
          line-height: 1.59;
          letter-spacing: 1.2px;
          text-align: center;
          color: #fff;
          margin: 0;
          padding: 0;
        }

        h2 {
          font-family: 'PlayfairDisplay', serif;
          font-size: 15px;
          font-weight: bold;
          line-height: 0.83;
          text-align: center;
          color: #fff;
          margin-bottom: 27px;
        }

        hr {
          width: 32px;
          height: 3px;
          background-color: #fff;
          border: 0;
          margin: 10px auto;
        }

        p {
          margin-top: 0;
          font-family: 'Montserrat', sans-serif;
          font-size: 10px;
          font-weight: 300;
          line-height: 1.5;
          letter-spacing: 1.2px;
          text-align: center;
          color: #fff;
        }
      }

      &:hover {
        a {
          text-decoration: none;
        }

        img {
          opacity: 0.5;
        }
      }
    }

    h3 {
      font-size: 0.875rem;
      margin-top: 15px;
      line-height: 1rem;
    }

    .subcategories {
      .title {
        padding-left: 15px;
      }
    }

    .header-tag-separator {
      border-top: 1px solid black;
      margin-top: 20px;

      .content {
        padding: 0;
        margin-top: 10px;

        span {
          letter-spacing: 1px;
        }
      }
    }

    h2 {
      letter-spacing: 1px;
      font-family: $font-stack-general-1;
      font-size: 0.875rem;
      font-weight: 300;
    }

    h3 {
      letter-spacing: 0;
      color: black;
      text-transform: initial;
      font-weight: 900;
    }

    img {
      width: 100%;
    }

    .recipes-categories-container {
      padding-left: 15px;
      padding-right: 15px;

      .element {
        &:hover {
          .post-image-thumbnail {
            opacity: 0.5;
          }

          .category-title-container {
            background-color: black;
          }
        }
      }

      .category-title-container {
        margin: 0 auto;
        right: 0;
        bottom: 0;
        width: 111px;
        background-color: #ff5859;
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h5 {
          margin: 0;
          text-align: center;
          text-transform: uppercase;
          color: white;
          font-family: $font-stack-general-1;
          font-size: 0.625rem;
          letter-spacing: 1.2px;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          line-height: 1.2;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .left-side,
    .text-right {
      display: none;
    }

    #mobile-menu-controller {
      border: unset;
      background: url('../images/open-mobile-menu.png') no-repeat scroll 7px 7px;
      position: relative;
      float: right;
      padding: 15px 20px;
      margin-top: 8px;
      margin-right: 0;
      margin-bottom: 0;

      &.active {
        background: url('../images/close-mobile-menu.png') no-repeat scroll 7px 7px;
      }
    }

    .mobile-search {
      display: block;
      float: right;
      width: 26px;
      text-align: right;
      padding: 0;

      a {
        img {
          width: 20px;
        }
      }
    }

    .pre-header {
      .text-center {
        padding: 0;

        .navbar-header {
          display: inline-block;
          float: left;
        }
      }
    }

    .mobile-menu-header {
      display: none;

      &.active {
        display: block;
        background: white;
        height: 100vh;
        width: 100vw;
        overflow: auto;
      }

      .latest,
      .categories {
        margin: 12px;

        .title {
          font-family: $font-stack-general-1;
          font-size: 1rem;
          letter-spacing: 1px;
          font-weight: 500;
        }

        .latest-container,
        .categories-container {
          white-space: nowrap;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          .element {
            display: inline-block;
            max-width: 130px;

            a {
              color: black;
            }

            img {
              width: 110px;
              height: 70px;
            }

            h3 {
              white-space: normal;
              text-transform: initial;
              line-height: 1rem;
              letter-spacing: 0;
              font-size: 0.75rem;
              margin-top: 10px;
            }

            h5 {
              font-size: 0.4375rem;
            }
          }
        }
      }

      .categories-container {
        .element {
          h3 {
            font-family: "Montserrat", Arial, Verdana, Geneva, sans-serif;
            background-color: #ff5859;
            position: relative;
            left: 0;
            top: -65px;
            margin: 21px 18px;
            height: 20px;
            padding: 5px;
            color: white;
            font-size: 4px;
            font-weight: 500;
            text-align: center;
            line-height: 10px;
          }
        }
      }

      .mobile-header-menu-social {
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;

        figure {
          display: inline-block;
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .search-close {
    display: none;
  }
}

.clearall {
  clear: both;
}
