.sv09-category-selector {
  display: none;

  @media (max-width: $mobile-breakpoint) {
    display: block;
    margin: 30px 0;

    .text-with-separator {
      hr {
        width: 37px;
      }
    }

    .elements-container {
      padding: 0 20px;

      .element {
        font-family: $font-stack-general-1;
        font-size: 10px;
        border: 1px solid $main-color;
        text-transform: uppercase;
        margin: 0 2.5px 2.5px 0;
        letter-spacing: 1px;

        .fill {
          background-color: $main-color;
          color: white;
        }
      }
    }
  }
}
