/* summerhearts - regular */
/*
@font-face {
  font-family: 'summerhearts';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: local(‘summerhearts’), url('../fonts/summerhearts.woff2') format('woff2'), url('../fonts/summerhearts.woff') format('woff'), url('../fonts/summerhearts.ttf') format('truetype');
}
*/
