/*
New newsletter module
 */

.sv-newsletter-horizontal {
  background-color: #cc5191;
  text-align: right;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 552px;
  max-width: 100%;

  @media screen and (min-width: $tablet-breakpoint) {
    width: 925px;
  }

  .noise-texture {
    height: 100%;
    width: 100%;
    background-image: url("../images/noise-texture.png");
    background-repeat: repeat;
    background-size: 20%;
    padding: 25px 45px 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      padding: 30px 30px 30px 50px;
      background-size: 15%;
    }
  }

  .box-color {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #cc5191 40%, transparent 40%), linear-gradient(180deg, #f552a7 60%, #ea5272 60%);
  }

  img:hover {
    opacity: 1;
  }

  button {
    background: transparent;
    border: none;
    margin: 0;

    &:hover {
      opacity: 0.75;
    }
  }

  .close-newsletter {
    height: 20px;
  }

  .newsletter-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: white;

    @media screen and (min-width: $tablet-breakpoint) {
      flex-direction: row;
    }

    .mono-logo {
      margin: 0;
      width: 45px;

      @media screen and (min-width: $tablet-breakpoint) {
        width: 70px;
      }
    }

    h3 {
      font-family: $font-stack-general-1;
      font-size: 17px;
      line-height: 23px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      width: 100%;
      margin: 15px 0 0;

      @media screen and (min-width: $tablet-breakpoint) {
        max-width: 280px;
        margin: 0;
      }
    }

    .wpcf7-mail-sent-ok {
      text-align: center;
      margin-top: 0;
      border: 0;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-response-output,
    .wpcf7-acceptance-missing {
      margin: 10px 0 0;
      color: white;
      border: none;
      font-size: 12px;
      width: 100%;

      @media screen and (min-width: $tablet-breakpoint) {
        max-width: 428px;
      }
    }

    .wpcf7-form-control-wrap input,
    textarea {
      color: white;
    }

    .newsletter_error {
      font-family: $font-stack-general-1;
      font-size: 10.2px;
      font-weight: 300;
      line-height: 1.75;
      letter-spacing: 0.3px;
      text-align: center;
      color: #000;
    }

    .newsletter_success {
      text-align: center;
      margin-top: 40px;
    }

    .ajax-loader {
      margin-top: 10px;
    }

    form {
      color: white;
      font-family: $font-stack-general-1;
      letter-spacing: 0.015rem;
      text-align: center;

      p {
        display: inline-block;
        margin: 0;
      }

      input {
        margin: 0;
      }

      input[type="email"] {
        color: white;
        outline: none;
        border: 1px solid white;
        background-color: initial;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 14px;
        height: 45px;
        width: 250px;
        font-weight: 300;
        padding: 10px;
        margin: 20px 0 0;

        @media screen and (min-width: $tablet-breakpoint) {
          margin: 0;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: white;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: white;
          opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: white;
          opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }

        &:-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
      }

      input[type="submit"] {
        background-color: white;
        color: black;
        border: 1px solid white;
        padding: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 14px;
        height: 45px;
        min-width: 170px;
        border-radius: 0;
        margin-left: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          margin-left: 5px;
        }

        &:hover,
        &:active {
          color: white;
          background-color: black;
          border: 1px solid black;
        }
      }
    }
  }
}

