#breadcrumbs {
  color: $main-color;
  text-transform: capitalize;
  letter-spacing: 0;
  margin-top: -20px;
  margin-bottom: 35px;
  margin-left: 30px;

  span {
    font-size: 11px;
  }

  a {
    color: black;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .breadcrumb_last {
    color: black;
    font-weight: 500;
    text-decoration: underline;
    text-transform: initial;
  }
}

.row {
  &.breadcrumbs {
    margin-bottom: 0;
  }
}
