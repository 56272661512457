#menu-search-area {
  display: none;
  //transition: .5s all;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9990;
  top: 0;
  left: 0;
  position: fixed;

  /*   display: block;
    transition: .5s all; */

  .search-form-container {
    display: none;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    box-shadow: 0 -5px 40px #888;

    @media (min-width: $mobile-breakpoint) {
      height: 50vh;
    }

    figure {
      opacity: 0.5;
      padding: 12px;

      img {
        width: 50%;

        @media (min-width: $mobile-breakpoint) {
          width: auto;
        }
      }
    }

    .search-close {
      padding: 10px;

      @media (min-width: $mobile-breakpoint) {
        height: 54px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .mobile-search-close {
      display: none;
    }

    form {
      .screen-reader-text {
        display: none;
      }

      input {
        &[type="text"] {
          width: 50%;
          margin-top: 10vh;
          border: 0;
          text-align: center;
          font-family: $font-stack-general;
          font-size: 4.688em;

          &:active,
          &:focus {
            outline: 0;
          }

          &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: #d7d7d7;
          }

          &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #d7d7d7;
            opacity: 1;
          }

          &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #d7d7d7;
            opacity: 1;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #d7d7d7;
          }

          &:-ms-input-placeholder { /* Microsoft Edge */
            color: #d7d7d7;
          }
        }

        &#searchsubmit {
          font-family: $font-stack-general-1;
          border: 1px solid black;
          color: black;
          background: white;
          padding: 10px;
          text-transform: uppercase;
          letter-spacing: 1px;
          vertical-align: text-bottom;
          min-width: 131px;
          min-height: 45px;
          border-radius: 0;

          &:disabled {
            border: 1px solid #cdcdcd;
            color: #cdcdcd;
          }

          &:hover {
            background: black;
            color: white;
          }
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .text-center {
      &.search-box {
        padding: 18px 10px;

        .mobile-search-logo {
          width: 38%;
          margin-left: 50px;
          opacity: 0.5;
        }

        .mobile-search-close {
          display: inline-block;
          width: 22px;
          float: right;
          margin-right: 20px;
        }
      }
    }

    #searchform {
      width: 65%;
      margin: 0 auto;

      input {
        &[type="text"] {
          font-size: 37.5px;
          display: block;
          width: 100%;
        }

        &[type="submit"] {
          display: block;
          max-width: 50%;
          margin: 40px auto;
        }
      }
    }
  }
}

.search {
  main {
    @media (min-width: $mobile-breakpoint) {
      margin-top: 25px;
    }
  }
}

.search-page {
  margin-top: 30px;

  .sv06-js-articles-container {
    margin: 0 0 40px;
  }

  a:hover {
    color: #878787;
  }

  .container-header {
    margin-left: -30px;

    h5 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
      margin-bottom: 30px;
    }

    hr {
      border-top: 4px solid $main-color;
      width: 37px;
    }
  }

  a {
    text-decoration: none;
  }

  .category,
  .title,
  .description {
    color: black;
    text-decoration: none;

    &:hover {
      color: #878787;
    }
  }

  .read-article {
    color: #878787;
    margin-bottom: 0;

    a {
      color: #878787;
      text-decoration: none;

      &:hover {
        color: black;
      }
    }
  }

  img:hover {
    opacity: 0.75;
  }

  margin-top: 0;
  padding-bottom: 50px;

  .gradbar {
    background: #f2f2f2;
    background: -webkit-linear-gradient(#f2f2f2, #fff);
    background: -o-linear-gradient(#f2f2f2, #fff);
    background: -moz-linear-gradient(#f2f2f2, #fff);
    background: linear-gradient(#f2f2f2, #fff);
    height: 40px;
  }

  .container-fluid {
    margin-bottom: 0;
  }

  .contentbar {
    margin-top: -20px;
    margin-bottom: 0;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 40px;
    }

    .row {
      margin: 0;
    }

    .description,
    .title {
      min-height: 37px;
      overflow: hidden;
      //font-size: 12px;
      margin-top: 0;
    }

    .description {
      @media (min-width: $mobile-breakpoint) {
        min-height: 42px;
        max-height: 42px;
        overflow: hidden;
      }
    }
  }

  .shadow {
    background: #e8e8e8; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#e8e8e8, #fff); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#e8e8e8, #fff); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#e8e8e8, #fff); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#e8e8e8, #fff); /* Standard syntax */
  }

  #searchform {
    width: 35%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @media (min-width: $mobile-breakpoint) {
      width: 100%;
    }
  }

  #s2 {
    width: 490px;
  }

  .search-input {
    display: inline-block;
    width: 85%;
  }

  .container-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;

    hr {
      width: 35px;
      border-top: 4px solid $main-color;
    }

    h5 {
      font-family: Montserrat;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1.7px;
    }
  }

  a {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }

  hr {
    border-top: 2px solid $main-color;
    width: 3%;
    margin: 0;
  }

  .list {
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 0 !important;

    .thumbnail-area {
      padding-left: 0;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .inside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //height: 180px;
    }
  }

  .element {
    border-bottom: 1px solid #d7d7d7;
    padding: 15px 0;

    @media (min-width: $mobile-breakpoint) {
      border-bottom: 0;
      margin-bottom: 40px;
      padding: 20px 0;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      img {
        opacity: 0.5;
      }
    }

    &.native-article {
      .thumbnail-area {
        //box-shadow: -5px -5px 0 $main-color;
      }

      a {
        color: white;
        text-decoration: none;

        &:hover {
          color: #878787;
        }
      }

      .sponsored {
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 5px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2px 5px;
        font-family: $font-stack-general-1;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 1px;
        height: 15px;
        display: flex;
        align-items: center;
        color: black;

        &:hover {
          background-color: rgba(255, 255, 255, 1);
        }

        a {
          color: black !important;
          text-decoration: none !important;

          &:hover {
            color: black !important;
          }
        }
      }
    }

    &.list {
      &.native-article {
        img {
          //box-shadow: -5px -5px 0 $main-color;
        }

        h6 a {
          color: #878787;
          text-decoration: none;

          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .inside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h6 {
      margin-bottom: 0;
    }

    h4 {
      margin-top: 0;
    }
  }

  .category {
    margin-top: 0;
    font-size: 8px;
    margin-bottom: 0;
    text-transform: uppercase;
    color: black;

    @media (min-width: $mobile-breakpoint) {
      margin: 0 0 5px;
      font-size: 9px;
    }

    &:hover {
      color: #878787;
    }
  }

  .title {
    margin: 0 0 10px;
    color: black;

    &:hover {
      color: #878787;
    }
  }

  hr {
    border-width: 1px;
    width: 18px;
  }

  .description {
    margin: 0 0 15px;
    line-height: 14px;
  }

  /*
  a{
    color: black;
    text-decoration: none;
    &:hover{
      color: #878787;
    }
  }
  */

  .read-article {
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media (min-width: $mobile-breakpoint) {
      font-size: 9px;
    }
  }

  .search {
    background-color: white;
    padding-top: 1px;
    margin-bottom: 25px;

    /* margin: 33px 0 0 0; */
    .screen-reader-text {
      display: none;
    }

    input {
      &[type="text"] {
        /*  background: url('../images/icon-cancel.png') no-repeat scroll 7px 7px;
        background-position-x: 95%;
        background-position-y: center; */
        width: 85%;
        padding: 5px 5px 5px 15px;
        height: 67px;
        border: none;
        font-size: 1.25rem;
        text-align: left;
        font-family: $font-stack-general;
        color: black;

        @media (min-width: $mobile-breakpoint) {
          border-top: 0;
          border-bottom: 0;
          border-left: 1px solid #e0e1ed;
          border-right: 1px solid #e0e1ed;
          padding: 5px;
        }

        &:active,
        &:focus {
          outline: 0;
        }
      }
    }

    .delete-search {
      position: relative;

      @media (min-width: $mobile-breakpoint) {
        left: -40px;
        height: 25px;
        margin-top: 21px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .search-page-search {
      position: relative;
      left: -25px;
      top: -5px;
      height: 67px;
      width: 9%;
      border: 0;
      border-right: 1px solid #e0e1ed;
      background: unset;

      @media (min-width: $mobile-breakpoint) {
        top: 0;
        width: 64px;
      }

      img {
        height: 25px;
        margin-top: 6px;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .sidebar {
    @media (min-width: $tablet-breakpoint) {
      margin-top: 40px;
      padding-left: 70px;
    }
  }

  #sv06-load-more {
    margin: 15px auto 30px;
  }

  .results-not-found {
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 0;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    h5 {
      padding: 0 15px;
      letter-spacing: 1.2px;

      @media (min-width: $mobile-breakpoint) {
        padding: 0;
      }
    }

    h4 {
      padding: 0 15px;
      margin-top: 12px;
      color: $main-color;

      @media (min-width: $mobile-breakpoint) {
        padding: 0;
      }
    }

    .read-article {
      color: #878787;
      text-decoration: none;

      &:hover {
        color: black;
      }
    }

    .general-separator-line {
      margin: 18px auto;
      width: 90%;
      background-color: #d7d7d7;

      @media (min-width: $mobile-breakpoint) {
        margin: 18px 0;
        width: 100%;
      }
    }

    .last_articles {
      margin-top: 50px;

      @media (min-width: $mobile-breakpoint) {
        margin-top: 68px;
      }

      .article {
        margin: 20px 0;
      }

      hr {
        width: 50px;
        margin: 0 auto;
        border-top: 5px solid #ff5859;
      }

      h4 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.14;
        letter-spacing: 1.7px;
        text-align: center;
        color: #000;

        @media (min-width: $mobile-breakpoint) {
          margin-bottom: 50px;
        }
      }

      .three-items {
        text-align: center;

        .elements-data {
          margin: 0 auto;
        }

        .element {
          padding-top: 0;
          padding-bottom: 0;
          background-color: white;

          .post-image-thumbnail {
            height: 100px;

            @media (min-width: $mobile-breakpoint) {
              height: auto;
            }
          }

          &.native-article {
            //box-shadow: -5px -5px 0 $main-color;

            a {
              color: white;
            }

            .content,
            .content-area {
              background-color: black;
              color: white;
            }
          }

          &:hover {
            img {
              opacity: 0.75;
            }

            h2 a {
              color: #515151;
            }

            .h5 a {
              color: #515151;
            }

            .read-article {
              color: #000;
            }
          }

          .content {
            padding: 20px;

            .general-separator-line {
              width: 17px;
              height: 1px;
              background-color: #ff5859;
              margin: 12px auto;
            }

            h5 {
              font-size: 8px;
              font-family: "Montserrat", Arial, Verdana, Geneva, sans-serif;
              margin: 0;
            }

            h2 {
              min-height: 72px;
              max-height: 72px;
              overflow-y: hidden;
              font-family: PlayfairDisplay;
              font-size: 14.5px;
              font-weight: bold;
              line-height: 1.25;
              text-align: center;
              color: #000;

              @media (min-width: $mobile-breakpoint) {
                font-size: 16px;
              }
            }

            h6 {
              margin: 10px 0 0;
              font-size: 8px;
            }
          }

          box-shadow: 5px 5px 5px #f2f2f2;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .search {
      main {
        margin-top: 0;
      }
    }

    #searchform {
      width: 100%;
      position: relative;

      input {
        &[type="text"] {
          width: 100%;
        }
      }

      .delete-search {
        position: absolute;
        right: 15px;
        bottom: 35%;
        width: 20px;
      }
    }

    .search-page-search {
      display: none;
    }

    .search-page {
      .contentbar {
        margin-top: -20px;
        margin-bottom: 0;

        .description,
        .title {
          min-height: 37px;
          //max-height: 37px;
          overflow: hidden;
        }
      }
    }

    .element {
      margin: 0;

      .thumbnail-area {
        padding: 0;
      }

      .description {
        display: none;
      }

      hr {
        width: 17px;
        border-top: 1px solid $main-color;
      }
    }
  }
}

.search-page .pagination {
  text-align: center;
  margin: 30px 15px;

  .page-numbers {
    display: inline-block;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid $main-color;
    min-width: 20px;
    margin: 0 5px;

    &.current {
      font-weight: bold;
    }

    &.dots {
      border: none;
    }
  }
}

