.quote {
  font-family: $font-stack-general;
  font-size: 20px;
  line-height: 1.64;
  text-align: center;
  color: #000;
  padding: 40px 0;
  margin: 0 10px !important;

  @media (min-width: $mobile-breakpoint) {
    font-size: 22px;
    margin: 0 !important;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }

  &::before {
    text-align: left;
    font-family: $font-stack-general-1;
    color: #ff5859;
    content: '\201C';
    font-size: 5rem;
    line-height: 1rem;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    display: block;
    font-weight: bold;
  }

  &::after {
    font-family: $font-stack-general-1;
    font-weight: bold;
    color: #ff5859;
    content: '\201D';
    font-size: 5rem;
    line-height: 0;
    display: block;
    text-align: right;
    position: relative;
    top: 30px;
  }

  &.highlight {
    &::before,
    &::after {
      content: '';
    }
  }
}
