/*
New newsletter module
 */

///// NEWSLETTER GERAL /////

.sv-newsletter {
  .noise-texture {
    padding: 35px 0 0;
    height: 100%;
    width: 100%;
    background-image: url("../images/noise-texture.png");
    background-repeat: repeat;
    background-size: 10%;
  }

  .box-color {
    height: 100%;
    width: 100%;
    background-color: #e84d75;
  }

  // Botão de fechar modal
  .close-modal {
    text-align: right;
    padding-right: 10px;
    margin-top: -30px;
    cursor: pointer;
  }

  img:hover {
    opacity: 1;
  }

  .newsletter-content {
    .mono-logo {
      height: 55px;
      width: 55px;
      margin: 0 0 20px;
    }

    h3 {
      font-family: $font-stack-general;
      font-size: 26px;
      line-height: 30px;
      font-weight: 700;
      margin: 0 0 20px;
      letter-spacing: 0.05rem;
      padding: 0 15px;
      width: 100%;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 28px;
        line-height: 34px;
        padding: 0;
      }
    }

    p {
      font-family: $font-stack-general-1;
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
      max-width: 355px;
      margin: 0;
    }

    .wpcf7-mail-sent-ok {
      text-align: center;
      margin-top: 0;
      border: 0;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-response-output,
    .wpcf7-acceptance-missing {
      margin: 0;
      color: white;
      border: none;
      font-size: 12px;
      background-color: #000;
      padding: 8px;
    }

    .wpcf7-form-control-wrap input {
      margin: 0;
    }

    .wpcf7-form-control-wrap input,
    textarea {
      color: white;
    }

    .newsletter_error {
      font-family: $font-stack-general-1;
      font-size: 10.2px;
      font-weight: 300;
      line-height: 1.75;
      letter-spacing: 0.3px;
      text-align: left;
      color: #000;
    }

    .newsletter_success {
      text-align: center;
      margin-top: 40px;
    }

    .ajax-loader {
      display: block;
      margin: 10px auto;
    }

    div.wpcf7 {
      width: 100%;
    }

    form {
      color: white;
      font-family: $font-stack-general-1;
      margin: 10px 0 0;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-top: 30px;
        //display: flex;
        //justify-content: center;
      }

      p {
        display: inline-block;
        margin: 0;
        width: 100%;
        //padding: 0 10px;

        @media screen and (min-width: $mobile-breakpoint) {
          width: unset;
          padding: 0;
        }
      }

      p:first-of-type {
        margin: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          //margin: 0 10px 0 0;
        }
      }

      label {
        width: 100%;
        margin: 0;
        padding: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          padding: 0 10px 0 0;
        }
      }

      input[type="email"] {
        outline: none;
        border: 1px solid white;
        background-color: transparent;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        min-height: 45px;
        font-weight: 300;
        width: 100%;
        max-width: unset;
        padding: 10px;
        //margin: 0 0 10px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 0;
          max-width: 250px;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: white;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: white;
          opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: white;
          opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }

        &:-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
      }

      input[type="submit"] {
        background-color: white;
        color: black;
        border: 1px solid white;
        padding: 10px 35px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        min-height: 46px;
        border-radius: 0;
        width: 100%;
        max-width: 175px;

        @media screen and (min-width: $mobile-breakpoint) {
          width: auto;
        }

        &:hover,
        &:active {
          color: white;
          background-color: black;
          border: 1px solid black;
        }
      }
    }

    .conditions {
      text-align: left;
      margin: -10px 0 10px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin: 10px 0 20px;
      }

      ///// CUSTOM CHECKBOX /////

      input[type=checkbox] {
        visibility: hidden;
      }

      .custom-checkbox {
        width: 12px;
        height: 12px;
        position: relative;
      }

      /* custom check */
      .custom-checkbox label {
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        background: #fff;
      }

      /* checked state */
      .custom-checkbox label:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 9px;
        height: 5px;
        background: transparent;
        top: 20%;
        left: 15%;
        border: 2px solid #000;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
      }

      /* checkbox hover */
      .custom-checkbox label:hover::after {
        opacity: 0.4;
      }

      /* checkbox state */
      .custom-checkbox input[type=checkbox]:checked + label:after {
        visibility: initial;
        opacity: 1;
      }

      .disclaimer {
        padding-top: 0;
        text-align: left;
        font-size: 12px;
        font-weight: 300;
        color: #fff;
        width: 100%;
        margin-left: 8px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    &.success {
      h3 {
        max-width: 450px;
        margin-bottom: 50px;
      }
    }
  }

  .newsletter_share-title {
    font-family: $font-stack-general-1;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px !important;
    text-align: center;
    margin-bottom: 15px !important;
  }

  .newsletter_share-social {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 35px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #000;
      margin: 0 15px;

      img {
        height: 25px;
        width: auto;
      }
    }
  }
}

///// MÓDULO VERTICAL HOMEPAGE /////

.sv-newsletter.vertical {
  display: flex;
  justify-content: center;
  margin: 40px 10px 20px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 0 0 45px;
  }

  .box-color {
    height: 100%;
    width: 710px;
    background-color: #e84d75;
  }

  .newsletter-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: white;

    .description {
      max-width: 50%;

      @media screen and (max-width: $mobile-breakpoint) {
        display: none;
      }
    }
  }

  &.sv-newsletter-vertical--card {
    width: 360px;
    max-width: 100%;
  }

  &[data-newsletter-side-fixed] {
    position: absolute;
    z-index: 999;
    width: 325px;
    padding-bottom: 30px;
    background-color: transparent;
  }
}

///// MÓDULO HORIZONTAL HOMEPAGE /////

.sv-newsletter.horizontal {
  display: flex;
  justify-content: center;
  margin: 40px 10px 20px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 0 0 45px;
  }

  .noise-texture {
    padding: 35px 0 0;

    @media screen and (min-width: $mobile-breakpoint) {
      padding: 0;
    }
  }

  .box-color {
    height: 100%;
    width: 100%;
    background-color: $main-color;

    @media screen and (min-width: $mobile-breakpoint) {
      width: 925px;
    }
  }

  .newsletter-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: white;
    padding: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      flex-direction: row;
      padding: 35px;
    }

    .wpcf7-response-output {
      margin-top: 0;
    }

    @media screen and (min-width: $mobile-breakpoint) {
      .mono-logo {
        margin: 0;
      }

      h3 {
        font-size: 21px;
        line-height: 27px;
        margin: 0;
        max-width: 300px;
      }

      div.wpcf7 {
        width: auto;
      }

      form {
        margin: 0;

        input[type="submit"] {
          padding: 10px;
        }
      }

      .conditions {
        margin: 10px 0 0;
      }

      .wpcf7-response-output {
        margin-top: 10px;
      }

      .newsletter_share {
        width: 50%;

        p {
          max-width: none;
        }

        .newsletter_share-social {
          margin: 0;
        }
      }
    }
  }
}

///// MÓDULO ARTIGO /////

.sidebar {
  .sv-newsletter.vertical {
    .noise-texture {
      padding: 35px 0 0;
    }

    h3 {
      margin: 0 15px;
    }

    .description {
      display: none;
    }

    form {
      label {
        padding: 0;
      }

      p {
        width: 100%;
        padding: 0 10px;
      }

      input[type="email"] {
        margin: 0 0 10px;
        width: 100%;
        max-width: unset;
      }

      input[type="submit"] {
        width: 100%;
      }

      .ajax-loader {
        margin-bottom: 10px auto;
      }
    }

    .form-container {
      display: initial;
    }

    .first-block,
    .second-block {
      display: block;
      margin: 0 16px;
    }

    .conditions {
      margin: -20px 0 10px;

      .disclaimer {
        margin: 0;
      }
    }

    .newsletter-content.success {
      h3 {
        margin-bottom: 50px;
      }

      .newsletter_share-social {
        margin: 0 0 35px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 0;
        }
      }
    }
  }
}

///// MÓDULO LAST ARTICLES NUM ARTIGO /////

.newsletter__intra-posts-container {
  form {
    p {
      padding: 0 10px;
    }
  }
}

///// MÓDULO FOOTER /////

.sv-newsletter.foot {
  input {
    text-transform: uppercase;
  }

  .wpcf7-response-output,
  .wpcf7-acceptance-missing,
  .wpcf7-validation-errors {
    margin: 0;
    color: #fff !important;
    border: none;
    font-size: 12px !important;
    background-color: #000;
    padding: 8px !important;
    text-align: center;
  }

  .wpcf7-form-control-wrap input,
  textarea {
    color: white;
  }

  label {
    width: 100%;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .ajax-loader {
    margin: 10px 0;
  }

  input[type=email] {
    margin: 0;
  }

  .form-container {
    display: initial;
  }

  .first-block,
  .second-block {
    width: 100%;
  }

  .conditions {
    text-align: left;
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    input[type=checkbox] {
      margin: 0;
    }

    .disclaimer {
      padding-top: 0;
      text-align: left;
      font-size: 12px;
      font-weight: 300;
      color: #fff;
      width: 100%;
      margin-left: 8px;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  ///// CUSTOM CHECKBOX /////

  input[type=checkbox] {
    visibility: hidden;
  }

  .custom-checkbox {
    width: 12px;
    height: 12px;
    position: relative;
  }

  /* custom check */
  .custom-checkbox label {
    cursor: pointer;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    background: lightgrey;
  }

  /* checked state */
  .custom-checkbox label:after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 20%;
    left: 15%;
    border: 2px solid #000;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  /* checkbox hover */
  .custom-checkbox label:hover::after {
    opacity: 0.4;
  }

  /* checkbox state */
  .custom-checkbox input[type=checkbox]:checked + label:after {
    visibility: initial;
    opacity: 1;
  }
}

/* mobile */
.newsletter-footer-mobile,
.newsletter-article-mobile {
  padding: 20px 10px;
  background: #f0f0f0;
  height: 100%;

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }
}
