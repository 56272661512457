.mobile-menu-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 5px;

  @media (min-width: $mobile-breakpoint) {
    justify-content: center;
    margin: 0;
  }
}

.mobile-menu {
  height: 100%;
  width: 250px;
  text-align: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -250px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s ease;

  @media screen and (min-width: $mobile-breakpoint) {
    display: none !important;
  }

  .mobile-menu__title {
    font-family: $font-stack-general-1;
    font-weight: normal;
    background: #8c8c8c;
    color: white;
    margin: 0;
    padding: 20px;
  }

  .mobile-menu__categorias {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;

    .mobile-menu__link {
      padding: 20px 0;
      font-family: $font-stack-general-1;
      font-weight: normal;
      color: #000;

      &.empowering-women {
        img {
          height: 27px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      &.openmag {
        img {
          height: 30px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      &.mobile-menu__link--bottom-border {
        border-bottom: 1px solid #a1a1a1;
      }
    }
  }

  .mobile-menu__social {
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;

    .mobile-menu__link {
      padding-right: 20px;

      img {
        width: 20px;
      }
    }
  }
}

.menu-icons {
  position: relative;
  //left: -5px;
  //padding: 12px;
  @media screen and (min-width: $mobile-breakpoint) {
    display: none !important;
  }
}

.openMenu {
  display: none;
  //display: inline-block;

  &.hide-open {
    display: none;
  }

  .menu-icons__open {
    width: 26px;
  }
}

.closeMenu {
  display: none;

  &.show-close {
    display: inline-block;
  }

  .menu-icons__close {
    width: 26px;
  }
}

.navbar {
  margin-bottom: 0;
}

.mobile-menu__logo {
  text-align: center;
  margin: 0 auto;

  @media (min-width: $mobile-breakpoint) {
    padding: 12px 0 !important;
  }

  img {
    width: 150px;

    @media (min-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
}
