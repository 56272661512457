.modal-newsletter {
  display: none;

  > .alpha {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;

    > .sv-newsletter.vertical {
      max-width: 600px;
      width: 100%;

      h3 {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}