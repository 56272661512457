.aside-wrapper {
  .editors-pick {
    padding-top: 20px;

    .container-fluid {
      margin-bottom: 0;
    }
  }

  .editors-pick,
  .last-articles {
    > .container-fluid {
      padding: 0 10px;

      > .row > .col-xs-12 {
        padding: 0;

        .mobile-post-card {
          padding: 0;
        }
      }
    }
  }
}

.article-related,
.openmag-articles,
.editors-pick,
.last-articles {
  //margin-left: -15px;
  //margin-right: -15px;
  //margin-right: 0;
  padding: 0;
  background: #f0f0f0;

  @media (min-width: $mobile-breakpoint) {
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
    background: transparent;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  > div > .container {
    padding: 0;
  }

  .element {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 10px;

    > img {
      width: 100%;
    }
  }

  .row {
    margin: 0;
  }

  hr {
    text-align: center;
    margin: 0 auto;
    border-top: 5px solid $main-color;
    width: 35px;
    //border-bottom: 3px solid $main-color;
    @media (min-width: $mobile-breakpoint) {
      width: 50px;
    }
  }

  h2 {
    color: black;
    margin-top: 0;

    &.description {
      text-align: center;
      padding: 0 35px;
      font-size: 14px;
      line-height: 31px;
      margin-bottom: 30px;
      font-weight: 300;
      font-family: $font-stack-general-1;

      @media (min-width: $mobile-breakpoint) {
        font-size: 14px;
      }
    }

    &.related-title {
      font-size: 16px;
      line-height: 18px;
    }
  }

  a {
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
    }
  }

  img:hover {
    opacity: 0.75;
  }

  @media (max-width: $mobile-breakpoint) {
    .description {
      text-transform: uppercase;
      font-family: $font-stack-general-1;
      letter-spacing: 1px;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
