.customized-header{
	pointer-events: none;

	.image{
		min-width: 100%;
		max-width: 100%;
	}

	.desktop--only {
		@media all and (max-width: $breakpoint-xs) {
			display: none !important;
		}
	}

	.mobile--only {
		@media all and (min-width: $breakpoint-xs) {
			display: none !important;
		}
	}
}
