.single-recipe-group {
  .title {
    text-align: center;
    margin-bottom: 45px;

    hr {
      border-top: 3px solid $main-color;
      margin-bottom: 10px;
      margin-top: 26px;
      width: 10%;
    }

    h4 {
      text-transform: uppercase;
      font-family: $font-stack-general-1;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }

  a {
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
    }
  }

  .articles {
    .element {
      box-shadow: 5px 5px 20px #f2f2f2;
      text-align: center;
      min-height: 460px;

      h2 {
        margin-top: 0;
      }

      .category {
        margin: 30px 0;
      }

      .read-article {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        color: #7f7f7f;
        text-transform: uppercase;
        letter-spacing: 1px;

        hr {
          border-top: 2px solid $main-color;
          margin-bottom: 10px;
          margin-top: 26px;
          width: 10%;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .mobile-social-media {
      .share-icons {
        bottom: -70px;
        right: 20px;
      }

      .mobile-social {
        bottom: -20px;
        right: 20px;
      }
    }
  }
}
