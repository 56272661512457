body {
  position: relative;
  top: 0;
  left: 0;
  overflow: auto;
  transition: left 0.5s ease;
  padding-top: 60px;

  &.mobile-menu-opened {
    left: 250px;
    overflow: hidden;
  }

  &.cookies-message--hidden {
    padding-bottom: 0 !important;
  }
}

.main-content {
  background-color: #f0f0f0;
  padding-top: 1px;

  @media (min-width: $mobile-breakpoint) {
    padding: 40px 0 0;
  }

  > .container {
    margin-bottom: 0;
  }
}

.single {
  .main-content {
    background: #fff;
  }
}

.main-content-article {
  background-color: $color-background;
  padding: 40px 0 10px;
}

.article-separator {
  height: 1rem;
}

.category {
  /*
  font-size: 0.625rem;
  margin: 10px 0;
  */
}

.element .category {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $font-stack-general-1;
}

.editor-title {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin: 30px 0;
  font-family: $font-stack-general-1;
  font-size: 0.625rem;
}

.text-with-separator {
  hr {
    border-top: 5px solid $main-color;
    margin: 0 auto;
    width: 4%;

    @media (min-width: $mobile-breakpoint) {
      width: 50px;
    }
  }

  p {
    padding: 15px 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $font-stack-general-1;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
  }

  .description {
    font-family: $font-stack-general-1;
    letter-spacing: 1px;
    margin: 0 auto 30px;
    font-weight: 300;
    text-align: center;
    font-size: 0.875rem;
    max-width: 90%;
    text-transform: initial;

    @media (max-width: $mobile-breakpoint) {
      margin: 0 auto 10px;
    }
  }
}

.category {
  /*
  text-transform: uppercase;
  */
  letter-spacing: 1px;
  font-family: $font-stack-general-1;
}

.loader {
  display: none;
  width: 50px;
  height: 50px;
  margin: 0 auto;

  &.active {
    display: block;
  }
}

.general-separator-line {
  width: 17px;
  height: 1px;
  background-color: $main-color;
  margin: 12px auto;
}

.mobile-social-media {
  display: none;
}

.no-margin {
  margin-bottom: 0;
}

.no-paddings {
  padding: 0;
}

.suppress-container-side-pads {
  padding-left: 0;
  padding-right: 0;
}

.extra-margin {
  margin-top: 70px;
}

@media (max-width: $mobile-breakpoint) {
  .mobile-social-media {
    position: absolute;
    display: block;
    right: 0;
    bottom: -30px;
    margin: 10px;
    z-index: 998;

    .share-icons {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;

      a {
        figure {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          img {
            width: 20px !important;
          }
        }

        .share-icons__last {
          margin-bottom: 0;
        }
      }
    }

    figure,
    .mobile-social {
      background-color: white;
      border-radius: 50%;
      width: 40px !important;
      height: 40px;
      border: 1px solid black;
    }

    .mobile-social {
      position: absolute;
      display: block;
      right: 0;
      bottom: 0;
      border: 1px solid red;
      padding: 10px;
      max-width: none;
    }
  }

  article {
    .mobile-social-media {
      .share-icons {
        //bottom: -50px;
      }

      .mobile-social {
        //bottom: -10px;
      }
    }
  }
}
