.sv05-author-latest {
  padding-bottom: 30px;

  @media (min-width: $mobile-breakpoint) {
    padding-bottom: 0;
  }

  > .container {
    margin-bottom: 0;
  }

  .element {
    margin-bottom: 30px;
    min-height: 245px;

    a {
      color: black;

      &:hover {
        color: black;
        text-decoration: none;
      }
    }

    h4 {
      min-height: 50px;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    &:hover {
      img {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .element {
      min-height: 200px;
    }
  }
}
