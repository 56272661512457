@import "../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";

.article-gallery {
  position: relative;
  padding: 0 0 40px;

  @media (min-width: $breakpoint-sm) {
    padding: 0;
  }

  .glide__slides {
    @media (min-width: $breakpoint-sm) {
      align-items: center;
    }

    .glide__slide {
      display: flex;
      justify-content: center;

      &.google-ad {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
      }

      i {
        font-style: italic;
      }
    }
  }

  .article-gallery__slide__page {
    font-family: $font-montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 213.28%;
    position: absolute;
    bottom: 10px;
    //right: 20px;
    text-align: left;

    @media (min-width: $breakpoint-sm) {
      bottom: 100px;
      left: 50%;
      margin-left: 60px;
    }
  }

  .article-gallery__arrows {
    position: absolute;
    width: 100%;
    left: 0;
    top: 210px;
    transition: top 0.6s;

    /*
    display: none;
    @media (min-width: $breakpoint-sm) {
      display: block;
    }
     */

    @media (min-width: $breakpoint-sm) {
      top: 50%;
      margin-top: -25px;
    }

    .article-gallery__arrow {
      background: transparent;
      position: absolute;
      border: 2px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 10px;
      cursor: pointer;

      @media (min-width: $breakpoint-sm) {
        background: #fff;
        padding: 14px 17px;

        &:hover {
          background: #eee;
        }
      }

      &:last-child {
        right: 20px;
      }

      &:first-child {
        left: 20px;
        transform: rotate(-180deg);
      }
    }
  }

  .article-gallery__slide {
    display: flex;

    &.article-gallery__slide--instagram,
    &.article-gallery__slide--video {
      .article-gallery__slide__wrapper {
        @media (min-width: $breakpoint-sm) {
          padding: 60px 90px;
          flex-direction: column;
          align-items: center;
        }
      }

      .image {
        min-height: auto;
        flex: none;
      }

      .contents-mobile {
        h3 {
          text-align: center;
        }
      }

      .contents {
        flex: 1;
        align-items: flex-start;
        width: 100%;

        @media (min-width: $breakpoint-sm) {
          margin-top: 45px;
        }

        .description {
          margin: 0 0 10px;

          @media (min-width: $breakpoint-sm) {
            margin: 10px 0;
          }
        }
      }

      .article-gallery__slide__page {
        position: relative;
        left: auto;
        bottom: auto;

        @media (min-width: $breakpoint-sm) {
          margin-left: 0;
        }
      }
    }

    &.article-gallery__slide--video {
      .contents {
        margin-top: 45px;

        @media (min-width: $breakpoint-sm) {
          margin-top: 30px;
          padding: 0;
        }

        h3 {
          display: none;
        }
      }
    }

    &.article-gallery__slide--instagram {
      .contents {
        @media (min-width: $breakpoint-sm) {
          align-items: center;
        }
      }
    }

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .article-gallery__slide__page {
      position: absolute;
      left: 0;
      bottom: 0;

      @media (min-width: $breakpoint-sm) {
        //left: 60px;
      }
    }

    .article-gallery__slide__wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      min-height: 600px;

      &.standard-image-background {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        background-position: center;
      }

      @media (min-width: $breakpoint-sm) {
        padding: 60px 90px;
        flex-direction: row;
      }
    }

    .image {
      position: relative;
      flex: none;
      min-height: auto;
      display: flex;
      align-items: center;
      margin: 0 -20px;

      @media (min-width: $breakpoint-sm) {
        flex: 1 1 50%;
        min-height: 570px;
        margin: 0;
      }

      img {
        width: auto !important;
        display: inline-block !important;
        max-width: 100%;
      }

      iframe {
        max-width: 100%;
        min-height: 210px;
        height: auto;

        @media (min-width: $breakpoint-sm) {
          min-height: 480px;
        }
      }

      .image-attribution {
        position: absolute;
        bottom: -21px;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    h3 {
      font-family: $font-playfair;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;

      @media (min-width: $breakpoint-sm) {
        font-family: $font-montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        margin: 0 0 15px;
      }
    }

    .contents-mobile {
      text-align: left;

      @media (min-width: $breakpoint-sm) {
        display: none;
      }

      h3 {
        line-height: 1.2;
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    .top-title {
      display: none;

      @media (min-width: $breakpoint-sm) {
        display: block;
        margin: 0 0 40px;

        h3 {
          font-family: $font-playfair;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;

          @media (min-width: $breakpoint-sm) {
            font-size: 36px;
            line-height: 1;
          }
        }
      }
    }

    .contents {
      position: relative;
      flex: 1 1 50%;
      font-family: $font-stack;
      font-weight: 300;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      padding: 20px 0;

      @media (min-width: $breakpoint-sm) {
        padding: 0 60px;
      }

      h3 {
        display: none;

        @media (min-width: $breakpoint-sm) {
          display: block;
        }
      }

      p {
        margin: 15px 0;

        @media (min-width: $breakpoint-sm) {
          margin: 0;
        }

        &.nichandle {
          font-family: $font-montserrat;
          font-weight: 700;
          font-size: 16px;
          line-height: 1.4;
          margin: 0;

          @media (min-width: $breakpoint-sm) {
            font-size: 24px;
          }
        }

        &.brand {
          font-family: $font-montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 2px;
          border-bottom: 4px solid transparent;

          @media (min-width: $breakpoint-sm) {
            font-size: 16px;
            line-height: 25px;
          }

          &.brand--linked {
            &:hover {
              border-bottom: 4px solid transparent;
            }
          }

          a {
            &:hover {
              text-decoration: none;
            }
          }
        }

        &.price {
          font-family: $font-montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 31px;
          margin: 0 0 15px;

          @media (min-width: $breakpoint-sm) {
            font-size: 16px;
            line-height: 31px;
          }
        }
      }

      .description {
        font-family: $font-montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        margin: 15px 0;

        @media (min-width: $breakpoint-sm) {
          line-height: 31px;
        }

        .ad {
          display: none !important;
        }

        p {
          a {
            margin-bottom: 2px;
            border-bottom: 2px solid #ff5859;
            text-decoration: none;
          }
        }
      }

      a.btn {
        background: #ff5859;
        color: #fff;
        font-family: $font-montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        text-decoration: none;
        padding: 6px 14px;
        margin: 15px 0;
        min-width: 166px;

        &:hover {
          background: #fff;
          color: #ff5859;
        }
      }
    }
  }
}
