.cta-sv:hover {
  text-decoration: none;
}

.cta-sv-wrapper {
  font-family: $font-stack-general-1;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1rem;
  padding: 14px 25px;
  height: 45px;
  margin: 0 0 20px;
  display: inline-block;

  @media screen and (min-width: $mobile-breakpoint) {
    font-size: 14px;
    padding: 12px 25px;
    margin: 0;
  }

  .enter-icon {
    width: 13px;
    margin-left: 10px;
    padding-bottom: 3.5px;

    &:hover {
      opacity: 1;
    }
  }
}

.cta-sv-main {
  background-color: $main-color;
  border: 1px solid $main-color;
  border-radius: 0;
  color: white;

  a {
    color: white;
  }

  &:hover {
    background-color: black;
    border: 1px solid black;
    text-decoration: none;
  }
}

.cta-sv-white {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  color: $main-color;

  &:hover {
    background-color: #000;
    border: 1px solid #000;
    text-decoration: none;
    color: #fff;
  }
}

.lp-newsletter-content {
  .cta-sv-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 260px;
  }
}
