.cpt006-mob {
  padding: 0 10px !important;
  margin-bottom: 0;

  .cpt006-mob__inner {
    //padding: 0 10px;
  }

  .sv-newsletter {
    form input[type="email"] {
      text-align: center;
    }
  }

  .cpt006-mob__title {
    margin: 30px 0 80px;
  }
}
