.wq_IsPersonality .row,
.wq_IsTrivia .row {
  display: flex;
  margin: 0 -6px 10px;

  .col-md-wq-6 {
    width: auto;
    padding: 0 6px;

    .wq_singleAnswerCtr {
      height: 100%;
    }
  }
}

.wq_quizCtr {
  margin: 20px 0;
}

.wq_questionTextCtr {
  h4 {
    font-size: 20px;
    line-height: 24px;
    text-transform: none;
    font-weight: bold;
  }
}

.wq_singleAnswerCtr {

  /*
  &:hover {
    background: #ffa1a2 !important;
    border: 3px solid #ffa1a2 !important;
  }
  */

  .wq_answerTxtCtr {
    font-size: 16px;
    font-weight: normal;
  }
}

