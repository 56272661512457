/**
General newsletter module present near the footer
 */
.om-newsletter {
  min-height: 540px;
  padding: 10px 20px;
  background-color: $color-blue;
  color: $color-black;
  text-transform: none;
  text-align: center;

  @media screen and (min-width: $mobile-breakpoint) {
    min-height: 340px;
  }

  h3 {
    margin: 30px 0;
    height: 100%;
    font-family: $font-stack-general;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
  }

  label {
    margin-right: 4px;
  }

  .wpcf7-form {
    .wpcf7-form-control-wrap {
      input {
        color: black;
      }
    }
  }

  .wpcf7-mail-sent-ok {
    text-align: center;
    margin-top: 0;
    border: 0;
  }

  .wpcf7-not-valid-tip {
    display: none;
  }

  .wpcf7-response-output,
  .wpcf7-acceptance-missing {
    margin: 0;
    color: $color-black;
    border: none;
    font-size: 14px;
  }

  .ajax-loader {
    display: none !important;

    @media screen and (min-width: $mobile-breakpoint) {
      display: initial;
    }
  }

  .newsletter_error {
    font-family: Montserrat;
    font-size: 10.2px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.3px;
    text-align: left;
    color: #000;
  }

  .newsletter_success {
    text-align: center;
    margin-top: 40px;
  }

  .elipse {
    width: 89px;
    height: 89px;
    background-color: #fff;
    border-radius: 50px / 50px;
    margin-left: auto;
    margin-right: auto;
  }

  &.foot {
    padding: 10px 0;

    .elipse {
      background-color: #000;
    }

    .newsletter_success {
      margin-top: 0;
    }

    .wpcf7-form {
      p,
      label {
        display: initial;
      }
    }
  }

  .highlight {
    display: inline-block;
    height: 65px;
    background-repeat: no-repeat;
    color: black;
    padding-top: 16px;
    width: 175px;
  }

  .message {
    color: $color-black;
    font-family: $font-stack-general-3;
    font-size: 36px;
    margin: 0 0 40px;
  }

  .disclaimer {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    font-size: 13px;
    line-height: 1rem;

    @media screen and (min-width: $mobile-breakpoint) {
      width: 35%;
      margin: 0 auto;
    }
  }

  form {
    color: $color-black;
    font-family: $font-stack-general-1;

    p {
      display: inline-block;
      width: 100%;
    }

    input[type="email"] {
      outline: none;
      border: 1px solid black;
      background-color: $color-blue;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
      min-height: 45px;
      max-width: 425px;
      font-weight: 300;
      width: 100%;
      padding: 10px;

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: black;
      }

      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: black;
        opacity: 1;
      }

      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: black;
        opacity: 1;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: black;
      }

      &:-ms-input-placeholder { /* Microsoft Edge */
        color: black;
      }
    }

    input[type="submit"] {
      background-color: white;
      color: $color-black;
      border: 1px solid white;
      padding: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
      min-height: 45px;
      min-width: 170px;
      border-radius: 0;

      &:hover,
      &:active {
        color: white;
        background-color: black;
        border: 1px solid black;
      }
    }
  }
}

/* Newsletter module present in the articles sidebar */
.sidebar {
  .om-newsletter {
    h3 {
      font-size: 24px;
      height: 95px;
      margin-bottom: 0;
      line-height: 28px;
    }

    .message {
      margin-top: 10px;
    }

    form {
      input {
        display: block;

        &[type="email"] {
          width: 100%;
          margin: 0;
          text-align: center;
        }

        &[type="submit"] {
          width: 100%;
          margin: 0;
        }
      }

      p {
        width: 100%;
      }
    }

    .highlight {
      background-position-y: 0;
      width: 65%;
      line-height: 12px;
    }

    .disclaimer {
      width: 100%;
      margin: 0 0 25px;
      padding: 0;
      font-size: 10px;
      line-height: 13px;
    }

    .message {
      /* margin: 75px 0 40px 0; */
      font-size: 26px;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-response-output,
    .wpcf7-acceptance-missing {
      color: black;
      border: none;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
