.newsletter-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: max-height 0.4s;
  max-height: 50px;
  z-index: 10000;

  @media (min-width: $mobile-breakpoint) {
    display: none;
  }

  &.newsletter-bar--open {
    max-height: 450px;

    > a {
      opacity: 0;
    }

    .sv-newsletter-vertical__contents {
      opacity: 1;
    }
  }

  .sv-newsletter-vertical__contents {
    opacity: 0;
    transition: opacity 0.4s;
  }

  a {
    /*
    background-color: #cc5191;
    background-image: url("../images/noise-texture.png");
    background-repeat: repeat;
    background-size: 30%;
    */
    width: 100vw;
    z-index: 1;
    padding: 12px 20px 15px;
    display: block;
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: $mobile-breakpoint) {
      display: none;
    }

    .bar-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
        height: 17px;
        margin-right: 15px;
      }

      span {
        font-family: $font-stack-general-1;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.15rem;
        color: white;
        padding-top: 1px;
      }
    }
  }
}
