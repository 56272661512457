.sv-02-rounded-image {
  p {
    text-align: center;
  }

  .center {
    &:first-child {
      margin-left: 25%;
    }

    &:last-child {
      margin-right: 25%;
    }
  }

  img {
    max-width: 100%;
    border-radius: 50%;
    height: 100%;
  }

  .separator-row {
    width: 50%;
    height: 5px;
    margin: 0 auto;
    margin-top: 23px;
    margin-bottom: 23px;
    background-color: $main-color;
  }

  .content {
    p {
      font-family: $font-stack-general;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.375rem;
    }
  }
}
