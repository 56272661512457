.sv06-latest-articles {
  .title-last {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
  }

  .element {
    border-bottom: 1px solid #d7d7d7;
    margin: 20px 0;
    padding: 20px 0;

    &:hover {
      img {
        opacity: 0.5;
      }
    }
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: #878787;
    }
  }

  p {
    margin: 0;
    font-size: 10px;
  }

  hr {
    border-top: 2px solid $main-color;
    width: 17px;
    margin: 0;
  }

  h4 {
    margin-top: 0;
    font-size: 13px;
  }

  h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;

    &.description {
      text-transform: none;
      letter-spacing: 0;
    }
  }

  img:hover {
    opacity: 0.75;
  }

  .category {
    margin: 0 0 5px;
    font-size: 10px;
  }

  .description {
    margin-bottom: 20px;
  }

  .read-article {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #7f7f7f;
    font-size: 10px;

    &:hover {
      color: black;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    //margin-top: 30px;
    background-color: #f5f8fb;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .element {
      border-bottom: unset;
      //margin: 20px 0;
      padding: 0;
      display: flex;
      align-items: center;

      h5 {
        display: none;
      }

      .post-thumbnail,
      .content {
        padding: 0 10px;
      }

      .content {
        padding-left: 14px;
      }
    }

    .container-header {
      padding-top: 32px;

      hr {
        width: 37px;
        border-top: 4px solid $main-color;
        margin: 0 auto;
      }

      h5 {
        text-align: center;
      }
    }

    .btn-subscribe {
      margin: 25px auto;
    }
  }
}
