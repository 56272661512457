body.cookies-message--hidden .cookies {
  display: none;
}

.cookies {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 15px;
  background-color: $main-color;
  opacity: 0.9;
  z-index: 1000;
  position: fixed;
  bottom: 0;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 30px;
  }

  p {
    color: #fff;
    font-family: $font-stack-general-1;
    font-weight: 300;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    max-width: unset;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      font-size: 12px;
      line-height: 20px;
      max-width: 1000px;
    }

    a {
      text-decoration: underline;
      color: #fff;

      &:hover {
        color: #000;
      }
    }
  }

  .cta-sv-wrapper {
    margin: 0;
    font-size: 10px;
    height: 100%;
  }

  .cta-sv-white {
    min-width: 150px;
    text-align: center;
  }
}