@media (max-width: $mobile-breakpoint - 1) {
  .js-articles-container {
    > .container {
      margin-bottom: 0;
    }
  }

  .category-header {
    .page-title {
      margin: 0;
      max-width: 100%;
    }
  }

  .sv-03-subcategory-latest {
    &.container-fluid {
      background-color: #f5f8fb;
      padding: 67px 0 0;
      margin: 0;
    }

    .no-padding {
      padding: 0;
    }

    .content {
      padding-left: 20px;
      vertical-align: top;

      h6 {
        padding-top: 0;
        margin-top: 0;
        font-size: 10.5px;

        a {
          font-family: Montserrat;
          font-size: 10.5px;
          font-weight: 300;
          line-height: 1.8;
          letter-spacing: 1.3px;
          text-align: left;
          color: #000;
        }
      }

      h4 {
        padding-top: 0;
        margin-top: 0;
        max-height: 80px;
        overflow: hidden;
        font-size: 18px;
        text-transform: initial;

        a {
          font-family: PlayfairDisplay;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.11;
          text-align: left;
          color: #000;
        }
      }
    }

    .block {
      height: 113px;
      margin-bottom: 50px;
    }

    .area-title {
      margin: 0 auto;

      hr {
        width: 50px;
        margin: 0 auto;
        border-top: 5px solid #ff5859;
      }

      h2 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 2.21;
        letter-spacing: 0;
        text-align: center;
        color: #000;
        border: 0;
        padding: 0;
        margin: 10px 0 50px;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      .block {
        height: 200px;
        margin-bottom: 50px;
      }

      .content {
        padding-left: 0;
      }
    }
  }

  .sv-03-category-latest {
    .sv-08-latest {
      margin-top: 0;
    }

    a {
      color: black;

      &:hover {
        text-decoration: none;
      }
    }

    .img-responsive {
      width: 100%;
    }

    h2 {
      letter-spacing: 0;
    }

    .one-article {
      h2 {
        text-transform: initial;
        letter-spacing: 0;
      }

      .col-md-9 {
        max-height: 500px;
        //margin-bottom: 30px;
      }

      .read-article {
        bottom: -25px;
      }

      .social {
        bottom: -50px;
        padding: 0 5px;
        right: 5px;

        figure {
          padding: 5px 0;
        }
      }
    }

    .content {
      text-align: center;
      bottom: 195px;
      left: -165px;
      font-size: 0.625rem;
      height: 226px;
      width: 368px;
      //box-shadow: 5px 5px 20px #f2f2f2;
      background-color: white;
      padding: 20px;

      h2 {
        font-weight: 600;
      }
    }

    .social {
      position: relative;
      right: 50px;
      padding-left: 0;
    }

    .two-articles {
      max-height: 310px;
      margin-top: 30px;
      margin-bottom: 30px;

      h2 {
        text-transform: initial;
        letter-spacing: 0;
      }

      .content {
        text-align: center;
        bottom: 130px;
        left: -100px;
        font-size: 0.625rem;
        height: 200px;
        width: 347px;
        box-shadow: 5px 5px 20px #f2f2f2;
        background-color: #fff;
        padding: 20px;

        .read-article {
          padding-top: 16px;
          margin-top: 10px;
          bottom: 20px;
        }
      }

      .social {
        bottom: -30px;
        padding: 0 5px;
        right: 0;

        figure {
          padding: 5px 0;
        }
      }

      .read-article {
        margin: 0;
        padding: 10px;
      }
    }

    .three-articles {
      .post-image-thumbnail {
        min-height: 224px;
      }

      h2 {
        text-transform: initial;
        letter-spacing: 0;
        min-height: 84px;
        max-height: 84px;
        overflow: hidden;
      }

      .col-md-4 {
        margin-bottom: 30px;
        height: 452px;

        .element {
          padding: 0 10px;
          text-align: center;
          //box-shadow: 5px 5px 20px #f2f2f2;
          .inside {
            padding: 20px;
            height: 223px;
            background-color: white;
          }

          &.native-article {
            //box-shadow: -5px -5px 0 $main-color;
            a {
              color: white;
            }

            .inside {
              background-color: black;
              color: white;
            }
          }
        }
      }
    }

    .latest-four-container {
      //margin-top: 30px;
      padding: 20px;
      background-color: $color-background-option;
    }

    .four-articles {
      background-color: $color-background-option;
      max-height: 270px;
      margin-bottom: 50px;
      padding: 34px 0;
      text-align: left;

      .contentarea {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        height: 60px;
        background-repeat: no-repeat;
        text-align: center;
        margin: 0 auto;
        text-transform: none;
        background-position: top center;
        width: 145px;
        letter-spacing: 0;
      }

      h4 {
        margin: 0;
      }

      h6 {
        color: black;
      }

      .category {
        margin: 16px 0;
      }

      .element {
        width: 15.25%;
      }
    }

    .native-article {
      margin-top: 0;
      /*
      img {
        &.img-responsive {
          box-shadow: -5px -4px 0 $main-color;
        }
      }

      .sponsored {
        font-size: 0.625rem;
        text-transform: uppercase;
        position: absolute;
        //left: 40;
        //top: 10;
        z-index: 99;
        background-color: rgba(230, 228, 226, 0.5);
        padding: 5px;
      }
      */

      &.four-articles {
        .sponsored {
          left: 143px;
          top: 100px;
          padding: 0;
        }
      }

      .content {
        background-color: black;

        h2,
        .category {
          a {
            color: white;
          }
        }
      }
    }

    .video-thumbnail {
      position: absolute;
      opacity: 1;
      width: auto !important;
      height: 50px;
      top: 41%;
      left: 43.5%;
      z-index: 1;

      @media screen and (min-width: 1025px) {
        top: 30%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 30%;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        top: 35%;
      }
    }

    hr {
      border-top: 1px solid $main-color;
      margin: 0 auto;
      width: 17px;
    }

    .read-article {
      position: relative;
      width: 100%;
      text-align: center;
      bottom: 0;
      text-transform: uppercase;
      font-size: 0.625rem;
      padding-top: 16px;
      letter-spacing: 1px;
      color: #7f7f7f;
      margin-bottom: 0;
      padding-bottom: 10px;

      hr {
        margin-bottom: 10px;
      }

      a {
        color: #7f7f7f;
      }
    }

    h4 {
      text-transform: initial;
      letter-spacing: 0;
      margin-bottom: 20px;
    }

    .two-articles .col-md-6,
    .one-article .col-md-8,
    .three-articles .col-md-4,
    .four-articles .element {
      &:hover {
        .post-image-thumbnail {
          opacity: 0.5;
        }

        h2,
        h4 {
          a {
            color: #515151;
          }
        }

        .category {
          color: #515151;

          a {
            color: #515151;
          }
        }

        .read-article {
          color: #000;

          a {
            color: #000;
          }
        }
      }
    }

    @media (max-width: $mobile-breakpoint) {
      .social {
        display: none;
      }

      .mobile-social-media {
        z-index: 9;
      }

      .element {
        //padding: 0 10px;
        padding: 0;
      }

      .two-articles {
        max-height: unset;

        .element {
          .col-md-11 {
            padding: 0;
          }

          .content {
            position: relative;
            left: 0;
            top: 0;
            height: auto;
            width: 100%;
          }
        }
      }

      .four-articles {
        max-height: 100%;

        .category-main-title {
          background-position: center;
          line-height: 2rem;
          padding-top: 12px;
        }

        .element {
          width: 50%;
          padding: 10px;
          min-height: 220px;

          .category {
            margin: 15px 0;
            //padding: 15px 0;
          }
        }
      }

      .three-articles {
        .col-md-4 {
          height: auto;
          padding: 0;
        }
      }

      .one-article {
        text-align: center;
      }
    }

    > .container {
      margin-bottom: 0;
    }
  }
}

.category-header {
  margin-bottom: 0;

  .page-title {
    max-width: 100%;
    margin-top: 30px;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 0;
    }

    hr {
      width: 50px;
      margin: 0 auto;
      border-top: 5px solid #ff5859;
    }

    h4 {
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 15px 0 20px;
      font-weight: 300;
      text-align: center;
      font-size: 14px;
    }

    .description {
      font-family: $font-stack-general-1;
      letter-spacing: 1px;
      margin: 0 auto 30px;
      font-weight: 300;
      text-align: center;
      font-size: 14px;
      max-width: 90%;
      text-transform: initial;

      @media (max-width: $mobile-breakpoint) {
        margin: 0 auto 10px;
      }
    }
  }
}

@media (min-width: $mobile-breakpoint) {
  .sv-03-subcategory-latest {

    // Comportamento hover

    .wp-post-image:hover {
      opacity: 0.75;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    h2 {
      margin: 0;
      max-height: 120px;
      overflow: hidden;
      //white-space:nowrap;
      //text-overflow: ellipsis;
    }

    h6 {
      margin-bottom: 0;

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #7f7f7f;
        font-size: 10px;

        &:hover {
          color: black;
        }
      }
    }

    .social-media {
      display: flex;
      flex-direction: row;
      justify-content: center;

      figure {
        margin: 0 12px;
      }

      img {
        //height: 15px;
      }
    }

    &.container-fluid {
      background-color: #f5f8fb;
      padding: 67px 0 0;
      margin: 0;
    }

    .no-padding {
      padding: 0;
    }

    .content {
      padding-left: 20px;
      vertical-align: top;

      h6 {
        padding: 0;
        margin-top: 0;
        font-size: 10.5px;

        a {
          font-family: Montserrat;
          font-size: 10.5px;
          font-weight: 300;
          line-height: 1.8;
          letter-spacing: 1.3px;
          text-align: left;
          color: #000;
        }
      }

      h4 {
        padding-top: 0;
        margin-top: 0;
        font-size: 18px;
        text-transform: initial;
        max-height: 80px;
        overflow: hidden;

        a {
          font-family: PlayfairDisplay;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.11;
          text-align: left;
          color: #000;
        }
      }
    }

    .block {
      height: 113px;
      margin-bottom: 50px;
    }

    .area-title {
      margin: 0 auto;

      hr {
        width: 50px;
        margin: 0 auto;
        border-top: 5px solid #ff5859;
      }

      h2 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 2.21;
        letter-spacing: 1.7px;
        text-align: center;
        color: #000;
        border: 0;
        padding: 0;
        margin: 10px 0 50px;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      .block {
        height: 200px;
        margin-bottom: 50px;
      }

      .content {
        padding-left: 0;
      }
    }
  }

  .sv-03-category-latest {
    padding-bottom: 40px;

    // Comportamento hover

    img {
      max-width: 100%;
      height: auto;

      &:hover {
        opacity: 0.75;
      }
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: #878787;
      }
    }

    h2 {
      margin: 0;
      //max-height: 120px;
      //overflow: hidden;

      //white-space:nowrap;
      //text-overflow: ellipsis;
    }

    h6 {
      margin-bottom: 0;

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #7f7f7f;
        font-size: 10px;

        &:hover {
          color: black;
        }
      }
    }

    .social-media {
      display: flex;
      flex-direction: row;
      justify-content: center;

      figure {
        margin: 0 12px;
      }

      img {
        //height: 15px;
      }
    }

    .js-articles-container {
      .container {
        margin-bottom: 0;
      }
    }

    .element {
      position: relative;
      margin: 0;
    }

    .img-responsive {
      width: 100%;
    }

    .read-article {
      position: relative;
      width: 100%;
      text-align: center;
      bottom: 0;
      text-transform: uppercase;
      font-size: 0.625rem;
      letter-spacing: 1px;
      color: #7f7f7f;
      margin: 0;
      padding: 0;

      hr {
        margin-bottom: 10px;
      }
    }

    .one-article {
      h2 {
        text-transform: initial;
        letter-spacing: 0;
      }

      .col-md-9 {
        max-height: 500px;
        //margin-bottom: 30px;
      }

      .read-article {
        bottom: -25px;
      }

      .social {
        bottom: -50px;
        padding: 0 5px;
        right: 5px;

        figure {
          padding: 5px 0;
        }
      }

      &.native-article {
        .img-responsive {
          //box-shadow: -5px -5px 0 $main-color;
        }
      }
    }

    .content {
      text-align: center;
      bottom: 195px;
      left: -165px;
      font-size: 0.625rem;
      height: 226px;
      width: 368px;
      //box-shadow: 5px 5px 20px #f2f2f2;
      background-color: white;
      padding: 20px;

      h2 {
        font-weight: 600;
      }
    }

    .social {
      position: relative;
      right: 50px;
      padding-left: 0;
    }

    .two-articles {
      max-height: 310px;
      margin: 30px 0 80px;

      h2 {
        text-transform: initial;
        letter-spacing: 0;
        //max-height: 64px;
        //overflow: hidden;

        //white-space:nowrap;
        //text-overflow: ellipsis;
      }

      .video-thumbnail {
        position: absolute;
        opacity: 1;
        width: auto !important;
        height: 50px;
        top: 41%;
        left: 46%;
        z-index: 1;

        @media screen and (min-width: 1025px) {
          top: 30%;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          top: 20%;
        }

        @media (min-width: 481px) and (max-width: 768px) {
          top: 35%;
        }
      }

      .content {
        position: absolute;
        text-align: center;
        bottom: -40px;
        left: auto;
        right: 15px;
        font-size: 0.625rem;
        height: 158px;
        width: 250px;
        //box-shadow: 5px 5px 20px #f2f2f2;
        background-color: #fff;
        padding: 20px 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h5 {
          margin-top: 0;
        }

        h2 {
          font-size: 16px;
          line-height: 21px;
          margin: 0;
        }

        .read-article {
          padding: 0;
        }

        &.native-article {
          //box-shadow: -5px -5px 0 $main-color;

          .inside {
            background-color: black;
            color: white;
          }
        }
      }

      .element {
        &.native-article {
          .post-image-thumbnail {
            //box-shadow: -5px -5px 0 $main-color;
          }

          .read-article {
            color: white;
            text-decoration: none;

            &:hover {
              color: #878787;
            }
          }
        }
      }

      .social-media {
        flex-direction: column;
      }

      .social {
        bottom: -20px;
        padding: 0 5px;
        right: 10px;
        width: 35px;
        text-align: center;

        @media (min-width: 1200px) {
          bottom: -40px;
        }

        figure {
          padding-bottom: 8px;

          @media (min-width: 1200px) {
            padding-bottom: 16px;
          }
        }

        img {
          //height: 15px;
        }
      }
    }

    .three-articles {
      .post-image-thumbnail {
        min-height: 224px;
      }

      h2 {
        text-transform: initial;
        letter-spacing: 0;
      }

      .col-md-4 {
        margin: 15px 0;
        height: 100%;

        .element {
          text-align: center;

          .inside {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            height: 230px;
            background-color: $color-background;

            h6 {
              margin-bottom: 12px;
            }
          }

          &.native-article {
            //box-shadow: -5px -5px 0 $main-color;

            a {
              color: white;
              text-decoration: none;

              &:hover {
                color: #878787;
              }
            }

            .inside {
              background-color: black;
              color: white;
            }
          }
        }
      }
    }

    .latest-four-container {
      //margin-top: 30px;
      padding: 20px;
      background-color: $color-background-option;
    }

    .four-articles {
      background-color: $color-background-option;
      max-height: 270px;
      margin-bottom: 50px;
      padding: 34px 0;
      text-align: left;

      .contentarea {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        height: 60px;
        background-repeat: no-repeat;
        text-align: center;
        margin: 0 auto;
        text-transform: none;
        background-position: top center;
        width: 145px;
        letter-spacing: 0;
      }

      h4 {
        margin: 0;
      }

      .category {
        margin: 16px 0;
      }

      .element {
        width: 15.25%;
      }
    }

    .native-article {
      margin-top: 0;

      img {
        &.img-responsive {
          //box-shadow: -5px -5px 0 $main-color;
        }
      }

      a {
        color: white;
        text-decoration: none;

        &:hover {
          color: #878787;
        }
      }

      .sponsored {
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 5px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2px 5px;
        font-family: $font-stack-general-1;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 1px;
        height: 15px;
        display: flex;
        align-items: center;
        color: black;

        &:hover {
          background-color: rgba(255, 255, 255, 1);
        }

        a {
          color: black !important;
          text-decoration: none !important;

          &:hover {
            color: black !important;
          }
        }
      }

      &.four-articles {
        .sponsored {
          left: 143px;
          top: 100px;
          padding: 0;
        }
      }

      .content {
        background-color: black;
      }
    }

    .video-thumbnail {
      position: absolute;
      opacity: 1;
      width: auto !important;
      height: 50px;
      top: 41%;
      left: 43.5%;
      z-index: 1;

      @media screen and (min-width: 1025px) {
        top: 20%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 18%;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        top: 13%;
      }
    }

    hr {
      border-top: 1px solid $main-color;
      margin: 0 auto;
      width: 17px;
    }

    h4 {
      text-transform: initial;
      letter-spacing: 0;
      margin-bottom: 20px;
    }

    .two-articles {
      .sponsored {
        margin: 10px;
      }
    }

    /*
    .two-articles .col-md-6 ,
    .one-article .col-md-8 ,
    .three-articles .col-md-4 ,
    .four-articles .element{
      &:hover{
        .post-image-thumbnail{
          opacity: .5;
        }
        h2 , h4{
          a{
            color: #515151;
          }
        }
        .category{
          color: #515151;
        }
        .read-article{
          color: #000000;
          a{
            color: #000000;
          }
        }
      }
    }
    */

    @media (max-width: $mobile-breakpoint) {
      .social {
        display: none;
      }

      .mobile-social-media {
        z-index: 9;
      }

      .element {
        padding: 0;
      }

      .two-articles {
        max-height: unset;
        //.col-md-6 {max-height: 310px;}
        .element {
          .col-md-11 {
            padding: 0;

            &.native-article {
              h6 {
                a {
                  color: white;
                  text-decoration: none;

                  &:hover {
                    color: #878787;
                  }
                }
              }
            }
          }

          .content {
            position: relative;
            left: 0;
            top: 0;
            height: auto;
            width: 100%;
          }

          h2 {
            max-height: 64px;
            overflow: hidden;
            //white-space:nowrap;
            //text-overflow: ellipsis;
          }
        }
      }

      .four-articles {
        max-height: 100%;

        .category-main-title {
          background-position: center;
          line-height: 2rem;
          padding-top: 12px;
        }

        .element {
          width: 50%;
          padding: 10px;
          min-height: 220px;

          .category {
            margin: 15px 0;
            //padding: 15px 0;
          }
        }
      }

      .three-articles {
        .col-md-4 {
          height: 100%;
          padding: 0;
        }
      }

      .one-article {
        text-align: center;
      }
    }
  }
}
