.subcategory-page {
  .taxonomy-title {
    /* margin-bottom: 55px; */

    h1 {
      font-family: $font-stack-general-1;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 300;
      line-height: 2.21;
      letter-spacing: 1.7px;
      color: #000;
      margin-top: 20px;
    }

    hr {
      border-top: 5px solid #ff5859;
      margin: 0 auto;
      width: 50px;
    }
  }

  &.recipes {
    .recipes-categories-container {
      margin: 0 170px;
      margin-top: 30px;

      .element {
        &:hover {
          .post-image-thumbnail {
            opacity: 0.5;
          }

          .category-title-container {
            background-color: black;
          }
        }
      }

      img {
        width: 100%;
      }

      .category-title-container {
        position: absolute;
        margin: 0 auto;
        top: 30%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 120px;
        height: 40px;
        background-color: $main-color;

        h5 {
          margin: 0;
          padding: 10px;
          text-align: center;
          text-transform: uppercase;
          color: white;
          font-family: $font-stack-general-1;
          font-size: 0.625rem;
          letter-spacing: 1px;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    &.recipes {
      .title-area {
        margin: 0 auto;
        padding: 0 15px;
      }

      .recipes-categories-container {
        margin: 0;

        .element {
          padding: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
