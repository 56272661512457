.sv04-js-articles-container {
  > .container {
    margin-bottom: 0;
  }
}

.sv04-tag-latest {
  .ads_pub_fullscreen {
    .element {
      min-height: auto !important;
    }
  }

  .element {
    min-height: 224px;
    margin-bottom: 20px;
  }

  a {
    color: black;
    text-decoration: none;
  }

  @media (max-width: $mobile-breakpoint) {
    .element {
      height: auto;
      min-height: 170px;
    }
  }
}
