.sv-privacy {
  text-align: center;
  padding: 0 15px;
  margin: 30px 0;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }

  h1 {
    margin: 10px 0 20px;
    padding: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 2.14;
    letter-spacing: 1.7px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
  }

  hr {
    width: 100%;
    border-top: solid 1px #d7d7d7;
    height: 1px;

    &.orange {
      border-top: 5px solid #ff5859;
      margin: 0 auto;
      width: 50px;
    }
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: #878787;
    }
  }
}
