.sv_people_area {
  margin-bottom: 60px;
  text-align: center;

  @media (min-width: $mobile-breakpoint) {
    padding-bottom: 40px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 15px;
    padding-right: 15px;
  }

  h1 {
    margin: 10px 0;
    padding: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 2.14;
    letter-spacing: 1.7px;
    text-align: center;
    color: #000;
    text-transform: uppercase;

    @media (min-width: $mobile-breakpoint) {
      margin: 10px 0 20px;
    }
  }

  hr {
    width: 100%;
    border-top: solid 1px #d7d7d7;
    height: 1px;

    &.orange {
      border-top: 5px solid #ff5859;
      margin: 0 auto;
      width: 50px;
    }
  }

  h2 {
    font-size: 15px;
    font-family: $font-stack-general;
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;

    @media (min-width: $mobile-breakpoint) {
      font-size: 10px;
      font-weight: 300;
      line-height: 1.8;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: PlayfairDisplay;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    color: #000;
  }

  .email {
    display: block;
    font-family: Montserrat;
    color: #000;
    margin-top: 10px;
    margin-bottom: 16px !important;
    font-size: 13px;
    white-space: nowrap;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
  }



  a:hover {
    text-decoration: none;
  }

  .sv_people {
    margin-bottom: 16px !important;
    width: 180px;
    min-width: 180px;
    @media (min-width: $mobile-breakpoint) {
      margin: 0 15px;
      width: 180px;
      min-width: 180px;
    }

    > p {
      margin-top: 0 !important;
    }

    img {
      width: 164px;
      height: 164px;
      border-radius: 50%;
      margin: auto;
    }
  }
}

.firstletter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #000;
  margin-top: 10px;

  span {
    font-size: 80px;
    font-weight: bold;
    line-height: 0.3;
    letter-spacing: 8px;
    color: #ff5859;
    padding: 25px 11px 10px 9px;
    float: left;
  }
}

.sv_people_area__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
