.sv-01-image-w-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-weight: bold;
  list-style: none;

  @media (min-width: $mobile-breakpoint) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  img {
    max-width: 100%;
  }

  h2 {
    margin-top: 0;
  }

  .caption {
    text-align: left;
    font-weight: normal;
    order: 1;
    margin-top: 10px;

    @media (min-width: $mobile-breakpoint) {
      order: 2;
    }

    p {
      line-height: 1rem;
      font-family: $font-stack-general-1;
      font-weight: 300;
      font-size: 9px;
      margin: 0;
      color: #9d9d9c;
      text-transform: uppercase;
    }
  }

  // flexing
  .side-text,
  .left-image,
  .caption {
    flex: 0 0 50%;
  }

  .left-image {
    img {
      display: block !important;
    }
  }

  .side-text {
    padding-left: 20px;
    order: 2;

    @media (min-width: $mobile-breakpoint) {
      padding: 0 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      order: 1;
    }

    & h2 {
      font-family: $font-stack-general;
      font-size: 24px;
      line-height: 30px;
      color: #000;
      margin-bottom: 20px;
      padding: 0 !important;
    }

    & h4 {
      padding: 0 !important;
    }

    & p:first-of-type {
      padding: 0;
    }

    p {
      word-wrap: break-word;
      margin: 0;
      padding: 30px 20px;
    }

    p span {
      font-family: $font-stack-general;
      font-size: 20px;
      line-height: 30px;
      font-weight: normal;
      text-align: center;
      word-wrap: break-word;
      margin: 20px 0 0;

      @media (min-width: $mobile-breakpoint) {
        font-size: 12px;
        line-height: 14px;
      }

      @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &.background-pink {
    .side-text {
      background-color: $main-color;

      p span {
        color: #fff;
      }
    }
  }

  .quote {
    text-align: left;
    color: white;
    margin: 0;
    font-size: 1.375rem;
    line-height: 2.25rem;
    font-weight: normal;

    &::before {
      color: white;
    }

    &::after {
      color: white;
      top: 56px;
    }
  }

  .left-image {
    background: #eee;
    padding: 0;

    img {
      margin: 0;
    }

    p {
      margin: 0;
      padding: 0 !important;
      width: 100%;
      height: 100%;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    p,
    a {
      background-position: top center;
    }
  }
}
