.om-homepage {
  margin: 0 0 -40px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: -40px 0;
  }

  /*
  [data-om-location-vis] {
    display: none;
  }
  */

  .om-banner {
    background-image: url("../images/om-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 450px;
    text-align: center;

    @media screen and (min-width: $mobile-breakpoint) {
      height: 637px;
    }

    .banner-content {
      width: 100%;
      height: 100%;
      padding: 30px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: $mobile-breakpoint) {
        max-width: 650px;
        margin: 0 auto;
      }
    }

    h1 {
      font-family: "melloner-fun";
      font-size: 35px;
      line-height: 40px;
      color: white;
      text-align: center;
      margin: 0 0 30px;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 67px;
        line-height: 90px;
        margin: 70px 0 50px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
        margin-bottom: 30px;
      }

      .city-info {
        margin: 0 0 30px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 0 20px 10px;
        }

        /*
          &:hover {
            opacity: 0.75;
          }

          a {
            &:hover {
              text-decoration: none;
              color: white;
            }
          }
        */
        h2,
        h3 {
          font-family: $font-stack-general-1;
          text-transform: uppercase;
          text-align: center;
          color: white;
          margin: 0;
        }

        h2 {
          font-size: 18px;
          line-height: 1.5rem;

          //font-size: 21px;
          font-weight: 700;
          padding-bottom: 5px;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 25px;
          }
        }

        h3 {
          line-height: 1.313rem;

          //font-size: 18px;
          font-weight: lighter;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 22px;
          }
        }
      }
    }
  }

  .om-main-menu {
    margin: 40px auto 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin: 70px auto 0;
    }

    .om-main {
      margin-bottom: 0;
    }
  }

  .om-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 45px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin: 0;
    }

    .om-menu-dropdown {
      margin-bottom: 10px;
    }

    .om-section-title {
      margin: 45px 0 20px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin: 55px 0 30px;
      }
    }

    p,
    .om-section-text p {
      font-family: $font-stack-general-1;
      color: black;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      font-weight: 300;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      a {
        color: $om-color !important;
        text-decoration: none !important;
      }
    }
  }

  .om-main-content {
    h1 {
      font-family: $font-stack-general-1;
      //font-size: 18px;
      font-size: 16px;
      line-height: 25px;
      font-weight: lighter;
      text-align: center;
      color: black;
      margin-bottom: 30px;

      @media screen and (min-width: $mobile-breakpoint) {
        /*
        font-size: 25px;
        line-height: 34px;
        */

        font-size: 18px;
        line-height: 24px;
      }
    }

    .om-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      background-color: white;
      height: 100%;
      border: 10px solid white;
      padding: 0;
      margin-bottom: 40px;

      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
        height: 320px;
        padding: 30px 0;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 280px;
        padding: 20px 0;

        @media screen and (min-width: $mobile-breakpoint) {
          height: 100%;
          padding: 0;
        }

        h2 {
          font-family: $font-stack-general;
          font-size: 24px;
          color: $om-color;
          margin: 0 0 15px;
        }

        p {
          font-family: $font-stack-general-1;
          font-size: 16px;
          line-height: 21px;
          color: black;
        }
      }

      .map {
        height: 275px;
        margin: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          height: 300px;
        }
      }

      /* Always set the map height explicitly to define the size of the div
        * element that contains the map. */
      #map {
        height: 100%;
      }
    }

    .cta-pink {
      margin-bottom: 20px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-bottom: 0;
      }
    }
  }

  .om-highlight {
    background-image: url("../images/om-highlight.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 40px 0;

    @media screen and (min-width: $mobile-breakpoint) {
      height: 593px;
      padding: 0;
    }

    .highlight-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .om-section-title {
      margin-bottom: 20px;
      margin-top: 0;
      font-family: "melloner-fun", 'Montserrat', Arial;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-bottom: 50px;
        margin-top: 45px;
      }

      h1,
      h2 {
        color: white;
      }

      h2 {
        text-transform: uppercase;
        font-size: 20px;
        font-family: "melloner-fun", 'Montserrat', Arial;
        font-weight: bold;
      }
    }

    .highlight-data {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      text-align: center;
      color: white;
      margin-bottom: 20px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-bottom: 50px;
      }

      .data-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .data-block {
          margin: 0;
          width: 50%;

          @media screen and (min-width: $mobile-breakpoint) {
            margin: 0 20px;
            width: auto;
          }

          .data {
            display: inline-block;
            margin: 0 10px;
          }
        }

        h1 {
          font-family: $font-stack-general-1;
          font-size: 45px;
          font-weight: 700;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 50px;
          }

          span {
            font-family: inherit !important;
            font-size: inherit !important;
            line-height: inherit !important;
          }
        }

        p {
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 2px;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 21px;
          }
        }

        .cta-pink {
          margin-bottom: 0;
        }
      }
    }

    .highlight-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 35px;

      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
        margin-bottom: 50px;
      }

      ul {
        list-style: none;
        font-family: $font-stack-general-1;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.5px;
        color: white;
        //margin: 0 20px;
        width: 100%;

        @media screen and (min-width: $mobile-breakpoint) {
          font-size: 21px;
          margin: 0 50px;
          width: initial;
        }

        li {
          margin: 2px 0;

          &:before {
            content: '';
            background-image: url("../images/icon_check_1edicao.svg");
            background-size: contain;
            background-repeat: no-repeat;
            padding-left: 30px;
            height: 20px;
            width: 20px;

            @media screen and (min-width: $mobile-breakpoint) {
              padding-left: 35px;
            }
          }
        }
      }
    }
  }

  .om-box {
    margin: 90px 0 70px;
  }

  .om-rh {
    margin-bottom: 40px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 10px;
    }

    .om-section-title {
      margin: 0 0 20px;

      h1 {
        color: black;
      }
    }

    .rh-content {
      max-width: 590px;
      margin: 0 auto;
      text-align: center;

      h2 {
        font-family: $font-stack-general-1;
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        color: black;
        letter-spacing: 0.5px;
        margin: 0 0 20px;
      }

      p {
        font-family: $font-stack-general-1;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: black;
        margin-bottom: 0;
        letter-spacing: 0.5px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin-bottom: 20px;
        }

        a {
          color: $om-color;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.om-ticket-container {
  background-color: #fff;
  width: 100vw;
  padding: 0 0 40px;

  @media screen and (min-width: $mobile-breakpoint) {
    padding: 0 15% 70px;
  }

  .cta__wrapper {
    text-align: center;
  }
}

.om-article-tickets {
  .cta__wrapper {
    position: absolute;
    bottom: -68px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.om-video-wrapper {
  position: relative;
  padding-bottom: 54%;
  padding-top: 25px;
  height: 0;
  margin: 0 -15px 40px;

  @media screen and (min-width: $mobile-breakpoint) {
    margin: 0 0 -19%;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);

    @media screen and (min-width: $mobile-breakpoint) {
      width: 60%;
      height: 60%;
    }
  }
}

.om-articles {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .om-section-title {
    margin-bottom: 30px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 50px;
    }

    h1,
    h2 {
      color: black;
    }
  }

  .om-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      flex-direction: row;
      margin-bottom: 50px;
    }

    .om-ticket-card {
      flex: 0 1 50%;
      text-align: center;
      margin-bottom: 30px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-bottom: 0;
      }

      & .thumbnail-area {
        width: 100%;
        margin: 0 auto;
        max-width: 70px;

        @media screen and (min-width: $mobile-breakpoint) {
          max-width: 90px;
        }
      }

      & .content-area {
        h2 {
          font-family: $font-stack-general-1;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.5rem;
          margin: 20px 0;

          @media screen and (min-width: $mobile-breakpoint) {
            margin: 25px 0 20px;
          }
        }

        p {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    .om-card {
      position: relative;
      background-color: white;
      text-align: center;
      display: inline-block;
      margin: 0 0 20px;

      @media screen and (min-width: $mobile-breakpoint) {
        width: 367px;
        height: 460px;
        margin: 0 15px;
      }

      .thumbnail-area {
        width: 100%;
        //height: 230px;
        background-color: #878787;

        img {
          width: 100%;

          &:hover {
            opacity: 0.75;
          }
        }
      }

      .content-area {
        padding: 30px;

        h2 {
          margin: 0 0 30px;

          a {
            font-family: $font-stack-general;
            font-size: 24px;
            line-height: 30px;
            color: black;
            text-decoration: none;

            &:hover {
              color: #878787;
            }
          }
        }
      }
    }
  }
}

.cta-buttons {
  text-align: center;

  .cta__wrapper {
    display: inline-block;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
