/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display'), local('PlayfairDisplay-Regular'), url('../fonts/playfair-display-v11-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-regular.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-regular.woff') format('woff'), url('../fonts/playfair-display-v11-latin-regular.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url('../fonts/playfair-display-v11-latin-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-italic.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-italic.woff') format('woff'), url('../fonts/playfair-display-v11-latin-italic.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-700 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url('../fonts/playfair-display-v11-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-700.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-700.woff') format('woff'), url('../fonts/playfair-display-v11-latin-700.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-700italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url('../fonts/playfair-display-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-700italic.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-700italic.woff') format('woff'), url('../fonts/playfair-display-v11-latin-700italic.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-700italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-900 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Black'), local('PlayfairDisplay-Black'), url('../fonts/playfair-display-v11-latin-900.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-900.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-900.woff') format('woff'), url('../fonts/playfair-display-v11-latin-900.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* playfair-display-900italic - latin */
@font-face {
  font-family: 'Playfair Display';
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  font-display: auto;
  src: url('../fonts/playfair-display-v11-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'), url('../fonts/playfair-display-v11-latin-900italic.eot?#iefix') format('embedded-opentype'), url('../fonts/playfair-display-v11-latin-900italic.woff2') format('woff2'), url('../fonts/playfair-display-v11-latin-900italic.woff') format('woff'), url('../fonts/playfair-display-v11-latin-900italic.ttf') format('truetype'), url('../fonts/playfair-display-v11-latin-900italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
