.om-archive {
  @media (max-width: 767px) {
    margin-top: 50px;
  }

  background-color: #f0f0f0;
  text-align: center;

  > .top-area {
    background-color: #fff;
    margin-top: -100px;
    padding: 100px 0;

    > h1 {
      font-family: $font-stack-general-1, sans-serif;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
    }

    > h2 {
      font-family: 'Melloner Fun', sans-serif;
      font-size: 60px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 700;
      color: #ea2f7c;
    }
  }
}
