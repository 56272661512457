.special-article-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $mobile-breakpoint) {
    padding-bottom: 50px;
  }

  &:first-child {
    padding-bottom: 0;

    > .container {
      margin-bottom: 0;
    }
  }

  &:last-child {
    padding-bottom: 0;

    > .container {
      margin-bottom: 0;
    }
  }

  &.special-article-content-wrapper--backgrounded {
  }

  > .container {
    text-align: center;

    > div {
      text-align: left;
    }
  }
}

.native-article {
  margin-top: -32px;

  @media screen and (max-width: $mobile-breakpoint) {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .native-dashed-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 45px 0 30px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin: 60px 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 0;
    }

    h1 {
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 300;
      text-align: center;
      font-size: 14px;
      color: black;
      margin: 0;
    }

    hr {
      margin: 0 0 6px;
    }

    .red {
      border: 3px solid #be0f35;
      width: 50px;
    }
  }

  .native-article-content {
    /*
    .native-header-highlight {
      background-color: white;
    }
    */

    .native-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto 30px;
      padding: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 0 180px;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        margin-top: 0;
        margin-left: -15px;
        width: calc(100% + 30px);

        img {
          max-width: 100%;
          height: auto;
          margin-top: 55px;
        }
      }

      .native-article-header {
        max-width: 190px;
        margin: 0 auto 30px;

        @media screen and (min-width: $mobile-breakpoint) {
          max-width: 309px;
        }

        &:hover {
          opacity: 1;
        }
      }

      h2 {
        font-family: $font-stack-general;
        font-style: italic;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 23px;
        margin: 0 20px 30px;
        position: relative;

        @media screen and (min-width: $mobile-breakpoint) {
          font-size: 20px;
          line-height: 26px;
          margin: 20px 0 30px;
        }

        @media screen and (max-width: $mobile-breakpoint) {
          margin-top: 30px;
        }

        .native-sponsored-wrapper {
          position: absolute;
          right: -125px;
          top: -3px;

          span {
            display: inline-block;
            border-bottom: 1px solid black;
            margin-bottom: 30px;
            font-size: 12px;
          }

          @media screen and (max-width: $mobile-breakpoint) {
            position: relative;
            top: 0;
            right: 0;
            margin-top: 10px;

            img {
              margin-top: 0;
            }
          }
        }
      }
    }

    .native-destaque {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 auto 30px;
      padding: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        flex-direction: row;
        margin: 30px auto 70px;
      }

      .share-aside {
        float: none;
        text-align: center;
        margin-top: 10px;
        margin-right: 0;
        display: flex;
        justify-content: space-around;

        @media screen and (min-width: $mobile-breakpoint) {
          display: inline-block;
          float: right;
          margin-top: 400%;
          margin-right: 15px;
        }

        figure {
          padding: 6px;

          @media screen and (max-width: $mobile-breakpoint) {
            display: inline-block;
          }

          img {
            height: 22px;

            @media screen and (min-width: $mobile-breakpoint) {
              height: 15px;
            }
          }
        }
      }

      .native-destaque-content {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $mobile-breakpoint) {
          flex-direction: row;
        }
      }

      .img-destaque {
        width: 100vw;
        margin: 0 -15px;
        min-height: 200px;
        background-color: grey;

        @media screen and (min-width: $mobile-breakpoint) {
          height: 445px;
          width: 100%;
          margin: 0;
        }
      }

      .content-destaque {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        @media screen and (min-width: $mobile-breakpoint) {
          margin-left: 20px;
        }

        h1 {
          font-family: "Old Standard";
          font-style: italic;
          font-weight: normal;
          font-size: 28px;
          line-height: 32px;
          text-transform: uppercase;
          margin: 30px 0 25px;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 39px;
            line-height: 48px;
            margin: 0 0 25px;
          }
        }

        p {
          font-family: $font-stack-general-1;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 30px;
        }
      }
    }

    .native-bullet-cards {
      margin-top: -40px;
    }

    .native-card {
      margin: 0 auto 40px;
      padding: 0;
      overflow: hidden;
      display: flex;

      @media screen and (min-width: $mobile-breakpoint) {
        margin: 0 auto 125px;
        overflow: visible;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        display: block;
      }

      .thumbnail-area {
        width: 100%;
        height: 200px;
        background-color: grey;
        margin-left: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          width: 735px;
          height: 460px;
          margin-left: 6%;
        }

        img {
          width: 100%;
        }
      }

      .content-area {
        background-color: white;
        border: 1px solid #d7d7d7;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px 25px 15px;
        text-align: center;
        color: black;
        z-index: 1;
        position: initial;
        right: 0;
        bottom: 0;
        width: 100%;
        //height: 100%;


        @media screen and (min-width: $mobile-breakpoint) {
          width: 400px;
          height: fit-content;
          padding: 40px;
          margin-top: 56px;
          margin-left: -120px;
          //overflow: hidden;
        }

        @media screen and (max-width: $mobile-breakpoint) {
          padding-top: 50px;
          //overflow: hidden;
        }

        .native-bullet {
          display: flex;
          justify-content: center;
          border-radius: 100%;
          width: 65px;
          height: 65px;
          border: 3px solid black;

          @media screen and (min-width: $mobile-breakpoint) {
            width: 100px;
            height: 100px;
            border: 5px solid black;
          }

          p {
            font-family: $font-stack-general;
            font-weight: 700;
            font-size: 60px;
            margin: auto;
            padding-bottom: 20px;

            @media screen and (min-width: $mobile-breakpoint) {
              font-size: 92px;
              padding-bottom: 30px;
            }
          }
        }

        .bullet-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          h2 {
            font-family: $font-stack-general;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 23px;
            line-height: 15px;
            margin: 25px 0 20px;

            @media screen and (min-width: $mobile-breakpoint) {
              font-size: 29px;
              line-height: 34px;
              margin: 20px 0 15px;
            }

            @media screen and (max-width: $mobile-breakpoint) {
              line-height: 22px;
            }
          }

          p {
            font-family: $font-stack-general-1;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 30px;

            @media screen and (min-width: $mobile-breakpoint) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .native-card {
      &.left {
        .thumbnail-area {
          float: none;
          margin: 0;

          @media screen and (min-width: $mobile-breakpoint) {
            margin: 0 5% 0 0;
            float: right;
          }
        }

        .content-area {
          float: left;
          right: 0;
          left: 0;

          @media screen and (min-width: $mobile-breakpoint) {
            left: 6%;
            float: none;
          }
        }
      }
    }

    .native-product-cards {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        padding-bottom: 50px;
      }

      @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 35px;
      }

      .cta-sv-wrapper {
        margin: auto;
      }

      .native-product-wrapper {
        .native-product-card {
          color: black;
          text-align: center;
          padding: 0 0 35px;
          margin: 0;

          @media screen and (min-width: $mobile-breakpoint) {
            //margin: 0 0 50px;
            margin: 0;
            padding: 0 50px;
          }

          img {
            height: 200px;
            width: 100%;
            background-color: grey;

            &:hover {
              opacity: 1;
            }
          }

          h1 {
            font-family: $font-stack-general;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            margin: 20px 0;
          }

          p {
            font-family: $font-stack-general-1;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }

    .faqs-box {
      width: 100vw;
      margin: 0 -15px;
      padding: 0 0 40px;

      @media screen and (min-width: $mobile-breakpoint) {
        width: 100%;
        margin: 0;
        padding: 0 0 80px;
      }

      .faq-question {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid #d7d7d7;
        padding: 30px 25px;
        margin: 0;
        background-color: white;

        .faq-content {
          @media screen and (max-width: $mobile-breakpoint) {
            max-width: 270px;
          }
        }

        @media screen and (min-width: $mobile-breakpoint) {
          padding: 40px 65px;
        }

        h3 {
          font-family: $font-stack-general-1;
          font-size: 12px;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 0.15rem;
          color: #b2b2b2;
          margin: 0 0 10px;

          @media screen and (max-width: $mobile-breakpoint) {
            display: none;
          }
        }

        h1 {
          font-family: $font-stack-general;
          font-size: 30px;
          line-height: 35px;
          color: black;
          margin: 0;
        }
      }

      .faq-answer {
        display: none;
        width: 100%;
        border: 1px solid #d7d7d7;
        border-top: none;
        padding: 30px 25px;
        margin: 0;
        background-color: white;

        @media screen and (min-width: $mobile-breakpoint) {
          padding: 40px 65px;
        }

        p,
        li {
          font-family: $font-stack-general-1;
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          color: black;
          margin-bottom: 25px;

          @media screen and (min-width: $mobile-breakpoint) {
            margin-bottom: 15px;
          }
        }

        ol {
          padding-left: 0;
          margin-bottom: 30px;

          li {
            margin-bottom: 25px;

            @media screen and (min-width: $mobile-breakpoint) {
              margin-bottom: 15px;
            }
          }

          li::before {
            color: black;
            font-weight: 300;
          }
        }

        .cta-sv-wrapper {
          margin: 0;
        }
      }
    }

    .faq-button {
      position: relative;
      display: inline-block;
      float: right;
      margin: 0;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: $main-color;
      text-align: center;

      @media screen and (min-width: $mobile-breakpoint) {
        width: 50px;
        height: 50px;
        margin: 20px 0 0;
      }

      .faq-button-wrapper {
        position: absolute;
        left: -2px;
        top: 3px;
        transform: translate(50%, 50%) rotate(180deg);
        transition: transform 0.4s;

        .faq-button-arrow {
          width: 17px;
          padding-top: 6px;

          @media screen and (min-width: $mobile-breakpoint) {
            width: 28px;
            padding-top: 15px;
          }
        }
      }

      &:hover {
        background-color: black;

        img {
          opacity: 1;
        }
      }

      &[data-collapser-target-opened="true"] {
        .faq-button-wrapper {
          top: 0;
          transform: translate(50%, 0%) rotate(0deg);
        }
      }
    }

    .native-contact-form {
      background-color: white;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wpcf7-form .wpcf7-submit {
        display: block;
        margin: 0 auto;
      }

      p,
      .wpcf7-response-output {
        font-family: $font-stack-general-1;
        font-size: 16px;
        line-height: 24px;
        color: black;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      .contact-form-success {
        background-color: #f5f8fb;
        padding: 35px 0;
        text-align: center;

        h1 {
          font-family: $font-stack-general;
          font-size: 42px;
          margin: 0 0 30px;
          color: black;
          font-weight: 700;
        }

        a {
          font-family: $font-stack-general-1;
          font-size: 12px;
          line-height: 20px;
          font-weight: 300;
          color: $main-color;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          text-decoration: underline;

          &:hover {
            color: black;
          }
        }

        h2 {
          font-family: Sacramento;
          font-size: 30px;
          color: black;
          margin-top: 40px;
          font-weight: 400;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 36px;
          }
        }

        .native-share-wrapper {
          margin: 20px 0 0;
        }
      }
    }

    .native-share-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 60px;
      margin-top: 30px;

      @media screen and (min-width: $mobile-breakpoint) {
        margin-top: -30px;
        margin-bottom: 80px;
      }

      .native-share-button {
        margin: 0 20px;
        height: 40px;
        width: 40px;
        background-color: $main-color;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: $mobile-breakpoint) {
          height: 45px;
          width: 45px;
        }

        &:hover {
          background-color: black;

          img {            opacity: 1;
          }
        }

        .share-social {
          width: 20px;

          @media screen and (min-width: $mobile-breakpoint) {
            width: 25px;
          }
        }
      }
    }
  }
}

