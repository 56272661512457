.om-event {
  .openmag-articles {
    padding-right: 0;
    margin: 0;

    .btn-subscribe {
      background: $om-color;
      margin: 0 auto 20px;
    }
  }

  .tabs {
    justify-content: center !important;
    margin-top: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-top: 30px;
    }
  }

  .om-menu {
    margin-bottom: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 40px;
    }
  }

  .om-main .om-section-title {
    margin-top: 0;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-top: 20px;
    }
  }

  .cta-location {
    @media screen and (max-width: $mobile-breakpoint) {
      min-width: 190px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .cta-location:first-of-type {
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 20px;
    }
  }

  .talks-workshops-ctas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;

    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 60px;
    }

    .cta {
      min-width: 150px;
    }

    .cta__wrapper {
      padding: 0 10px;
    }
  }

  .om-info-box .box-content {
    padding: 0 15px;
  }

  .mobile-post-card {
    padding: 0;
  }

  .om-onde-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: white;
    height: 100%;
    border: 10px solid white;
    padding: 0;
    margin-bottom: 40px;

    @media screen and (min-width: $mobile-breakpoint) {
      flex-direction: row;
      /*height: 320px;
      padding: 30px 0;*/
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 280px;
      padding: 20px 0;

      @media screen and (min-width: $mobile-breakpoint) {
        height: 100%;
        padding: 0;
      }

      h2 {
        font-family: $font-stack-general;
        font-size: 24px;
        color: $om-color;
        margin: 0 0 15px;
      }

      p {
        font-family: $font-stack-general-1;
        font-size: 16px;
        line-height: 21px;
        color: black;
      }
    }

    .map {
      height: 275px;
      margin: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        height: 300px;
      }
    }

    /* Always set the map height explicitly to define the size of the div
      * element that contains the map. */
    #map {
      height: 100%;
    }
  }
}

.event-time {
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0;
  background-color: $om-color;
  padding: 2px 7px;
  font-family: $font-stack-general-1;
  font-size: 12px;
  font-weight: 300;
  height: 30px;
  display: flex;
  align-items: center;
  color: white;

  &:hover {
    background-color: black;
  }

  a {
    color: white !important;
    text-decoration: none !important;

    &:hover {
      color: white !important;
    }
  }

  img {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px;
  }
}