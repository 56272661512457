.btn-subscribe {
  background-color: $main-color;
  color: white;
  padding: 10px;
  //width: 50%;
  font-family: $font-stack-general-1;
  text-align: center;
  font-size: 0.75rem;
  margin: 0 auto;
  display: block;
  width: 100px;
  border-radius: unset;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }

  &:hover,
  &:active {
    color: white;
    background-color: black;
  }
}

#sv03-load-more,
#sv04-load-more {
  margin: 10px auto 30px;
}
