.diabetes-archive {
  .diabetes-archive-cards {
    padding-bottom: 60px;

    &.row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.article-diabetes {
  background-color: transparent;
  padding: 0;
  margin: 15px -5px;

  @media (min-width: $breakpoint-xs) {
    padding: 0 15px;
    margin: 15px 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  &.col-md-4 {
    margin-bottom: 40px;
    height: 100%;

    .element {
      text-align: center;
      background-color: white;

      .inside {
        padding: 20px;
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .category {
          color: black;
          text-decoration: none;

          &:hover {
            color: #878787;
            text-decoration: none;
          }

          &:focus {
            color: #878787;
            text-decoration: none;
          }

          &:active {
            color: #878787;
            text-decoration: none;
          }
        }

        .ellipsis {
          color: black;
          text-decoration: none;

          &:hover {
            color: #878787;
            text-decoration: none;
          }

          &:focus {
            color: #878787;
            text-decoration: none;
          }

          &:active {
            color: #878787;
            text-decoration: none;
          }
        }
      }
    }
  }
}