/*
.popup-viver-melhor {
  //z-index: 1;
  background-color: rgba(100, 100, 100, 0.5); //#959595aa;
  //opacity: 50%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6000;

  .popup {
    .border {
      margin: auto;
      padding: 8px;
      height: 100%;
      width: 100%;
      background: transparent;
      border-width: 6px;
      border-style: solid;
      border-image: linear-gradient(to bottom right, #ff9aff, #ff8b59);
      border-image-slice: 1;

      @media screen and (min-width: $mobile-breakpoint) {
        height: 100%;
        width: 730px;
      }
    }

    .box {
      background: linear-gradient(to bottom right, #ff9aff, #ff8b59);
      height: 100%;
      width: 100%;
      padding: 10px;
      text-align: right;

      @media screen and (min-width: $mobile-breakpoint) {
        padding: 30px;
      }

      button {
        background: transparent;
        border: none;
      }

      .close-popup {
        img {
          height: 25px;
          padding: 0;
          margin: 0;
        }
      }

      .popup-content {
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 0;

        h1 {
          font-family: $font-stack-general;
          font-size: 29px;
          line-height: 34px;
          letter-spacing: 0.05rem;
          margin: 0 auto 20px;
          max-width: 90%;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 39px;
            line-height: 47px;
            max-width: none;
            margin: 0 auto 30px;
          }
        }

        h2 {
          font-family: $font-stack-general-1;
          font-size: 12px;
          line-height: 18px;
          font-weight: 300;
          max-width: 90%;
          margin: 0 auto 25px;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 16px;
            line-height: 24px;
            max-width: 75%;
            margin: 0 auto 40px;
          }
        }

        .close-popup-message {
          font-family: $font-stack-general-1;
          font-size: 10px;
          line-height: 18px;
          font-weight: 300;
          color: black;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          text-decoration: underline;
          padding-bottom: 10px;
          max-width: 80%;
          margin: 0 auto;

          @media screen and (min-width: $mobile-breakpoint) {
            max-width: none;
            padding-bottom: 0;
            margin: 30px auto 40px;
          }

          &:hover {
            color: white;
          }
        }

        .popup-info {
          font-family: $font-stack-general-1;
          font-size: 10px;
          line-height: 17px;
          letter-spacing: 0.05rem;
          max-width: 80%;
          margin: 0 auto 15px;

          @media screen and (min-width: $mobile-breakpoint) {
            max-width: none;
            margin: 0 auto;
          }
        }
      }

      .wpcf7-form {
        .wpcf7-form-control-wrap input {
          color: white;
        }

        .wpcf7-validation-errors,
        .wpcf7-mail-sent-ok {
          color: white;
          margin: -10px 0 10px;
          font-size: 16px;

          @media screen and (min-width: $mobile-breakpoint) {
            margin: 0 0 20px;
          }
        }
      }

      form {
        color: white;
        font-family: $font-stack-general-1;
        min-height: 100px;
        letter-spacing: 0.015rem;
        margin-bottom: 5px;
        text-align: center;

        .ajax-loader {
          display: block;
          margin: 10px auto 0;
        }

        p {
          margin: 0;
        }

        input {
          margin: 0;
        }

        input[type="email"] {
          outline: none;
          border: 1px solid white;
          background-color: initial;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          font-size: 10.5px;
          height: 45px;
          font-weight: 300;
          width: 230px;
          padding: 10px;
          margin-bottom: 5px;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 14px;
            margin: 0;
            width: 340px;
          }

          &::-webkit-input-placeholder {
            color: white;
          }

          &:-moz-placeholder {
            color: white;
            opacity: 1;
          }

          &::-moz-placeholder {
            color: white;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: white;
          }

          &:-ms-input-placeholder {
            color: white;
          }
        }

        input[type="submit"] {
          background-color: black;
          color: white;
          border: 1px solid black;
          padding: 10px;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          font-size: 10.5px;
          height: 45px;
          width: 230px;
          border-radius: 0;
          margin: 0;

          @media screen and (min-width: $mobile-breakpoint) {
            font-size: 14px;
            width: 340px;
          }

          &:hover,
          &:active {
            color: black;
            background-color: white;
            border: 1px solid white;
          }
        }
      }
    }
  }
}

*/