/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../fonts/montserrat-v10-latin-300.woff2') format('woff2'), url('../fonts/montserrat-v10-latin-300.woff') format('woff');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/montserrat-v10-latin-regular.woff2') format('woff2'), url('../fonts/montserrat-v10-latin-regular.woff') format('woff');
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('../fonts/montserrat-v10-latin-700.woff2') format('woff2'), url('../fonts/montserrat-v10-latin-700.woff') format('woff');
}
