.most-read {
  border: 15px solid #f5f8fb;
  padding: 36px 0;
  height: 400px;

  hr {
    border-top: 5px solid $main-color;
    margin: 0 auto;
    width: 50px;
  }

  h3 {
    text-align: center;
    font-family: $font-stack-general-1;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    margin-bottom: 36px;

    @media (min-width: $mobile-breakpoint) {
      margin: 15px 0 36px;
    }
  }

  ul {
    padding: 0;
    margin: 0 auto;
    width: 75%;
    text-align: center;

    li {
      margin-bottom: 10px;

      &::before {
        color: black;
      }

      a {
        font-family: $font-stack-general;
        font-weight: bold;
        color: black;
        text-decoration: underline;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    height: auto;
  }
}

.category {
  .most-read {
    ul {
      li {
        a {
          font-family: "Playfair Display", sans-serif;
          font-weight: bold;
          color: black;
          text-decoration: underline;
          font-size: 14px;
          text-transform: initial;
          line-height: 1.12;
        }
      }
    }
  }
}
