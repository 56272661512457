.author-page {
  .container {
    margin-bottom: 0;
  }

  .author-info {
    text-align: center;

    img {
      margin: 0 auto;
      border-radius: 50%;
    }

    .name {
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 10px;
      letter-spacing: 1px;
    }

    .title {
      font-family: $font-stack-general-1;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: 1px;
    }

    .description {
      font-size: 16px;
      line-height: 20px;
      max-height: 60px;
      overflow: hidden;
    }

    hr {
      margin: 40px 0;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .author-info {
      padding-top: 40px;

      img {
        margin-bottom: 25px;
      }

      .author-details {
        text-align: center;
      }
    }
  }
}

.sv05-author-latest {
  .btn-subscribe {
    background-color: #ff5859;
    color: #fff;
    padding: 10px;
    //width: 50%;
    font-family: Montserrat, Arial, Verdana, Geneva, sans-serif;
    text-align: center;
    font-size: 10.5px;
    margin: 0 auto;
    display: block;
    border-radius: unset;
    font-weight: 300;
    text-transform: uppercase;
    width: 170px;
    height: 45px;
  }
}
