.fourofour-page {
  @media (min-width: $mobile-breakpoint) {
    background-color: white;
    margin-top: -32px;
    padding-bottom: 70px;
  }

  .fourofour-content {
    margin-bottom: 0;

    .main {
      font-family: $font-stack-general-1;
      font-size: 82px;
      font-weight: 700;
      color: #e7eaed;

      @media (min-width: $mobile-breakpoint) {
        font-size: 12.5rem;
      }
    }

    .content {
      position: relative;
      bottom: 120px;
      right: 90px;
      background-color: #fff;

      .text {
        margin-top: 50px;
      }

      img {
        opacity: 0.7;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      .content {
        position: relative;
        bottom: 0;
        right: 0;
        text-align: center;

        .img {
          padding: 0;
        }

        .main {
          @media (min-width: $mobile-breakpoint) {
            font-size: 2rem;
          }
        }

        .text {
          margin-bottom: 30px;
          margin-top: 0;
          padding: 0 50px;

          @media (min-width: $mobile-breakpoint) {
            margin-bottom: 0;
            padding: 0;
          }

          h1 {
            margin-top: 0;
            font-size: 20px;
          }

          h4 {
            font-size: 14px;
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .no-paddings {
    padding: 0;

    .latest-area {
      padding: 30px 0;

      @media (min-width: $mobile-breakpoint) {
        padding: 0;
      }
    }
  }
}
