.mortgage-simulator {
  p,
  li {    margin: 1.5em 0;
  }

  li {    list-style: square;
  }

  li ul li {    margin: 1em 0;
  }

  a {    color: #1e90ff;    text-decoration: none;
  }

  a:hover {    text-decoration: underline;
  }

  input {
    font-family: inherit;
    font-size: 1em;
    width: 4em;
    text-align: right;
    border-width: 0 0 2px;
    border-color: #ff5859;
  }
}
