.page-template-landing_page_newsletter {
  padding: 0;

  main {
    margin: 0;
  }
}

.lp-newsletter__wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  min-height: 100vh;
}

.lp-newsletter {
  min-height: 100vh;
  padding-top: 15%;
  padding-bottom: 5%;
  margin-bottom: 0;

  @media screen and (min-width: $mobile-breakpoint) {
    padding-top: 7%;
    padding-bottom: 5%;
  }

  .wpcf7-response-output {
    margin: 20px 0;
    padding: 0;
    border: 0;
    font-family: "Playfair Display", sans-serif;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 30px;
    max-width: 450px;
    color: #fff;
  }

  .lp-newsletter-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    min-height: 480px;

    @media screen and (min-width: $mobile-breakpoint) {
      min-height: 550px;
    }

    .wpcf7-form {
      .conditions {
        .disclaimer {
          color: #fff;
          font-weight: 400;
          font-size: 12px;

          a {
            color: #fff;
          }
        }

        .custom-checkbox {
          width: 22px;
          height: 22px;

          label {
            background: transparent;
            border: 1px solid #fff;
            height: 100%;
            width: 100%;

            &:after {
              width: 14px;
              height: 8px;
              border: 2px solid #000;
              border-top: none;
              border-right: none;
            }
          }
        }
      }

      .form-container {
        justify-content: flex-start;
      }

      .first-block,
      .second-block {
        margin: 0;
      }
    }

    img {
      height: 24px;
    }

    h1 {
      font-family: $font-stack-general;
      font-size: 25px;
      line-height: 31px;
      color: white;
      max-width: 450px;
      margin-bottom: 25px;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 35px;
        line-height: 42px;
        margin: 0;
      }
    }

    h2 {
      font-family: $font-stack-general-1;
      font-size: 15px;
      font-weight: 300;
      //text-transform: uppercase;
      line-height: 1.5;
      letter-spacing: 0.15rem;
      color: white;
      margin-bottom: 40px;

      @media screen and (max-width: $mobile-breakpoint) {
        display: none;
      }

      &.sent {
        display: none;
      }
    }

    .message {
      font-family: $font-stack-general-1;
      font-size: 10px;
      line-height: 13px;
      color: white;
      max-width: 485px;
      letter-spacing: 0.015rem;
      margin: 0;

      @media screen and (min-width: $mobile-breakpoint) {
        font-size: 13px;
        line-height: 18px;
      }

      &.sent {
        display: none;
      }
    }

    .lp-newsletter__success {
      display: none;

      .lp-newsletter__success-title {
        font-family: $font-stack-general-1;
        font-weight: 300;
        color: #fff;
        font-size: 18px;
      }

      .native-share-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        .native-share-button {
          margin: 0 20px;
          height: 40px;
          width: 40px;
          background-color: $main-color;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: $mobile-breakpoint) {
            height: 45px;
            width: 45px;
          }

          &:first-child {
            margin-left: 0;
          }

          &:hover {
            background-color: black;

            img {              opacity: 1;
            }
          }

          .share-social {
            width: 20px;

            @media screen and (min-width: $mobile-breakpoint) {
              width: 25px;
            }
          }
        }
      }

      &.sent {
        display: block;
      }
    }

    .wpcf7-form {
      &.sent {
        //display: none;
      }

      .wpcf7-validation-errors,
      .wpcf7-mail-sent-ok {
        color: white;
        margin: 20px 0;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 20px 0 0;
        }
      }
    }

    form {
      color: white;
      font-family: $font-stack-general-1;
      min-height: 100px;
      letter-spacing: 0.015rem;

      p {
        display: inline-block;
        margin: 0;
      }

      input {
        margin: 0;
      }

      input[type="email"] {
        color: white;
        outline: none;
        border: 1px solid white;
        background-color: initial;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 14px;
        height: 45px;
        max-width: 425px;
        font-weight: 300;
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;

        @media screen and (min-width: $mobile-breakpoint) {
          margin: 0;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: white;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: white;
          opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: white;
          opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }

        &:-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
      }

      input[type="submit"] {
        background-color: $main-color;
        color: white;
        border: 1px solid $main-color;
        padding: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 14px;
        height: 45px;
        min-width: 170px;
        border-radius: 0;
        margin-left: 0;

        @media screen and (min-width: $mobile-breakpoint) {
          margin-left: 5px;
        }

        &:hover,
        &:active {
          color: white;
          background-color: black;
          border: 1px solid black;
        }
      }
    }
  }

  &.lp-newsletter-black {
    .lp-newsletter-content {
      h1 {
        color: black;
      }

      h2 {
        color: $main-color;
      }

      .message {
        color: black;
      }

      .wpcf7-form {
        .wpcf7-validation-errors,
        .wpcf7-mail-sent-ok {
          color: black;
        }
      }

      form {
        color: black;

        input[type="email"] {
          color: black;
          border: 1px solid black;
          background-color: initial;

          &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: black;
          }

          &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: black;
            opacity: 1;
          }

          &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: black;
            opacity: 1;
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: black;
          }

          &:-ms-input-placeholder { /* Microsoft Edge */
            color: black;
          }
        }
      }
    }
  }
}

