.sv-recipes-group {
  .general-separator-line {
    width: 100%;
    background-color: #ccc;
  }

  a {
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
    }
  }

  .title {
    hr {
      border-top: 5px solid $main-color;
      margin-bottom: 22px;
      margin-top: 0;
      width: 10%;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      font-family: $font-stack-general-1;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 50px;
    }
  }

  .element {
    text-align: center;
    margin-bottom: 35px;

    img {
      margin: 0 auto;
      border-radius: 50%;
    }

    .read-article {
      color: #7f7f7f;
      text-transform: uppercase;
      letter-spacing: 1px;

      hr {
        border-top: 2px solid $main-color;
        margin-bottom: 10px;
        margin-top: 0;
        width: 10%;
      }
    }
  }
}
