.js-cover-image {
  display: none;
}

.centered-element {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .article-content {
    h1,
    h2,
    h3,
    a {
      overflow: hidden;

      img {
        max-width: unset;
        width: calc(100% + 40px) !important;
        margin-left: -20px !important;
      }
    }
  }
}