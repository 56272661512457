/* sacramento-regular - latin */
@font-face {
  font-family: 'Sacramento';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/sacramento-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Sacramento'), local('Sacramento-Regular'), url('../fonts/sacramento-v4-latin-regular.woff') format('woff'), url('../fonts/sacramento-v4-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
