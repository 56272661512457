.sv-magazine-template {
  background: $color-background-option;
  height: 400px;

  .image-area {
    text-align: center;
  }

  img {
    width: 100%;
    padding: 40px 0 0 10px;
    max-width: 270px;
  }

  .content {
    padding: 50px;
    text-align: center;

    h5 {
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    h2,
    p {
      text-transform: none;
    }

    h2 {
      margin-bottom: 10px;
    }

    .box {
      padding: 20px;
      outline: 1px solid #ff5859;
      background-color: white;

      .btn-subscribe {
        font-weight: 300;
        margin-top: 35px;
        width: 170px;
        padding: 18px 45px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10.5px;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    padding-top: 40px;
    margin-bottom: 30px;
    height: auto;

    .image-area {
      z-index: 1;
    }

    .content {
      padding: 0 50px 50px;
      top: -15px;
    }
  }
}
