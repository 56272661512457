.sv07-partners {
  margin-top: 70px;

  a {
    color: black;
    text-decoration: none;
  }

  h2 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
  }

  h4 {
    font-family: $font-stack-general-1;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 10px 0 50px;
    font-weight: 300;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
  }

  h5 {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $font-stack-general-1;
    margin-top: 15px;
  }

  .img-responsive {
    max-width: 193px;
  }

  hr {
    width: 50px;
    margin: 0 auto;
    border-top: 5px solid #ff5859;
  }

  .slick-list {
    width: 75%;
    margin: 0 auto;
  }

  .element {
    padding: 0;
    margin: 0 13.5px;

    &.slick-slide {
      width: 192px;
      // width: calc(50vh - 193px);
      // margin: 0 auto; /* it centers any block level element */
    }

    img {
      width: 100%;
    }

    &:hover {
      img {
        opacity: 0.5;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .element {
      padding: 20px;
      margin: 0;
    }
  }
}
