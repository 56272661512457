/*
General newsletter module present near the footer

.sv-newsletter {
  min-height: 340px;
  padding: 10px 20px;
  //background-color: $main-color;
  color: white;
  text-transform: none;
  text-align: left;

  .form_newsletter {
    h3 {
      padding-bottom: 90px;
    }

    .message {
      display: none;
    }
  }

  h3 {
    margin: 30px 0;
    height: 45px;
    font-family: $font-stack-general;
    font-size: 30px;
    font-weight: 700;
  }

  .wpcf7-mail-sent-ok {
    display: none !important;
  }

  .wpcf7-not-valid-tip {
    display: none;
  }

  .wpcf7-response-output,
  .wpcf7-acceptance-missing {
    font-size: 14px;
    margin: 0;
    color: white;
    border: none;

    @media (min-width: $mobile-breakpoint) {
      font-size: 12px;
    }
  }

  .wpcf7-form-control-wrap input,
  textarea {
    color: white;
  }

  .newsletter_error {
    font-family: Montserrat, sans-serif;
    font-size: 10.2px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.3px;
    text-align: left;
    color: #000;
  }

  .newsletter_success {
    text-align: center;
    font-size: 14px;
    margin-top: 40px;
  }

  .elipse {
    width: 89px;
    height: 89px;
    background-color: #fff;
    border-radius: 50px / 50px;
    margin-left: auto;
    margin-right: auto;
  }

  &.foot {
    padding: 10px 0;

    .elipse {
      background-color: #000;
    }

    .newsletter_success {
      margin-top: 0;
      font-size: 14px;
    }

    .wpcf7-form {
      p,
      label {
        display: initial;
      }
    }
  }

  .highlight {
    display: inline-block;
    height: 65px;
    background-repeat: no-repeat;
    color: black;
    padding-top: 16px;
    width: 175px;
  }

  .message {
    color: black;
    font-family: $font-stack-general-3;
    font-size: 36px;
    margin: 0 0 30px;

    @media (min-width: $mobile-breakpoint) {
      margin: 0 0 40px;
    }
  }

  .disclaimer {
    //padding-top: 46px;
    width: 35%;
    margin: 0 auto;
    font-size: 13px;
    line-height: 1rem;

    @media (min-width: $mobile-breakpoint) {
      margin-top: 30px;
    }
  }

  .conditions {
    margin-bottom: 10px;
  }

  form {
    color: white;
    font-family: $font-stack-general-1;

    p {
      display: inline-block;
    }

    input[type="email"] {
      outline: none;
      border: 1px solid white;
      background-color: $main-color;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 16px;
      min-height: 45px;
      max-width: 425px;
      font-weight: 300;
      width: 30%;
      padding: 10px;

      @media (min-width: $mobile-breakpoint) {
        width: 100%;
        font-size: 14px;
      }

      &::-webkit-input-placeholder { // WebKit, Blink, Edge
        color: white;
      }

      &:-moz-placeholder { // Mozilla Firefox 4 to 18
        color: white;
        opacity: 1;
      }

      &::-moz-placeholder { // Mozilla Firefox 19+
        color: white;
        opacity: 1;
      }

      &:-ms-input-placeholder { // Internet Explorer 10-11
        color: white;
      }

      &:-ms-input-placeholder { // Microsoft Edge
        color: white;
      }
    }

    input[type="submit"] {
      background-color: white;
      color: $main-color;
      border: 0;
      padding: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px;
      min-height: 45px;
      min-width: 170px;
      border-radius: 0;

      @media (min-width: $mobile-breakpoint) {
        border: 1px solid white;
      }

      &:hover,
      &:active {
        color: white;
        background-color: black;

        @media (min-width: $mobile-breakpoint) {
          border: 1px solid black;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    padding: 26px;

    .highlight {
      padding-top: 30px;
      background-position-y: 15px;
      background-position-x: center;
      width: 100%;
      height: 80px;
    }

    .message {
      padding: 54px 25px 20px;
      margin-top: 10px;
    }

    form {
      input {
        display: block;
        width: 100%;

        &[type="email"] {
          width: 100%;
          margin-bottom: 0;
          margin-top: 20px;

          @media (min-width: $mobile-breakpoint) {
            margin-top: 0;
            margin-bottom: 10px;
          }
        }
      }
    }

    .disclaimer {
      width: 100%;
    }
  }
}

// Footer
.foot {
  .wpcf7-response-output {
    color: #fff;
    background-color: #000;
    border: none;
    font-size: 12px;
    padding: 8px 5px;
    text-align: center;
  }
}

// Newsletter module present in the articles sidebar
.sidebar {
  .sv-newsletter {
    h3 {
      font-size: 24px;
      height: 95px;
      margin-bottom: 0;
    }

    form {
      input {
        display: block;

        &[type="email"] {
          width: 100%;
          margin: 10px 0;
          text-align: center;

          @media (min-width: $mobile-breakpoint) {
            margin: 0;
          }
        }

        &[type="submit"] {
          width: 100%;
          margin: 10px 0;

          @media (min-width: $mobile-breakpoint) {
            margin: 0;
          }
        }
      }

      p {
        width: 100%;
      }
    }

    .highlight {
      background-position-y: 0;

      @media (min-width: $mobile-breakpoint) {
        width: 65%;
        line-height: 12px;
      }
    }

    .disclaimer {
      width: 100%;

      @media (min-width: $mobile-breakpoint) {
        margin: 0 0 25px;
        padding: 0;
        font-size: 10px;
        line-height: 13px;
      }
    }

    .message {
      // margin: 75px 0 40px 0;
      font-weight: 100;
      font-size: 26px;
    }

    .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-response-output,
    .wpcf7-acceptance-missing {
      color: white;
      border: none;
      font-size: 12px;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    padding: 0 !important;

    .container-fluid {
      padding: 0;

      .sv-newsletter {
        padding: 50px 30px;

        .message {
          padding: 0;
          margin-top: 40px;
        }
      }
    }
  }
}

.newsletter__intra-posts-container {
  margin: 30px 0 40px;
}

*/
